import { Vector3 } from "src/core/math/Vector3";
import { BaseProgram } from "src/core/rendering/webgl/programs/BaseProgram";
import { Uniform, uniforms } from "./constants";
import { UniformValue } from "src/core/rendering/webgl/Uniforms";
import { Vector2 } from "src/core/math/Vector2";
import { Vector4 } from "src/core/math/Vector4";

import defaultVertexShader from "./baseShaders/default-vs.glsl";
import { WebGLTools } from "src/core/rendering/WebGLTools";

export class Program extends BaseProgram {
  private _aPosition = "aPosition";
  private _vertexBuffer: WebGLBuffer;
  constructor(gl: WebGLRenderingContext, fragmentShader: string) {
    super(gl, defaultVertexShader, fragmentShader);

    this.createVertexAttribs(this._aPosition);

    this.uniforms.setInt("uSampler", 0);

    const vertices = [1, 1, -1, 1, 1, -1, -1, -1];
    this._vertexBuffer = WebGLTools.createStaticVertexBuffer(
      gl,
      new Float32Array(vertices)
    );
  }

  setTime(value: number) {
    this.setValue(uniforms.time, value);
  }
  setResolution(value: Vector3) {
    this.setValue(uniforms.resolution, value);
  }
  setMousePosition(value: Vector2) {
    this.setValue(uniforms.mouse, new Vector4(value.x, value.y, 0, 0));
  }
  render(texture: WebGLBuffer) {
    this.beforeRender();

    this._gl.activeTexture(this._gl.TEXTURE0);
    this._gl.bindTexture(this._gl.TEXTURE_2D, texture);

    this._gl.bindBuffer(this._gl.ARRAY_BUFFER, this._vertexBuffer);
    this._gl.vertexAttribPointer(
      this.getAttrib(this._aPosition),
      2,
      this._gl.FLOAT,
      false,
      0,
      0
    );
    this._gl.drawArrays(this._gl.TRIANGLE_STRIP, 0, 4);

    this.afterRender();
  }
  dispose() {
    super.dispose();
    this._gl.deleteBuffer(this._vertexBuffer);
  }

  private setValue(uniform: Uniform, value: UniformValue) {
    this.uniforms.setValue(uniform.name, uniform.type, value);
  }
}
