import { EventHandler } from '../../EventHandler';

export class Texture {
    private _gl: WebGLRenderingContext;
    private _loaded = false;
    private _waiting = new EventHandler<void>();
    private _references = 0;
    source: string;
    value: WebGLTexture;

    constructor(gl: WebGLRenderingContext) {
        this._gl = gl;
    }

    /** Remove a texture reference from webgl. If there are no references left, returns true. */
    delete(): boolean {
        this._references--;
        if (this._references === 0) {
            this._gl.deleteTexture(this.value);
            return true;
        }
        return false;
    }
    /** Async texture load from a blob. */
    loadFromFile(source: File, done: () => void) {
        this._references++;
        let image = new Image();
        image.onload = () => {
            this.finishLoad(image);
            done();
        };
        image.src = window.URL.createObjectURL(source);
    }
    /** Async texture load from a url. */
    loadFromUrl(source: string, done: () => void) {
        this._references++;
        if (this._loaded)
            return done();

        this._waiting.add(done);

        // If this is not the first requester, there is no need to load the image again
        if (this._waiting.length > 1)
            return;

        let image = new Image();
        image.crossOrigin = "anonymous";
        image.onload = () => {
            this.finishLoad(image);
            this._waiting.call();
        };
        image.onerror = () => this._waiting.call();
        image.src = source;
    }

    private finishLoad(image: HTMLImageElement) {
        let logX = Math.log2(image.width);
        let logY = Math.log2(image.height);
        let powerOf2 = logX === Math.ceil(logX) && logY === Math.ceil(logY);

        this.value = this._gl.createTexture();
        this._gl.bindTexture(this._gl.TEXTURE_2D, this.value);
        this._gl.texImage2D(this._gl.TEXTURE_2D, 0, this._gl.RGBA, this._gl.RGBA, this._gl.UNSIGNED_BYTE, image);
        this._gl.texParameteri(this._gl.TEXTURE_2D, this._gl.TEXTURE_MAG_FILTER, this._gl.LINEAR);

        if (powerOf2) {
            // Use mipmaps only when texture dimensions are power of 2
            this._gl.texParameteri(this._gl.TEXTURE_2D, this._gl.TEXTURE_MIN_FILTER, this._gl.LINEAR_MIPMAP_NEAREST);
            this._gl.generateMipmap(this._gl.TEXTURE_2D);
        }
        else {
            this._gl.texParameteri(this._gl.TEXTURE_2D, this._gl.TEXTURE_WRAP_S, this._gl.CLAMP_TO_EDGE);
            this._gl.texParameteri(this._gl.TEXTURE_2D, this._gl.TEXTURE_WRAP_T, this._gl.CLAMP_TO_EDGE);
            this._gl.texParameteri(this._gl.TEXTURE_2D, this._gl.TEXTURE_MIN_FILTER, this._gl.LINEAR);
        }

        this._gl.bindTexture(this._gl.TEXTURE_2D, null);

        this._loaded = true;
    }
}