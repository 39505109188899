export class Color {
  /**
   * Creates a new color from RGBA values. Each component is from 0 to 255.
   * @param r Red.
   * @param g Green.
   * @param b Blue.
   * @param a Alpha (defalt 255).
   */
  constructor(
    public r: number,
    public g: number,
    public b: number,
    public a: number = 255
  ) {
    Color.assertComponent(r)
    Color.assertComponent(g)
    Color.assertComponent(b)
    Color.assertComponent(a)
  }

  getComponent(index: number): number {
    switch (index) {
      case 0:
        return this.r
      case 1:
        return this.g
      case 2:
        return this.b
      case 3:
        return this.a
      default:
        return 0
    }
  }

  toString(): string {
    return 'rgb(' + this.r + ', ' + this.g + ', ' + this.b + ')'
  }
  toStringRGBA(): string {
    return (
      'rgba(' +
      this.r +
      ', ' +
      this.g +
      ', ' +
      this.b +
      ', ' +
      this.a / 255.0 +
      ')'
    )
  }
  toFloatRGBA(): [number, number, number, number] {
    return [this.r / 255.0, this.g / 255.0, this.b / 255.0, this.a / 255.0]
  }
  toFloatRGB(): [number, number, number] {
    return [this.r / 255, this.g / 255, this.b / 255]
  }
  toGPUColor(): GPUColorDict {
    return {
      r: this.r / 255,
      g: this.g / 255,
      b: this.b / 255,
      a: this.a / 255,
    }
  }
  clone(): Color {
    return new Color(this.r, this.g, this.b, this.a)
  }
  addRGBScalar(s: number): Color {
    return new Color(this.r + s, this.g + s, this.b + s, this.a).selfNormalize()
  }
  multiplyRGBScalar(s: number): Color {
    return new Color(this.r * s, this.g * s, this.b * s, this.a).selfNormalize()
  }
  selfNormalize(): Color {
    let min = Math.min(this.r, this.g, this.b)
    if (min < 0) {
      this.r -= min
      this.g -= min
      this.b -= min
    }

    let max = Math.max(this.r, this.g, this.b)
    if (max > 255) {
      let factor = 255 / max
      this.r *= factor
      this.g *= factor
      this.b *= factor
    }
    return this
  }

  private static assertComponent(value: number) {
    if (isNaN(value) || value < 0 || value > 255) {
      throw new Error(`Invalid color component: ${value}`)
    }
  }

  private static parse(r: string, g: string, b: string): Color {
    return new Color(parseInt(r, 16), parseInt(g, 16), parseInt(b, 16))
  }

  static fromHexString(value: string): Color {
    if (value.startsWith('#')) {
      value = value.substring(1)
    }
    if (value.length === 6) {
      return this.parse(
        value.substring(0, 2),
        value.substring(2, 4),
        value.substring(4, 6)
      )
    } else if (value.length === 3) {
      return this.parse(
        value.substring(0, 1).repeat(2),
        value.substring(1, 2).repeat(2),
        value.substring(2, 3).repeat(2)
      )
    }
    throw new Error(`Invalid color hex: ${value}`)
  }
  static get random(): Color {
    return new Color(
      Math.round(Math.random() * 255),
      Math.round(Math.random() * 255),
      Math.round(Math.random() * 255),
      255
    )
  }
  static get white(): Color {
    return new Color(255, 255, 255, 255)
  }
  static get black(): Color {
    return new Color(0, 0, 0, 255)
  }
  static get blue(): Color {
    return new Color(0, 0, 255, 255)
  }
}
