import { GUI } from 'dat.gui'

export interface Configs {
  bottomColor: string
  topColor: string
  fogDistance: number
}

export class Configurator {
  private _gui: GUI
  onChange: () => void
  values: Configs = {
    bottomColor: '#370900',
    topColor: '#fcc000',
    fogDistance: 2,
  }

  constructor() {
    this._gui = new GUI({ width: 300 })
    this._gui.useLocalStorage = true
    this._gui.remember(this.values)

    this._gui.addColor(this.values, 'bottomColor')
      .onChange(this._onChangeHandler)

    this._gui.addColor(this.values, 'topColor')
      .onChange(this._onChangeHandler)
    
    this._gui.add(this.values, 'fogDistance')
      .min(0.1)
      .max(4)
      .step(0.01)
      .onChange(this._onChangeHandler)

  }
  dispose() {
    this._gui.destroy()
  }

  private _onChangeHandler = () => {
    this._gui.save()
    this.onChange()
  }
}
