import { BindGroupWithTexturePairs } from 'src/core/webGPU/BindGroupWithTexturePairs'
import { Color } from 'src/core/Color'
import { Bindable } from 'src/core/webGPU/Bindable'
import addColorsAndVelocityShader from './shaders/addColorsAndVelocity.wgsl'
import { GPU } from 'src/core/webGPU/GPU'
import { Textures } from '../Textures'
import { UniformStruct } from 'src/core/webGPU/Uniforms/UniformStruct'
import { Vector2 } from 'src/core/math/Vector2'
import { Sampler } from 'src/core/webGPU/textures/Sampler'
import { RenderPipeline } from 'src/core/webGPU/RenderPipeline'
import { Emmiter } from '../Emmiter'

const emmiters = 10
const circleRadius = 0.2

class Emitter extends UniformStruct {
  angle = this.addFloatField()
  speed = this.addFloatField()
  position = this.addVector2Field()
  color = this.addFloatRGBColorField()
}

class Emitters extends UniformStruct {
  constructor(private numberOfEmmiters: number) {
    super()
  }
  label = 'Emmiters'
  startPoint = this.addVector2Field()
  endPoint = this.addVector2Field()
  radius = this.addFloatField()
  emmiters = this.addArrayField(this.numberOfEmmiters, () => new Emitter())
}

export class AddColorAndVelocity {
  private _emmiters = new Array(emmiters)
    .fill(0)
    .map((_, i) => (Math.PI * 2 * i) / emmiters)
    .map(
      (angle) =>
        new Emmiter(
          new Vector2(Math.cos(angle), Math.sin(angle))
            .selfMultiplyScalar(circleRadius)
            .selfAdd(new Vector2(0.5, 0.5))
        )
    )

  private _gpu: GPU
  private _textures: Textures
  private _pipeline: RenderPipeline
  private _bindGroups: BindGroupWithTexturePairs
  private _sampler = new Sampler({
    magFilter: 'linear',
    minFilter: 'linear',
    addressModeU: 'repeat',
    addressModeV: 'repeat',
  })

  private _emmitersStruct = new Emitters(this._emmiters.length)

  constructor(gpu: GPU, configStruct: Bindable, textures: Textures) {
    this._gpu = gpu
    this._textures = textures
    this._bindGroups = new BindGroupWithTexturePairs([
      configStruct,
      this._emmitersStruct,
      this._sampler,
      this._textures.velocity,
      this._textures.colors,
    ])
    this._pipeline = new RenderPipeline(
      {
        label: 'AddColorAndVelocity pipeline',
        vertex: {
          module: { code: addColorsAndVelocityShader },
          entryPoint: 'vertex',
        },
        fragment: {
          module: { code: addColorsAndVelocityShader },
          entryPoint: 'flamethrower',
          targets: [
            {
              format: 'rg16float',
            } as GPUColorTargetState,
            {
              format: 'rgba8unorm',
            } as GPUColorTargetState,
          ],
        },
        primitive: {
          topology: 'triangle-strip',
        },
      },
      [this._bindGroups]
    )
  }

  setPattern(pattern: string) {
    this._pipeline.setFragmentEntrypoint(pattern)
  }

  createPipeline() {
    this._pipeline.initialize(this._gpu.device)
  }

  run(startPoint: Vector2, endPoint: Vector2, totalTime: number) {
    this._emmiters.forEach((e) => e.update(totalTime))
    const device = this._gpu.device

    this._emmitersStruct.startPoint.setValue(startPoint)
    this._emmitersStruct.endPoint.setValue(endPoint)
    this._emmitersStruct.radius.setValue(60)
    this._emmiters.forEach((emmiter, index) => {
      const emmiterField = this._emmitersStruct.emmiters.at(index)
      emmiterField.angle.setValue(emmiter.angle)
      emmiterField.speed.setValue(emmiter.angle)
      emmiterField.position.setValue(emmiter.position)
      emmiterField.color.setValue(emmiter.color)
    })

    const commandEncoder = device.createCommandEncoder({
      label: 'AddColorAndVelocity',
    })

    const velocityTextureView =
      this._textures.velocity.destination.getOrCreateResource(device)
    const colorTextureView =
      this._textures.colors.destination.getOrCreateResource(device)
    const renderPassDescriptor: GPURenderPassDescriptor = {
      colorAttachments: [
        {
          view: velocityTextureView,
          clearValue: Color.black.toGPUColor(),
          loadOp: 'clear',
          storeOp: 'store',
        } as GPURenderPassColorAttachment,
        {
          view: colorTextureView,
          clearValue: Color.black.toGPUColor(),
          loadOp: 'clear',
          storeOp: 'store',
        } as GPURenderPassColorAttachment,
      ],
    }

    const passEncoder = commandEncoder.beginRenderPass(renderPassDescriptor)
    this._pipeline.bindTo(passEncoder)
    passEncoder.draw(4, 1, 0, 0)
    passEncoder.end()

    device.queue.submit([commandEncoder.finish()])

    this._textures.velocity.swap()
    this._textures.colors.swap()
  }
}
