import { useEffect, useRef } from 'react'
import { ThreeApp } from 'src/core/three/ThreeApp'

interface ThreeAppPageProps {
  type: { new (): ThreeApp }
}

export const ThreeAppPage = ({ type }: ThreeAppPageProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const app = new type()
    app.initialize(canvasRef.current!)
    return () => {
      app.dispose()
    }
  }, [type])

  return <canvas ref={canvasRef} />
}
