import { Vector2 } from 'src/core/math/Vector2';

export class Block {
    positions: Vector2[];
    center: Vector2;
    color: string;
    halfRotation: boolean = false;
    currentSide: number = -1;

    constructor(type: number, public position: Vector2) {
        switch (type) {
            case 0:
                this.positions = [
                    new Vector2(-1, 0),
                    new Vector2(0, 0),
                    new Vector2(1, 0),
                    new Vector2(0, -1)];
                this.center = new Vector2(0, 0);
                this.color = "red";
                break;
            case 1:
                this.positions = [
                    new Vector2(-1, 0),
                    new Vector2(0, 0),
                    new Vector2(1, 0),
                    new Vector2(1, -1)];
                this.center = new Vector2(0, 0);
                this.color = 'blue';
                break;
            case 2:
                this.positions = [
                    new Vector2(-1, -1),
                    new Vector2(-1, 0),
                    new Vector2(0, 0),
                    new Vector2(1, 0)];
                this.center = new Vector2(0, 0);
                this.color = 'orange';
                break;
            case 3:
                this.positions = [
                    new Vector2(0, -1),
                    new Vector2(0, 0),
                    new Vector2(1, 0),
                    new Vector2(1, -1)];
                this.center = new Vector2(0.5, -0.5);
                this.color = 'green';
                break;
            case 4:
                this.positions = [
                    new Vector2(-1, 0),
                    new Vector2(0, 0),
                    new Vector2(1, 0),
                    new Vector2(2, 0)];
                this.center = new Vector2(0, 0);
                this.color = 'purple';
                this.halfRotation = true;
                break;
            case 5:
                this.positions = [
                    new Vector2(0, 0),
                    new Vector2(1, 0),
                    new Vector2(0, -1),
                    new Vector2(-1, -1)];
                this.center = new Vector2(-0.5, -0.5);
                this.color = 'pink';
                this.halfRotation = true;
                break;
            case 6:
                this.positions = [
                    new Vector2(-1, 0),
                    new Vector2(0, 0),
                    new Vector2(0, -1),
                    new Vector2(1, -1)];
                this.center = new Vector2(-0.5, -0.5);
                this.color = 'cornflowerblue';
                this.halfRotation = true;
                break;
        }
    }

    isCompletelyInside(): boolean {
        for (var i = 0; i < this.positions.length; i++) {
            var pos = this.position.add(this.positions[i]);
            if (pos.y < 0)
                return false;
        }

        return true;
    }

    rotate(side: number) {
        if (this.halfRotation) {
            side = -this.currentSide;
            this.currentSide = side;
        }

        for (var i = 0; i < this.positions.length; i++) {
            this.positions[i] = this.positions[i].subtract(this.center).rotateZ(side * Math.PI * 0.5).add(this.center);
            this.positions[i].x = Math.floor(this.positions[i].x + 0.5);
            this.positions[i].y = Math.floor(this.positions[i].y + 0.5);
        }
    }
}