import { GUI } from 'dat.gui'
import { Key } from 'src/core/Key'
import { Keyboard } from 'src/core/Keyboard'

const colorPatterns = [
  'black',
  'checkers',
  'rgbCircle',
]
const velocityPatterns = [
  'zero',
  'round',
  'left',
  'leftLeft'
]
const addVelocityPatterns = [
  'flamethrower',
  'mouseDrag',
]

const defaultOptions = {
  density: 0.99,
  stats: false,
  renderVelocity: false,
  colorPattern: colorPatterns[0],
  velocityPattern: velocityPatterns[0],
  addVelocityPattern: addVelocityPatterns[0],

  restoreDefaults: () => {},
}

export type Options = typeof defaultOptions

export class Configurator {
  private readonly _keyboard = new Keyboard()
  private _isOpen = true
  private _gui: GUI

  options = {
    ...defaultOptions,
    restoreDefaults: () => {
      for (const key in defaultOptions) {
        const value = defaultOptions[key]
        if (typeof value !== 'function') {
          this.options[key] = value
        }
      }
      this._gui.updateDisplay()
    },
  }

  constructor() {
    this._gui = new GUI({ width: 300 })
    this._gui.useLocalStorage = true
    this._gui.remember(this.options)
    this.initialize()

    this._keyboard.onKeyPress.add((key) => {
      switch (key) {
        case Key.h:
          this._isOpen = !this._isOpen
          if (this._isOpen) {
            this._gui.open()
            this._gui.updateDisplay()
          } else {
            this.options.stats = false
            this._gui.close()
          }
          break
      }
    })
  }

  initialize() {
    this._gui.add(this.options, 'density').min(0.3).max(2).step(0.01)
    this._gui.add(this.options, 'stats')
    this._gui.add(this.options, 'renderVelocity').name('render velocity')
    this._gui.add(this.options, 'colorPattern', colorPatterns)
    this._gui.add(this.options, 'velocityPattern', velocityPatterns)
    this._gui.add(this.options, 'addVelocityPattern', addVelocityPatterns)
  }

  onChange(fields: Array<keyof Options>, action: () => void) {
    this._gui.__controllers.forEach(c => {
      if (fields.includes(c.property as keyof Options)) {
        c.onChange(action)
      }
    })
  }

  dispose() {
    this._gui.destroy()
    this._keyboard.dispose()
  }
}
