import { Vector2 } from "src/core/math/Vector2";
import { FullScreenSprite } from "src/core/rendering/webgl/programs/FullScreenSprite";

export class Program extends FullScreenSprite {
  constructor(gl: WebGLRenderingContext, vsSource: string, fsSource: string) {
    super(gl, vsSource, fsSource);

    this.createVariables();
    this.uniforms.setFloat2("uPosition", new Vector2(-1000, -1000));
    this.uniforms.setFloat2("uDirection", new Vector2(1, 1));
  }

  setElapsedTime(value: number) {
    this.uniforms.setFloat("uTime", value);
  }
  setSize(size: Vector2) {
    this.uniforms.setFloat2("uSize", size);
  }
  move(position: Vector2, direction: Vector2) {
    this.uniforms.setFloat2("uPosition", position);
    this.uniforms.setFloat2("uDirection", direction);
  }

  render(texture: WebGLBuffer) {
    super.render(texture);
    this.uniforms.setFloat2("uPosition", new Vector2(-1000, -1000));
  }

  private createVariables() {
    this.createVertexAttribs("aPosition");
    this.createVertexAttribs("aTexCoord");
  }
}
