import { isNil } from "lodash"

export class GPU {
  private _device: GPUDevice
  private _context: GPUCanvasContext
  private _presentationFormat: GPUTextureFormat

  get presentationFormat(): GPUTextureFormat {
    return this._presentationFormat
  }
  get device(): GPUDevice {
    return this._device
  }
  get context(): GPUCanvasContext {
    return this._context
  }

  async initialize(canvas: HTMLCanvasElement) {
    if (!navigator.gpu) {
      throw new Error('WebGPU not available')
    }

    const adapter = await navigator.gpu.requestAdapter({
      powerPreference: 'high-performance',
    })
    if (isNil(adapter)) {
      throw new Error('WebGPU adapter not available')
    }
    this._device = await adapter.requestDevice()
    const context = canvas.getContext('webgpu')
    if (isNil(context)) {
      throw new Error('WebGPU context is not available')
    }
    this._context = context 
    this._presentationFormat = navigator.gpu.getPreferredCanvasFormat()
    this._context.configure({
      device: this._device,
      format: this._presentationFormat,
      alphaMode: 'opaque'
    })
  }

  dispose() {}
}
