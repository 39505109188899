import { UniformTypes } from "src/core/rendering/webgl/Uniforms";
import template from "./baseShaders/fragment-shader-template.glsl";
import { Uniform, uniforms } from "./constants";

export class FragmentShaderComposer {
  private _uniforms: string;

  constructor() {
    this._uniforms = Object.values(uniforms)
      .map(this.uniformToString)
      .join("\n");
  }

  generate(userCode: string): string {
    return template
      .replace("//__uniforms__", this._uniforms)
      .replace("//__usercode__", userCode);
  }
  private uniformToString = (u: Uniform): string => {
    return `uniform ${this.uniformTypeString(u.type)} ${u.name};`;
  };
  private uniformTypeString(type: UniformTypes): string {
    switch (type) {
      case UniformTypes.float:
        return "float";
      case UniformTypes.float2:
        return "vec2";
      case UniformTypes.float3:
        return "vec3";
      case UniformTypes.float4:
        return "vec4";
      case UniformTypes.int:
        return "int";
    }
  }
}
