import { Vector3 } from "./Vector3";

export class Plane {
  normal: Vector3
  d: number

  constructor(normal: Vector3, d: number) {
    this.normal = normal
    this.d = d
  }

  static fromDirectionPosition(direction: Vector3, position: Vector3): Plane {
    const normal = direction.negate()
    const d = normal.dot(position)
    return new Plane(normal, d)
  }
}