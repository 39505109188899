export class Vector4 {
  constructor(public x = 0, public y = 0, public z = 0, public w = 0) {}

  toArray(): Float32Array {
    return new Float32Array([this.x, this.y, this.z, this.w]);
  }
  toString(): string {
    return `{ x: ${this.x}, y: ${this.y}, z: ${this.z}, w: ${this.w} }`;
  }

  static fromArray(values: number[]) {
    return new Vector4(values[0], values[1], values[2], values[3]);
  }
  static revive(v: Vector4): Vector4 | null {
    if (!v) return null;
    return new Vector4(v.x, v.y, v.z, v.w);
  }
}
