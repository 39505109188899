import { Program } from './Program'
import { PageInformation } from 'src/components/PageInformation'
import { WebGPUCanvas } from 'src/core/webGPU/WebGPUCanvas'
import React from 'react'

export const PlaygroundPage = () => {
  const initFunction = React.useCallback((canvas: HTMLCanvasElement) => {
    const program = new Program()
    const initPromise = program.initialize(canvas).then(() => {
      program.start()
    })
    return () => {
      initPromise.then(() => {
        program.dispose()
      })
    }
  }, [])

  const informations = [
    'P to pause',
    'L to execute a single frame',
    'R to reset',
    'S to toggle stats',
  ]

  return (
    <>
      <WebGPUCanvas width={800} height={600} initFunction={initFunction} />
      <PageInformation lines={informations} />
    </>
  )
}
