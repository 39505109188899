import { easeInOutExpo, easeOutQuart } from 'src/core/math/easingFunctions'
import { randomSign } from 'src/core/math/randomUtils'
import {
  BufferGeometry,
  Float32BufferAttribute,
  MathUtils,
  Points,
  PointsMaterial,
  Scene,
} from 'three'
import { GalaxyOptions } from './GalaxyOptions'

export class Galaxy {
  _geometry: BufferGeometry
  _material: PointsMaterial
  _points: Points
  _scene: Scene

  constructor(options: GalaxyOptions, scene: Scene) {
    const vertices = new Float32Array(options.stars * 3)
    const angleBetweenBranches = (Math.PI * 2) / options.branches

    for (let star = 0; star < options.stars; star++) {
      const radius0to1 = Math.random()
      const radius = radius0to1 * options.radius
      const branch = MathUtils.randInt(0, options.branches)
      const branchCenterAngle =
        angleBetweenBranches * branch + radius0to1 * options.spiralAngle
      const angleFromBranchCenter =
        easeInOutExpo(Math.random()) * angleBetweenBranches
      const starAngle = branchCenterAngle + angleFromBranchCenter

      const x = Math.cos(starAngle) * radius
      const y = Math.sin(starAngle) * radius
      const z =
        (1 - easeOutQuart(Math.random())) * 0.5 * randomSign() * options.height

      const firstIndex = star * 3
      vertices[firstIndex] = x
      vertices[firstIndex + 1] = y
      vertices[firstIndex + 2] = z
    }

    this._geometry = new BufferGeometry()
    this._geometry.setAttribute(
      'position',
      new Float32BufferAttribute(new Float32Array(vertices), 3)
    )
    this._material = new PointsMaterial({
      color: 0xffffff,
      sizeAttenuation: true,
      size: 0.02,
    })
    this._points = new Points(this._geometry, this._material)

    scene.add(this._points)
    this._scene = scene
  }
  dispose() {
    this._geometry.dispose()
    this._material.dispose()
    this._scene.remove(this._points)
  }
}
