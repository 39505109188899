import Stats from 'three/examples/jsm/libs/stats.module'
import { Viewport } from '../Viewport'
import { Vector2 } from '../math/Vector2'
import { RenderLoop } from '../rendering/RenderLoop'
import { RenderLoopKeyboardController } from '../rendering/RenderLoopKeyboardController'
import { sguid } from '../sguid'
import { GPU } from './GPU'

export interface WebGPUProgramConfig {
  fillWindow: boolean
}

export abstract class WebGPUProgram {
  private readonly _id = sguid()
  private readonly _config: WebGPUProgramConfig
  private _initPromise: Promise<void> | undefined
  protected readonly _loop = new RenderLoop()
  private readonly _loopController = new RenderLoopKeyboardController(
    this._loop
  )
  protected _viewport: Viewport
  protected _size: Vector2
  protected _aspectRatio: number
  private _stats?: Stats
  protected _gpu: GPU
  protected _device: GPUDevice
  protected _context: GPUCanvasContext

  constructor(config?: Partial<WebGPUProgramConfig>) {
    ;(window as any)._app = this
    this._config = {
      fillWindow: true,
      ...config,
    }
    this._gpu = new GPU()
  }

  async initialize(canvas: HTMLCanvasElement) {
    this._initPromise = this.initializeAsync(canvas)
    return this._initPromise
  }

  start() {
    this._loop.start()
  }

  dispose(): void {
    this._initPromise?.finally(() => {
      this.removeStats()
      this._viewport?.dispose()
      this._loop.dispose()
      this._loopController.dispose()
      this._gpu?.dispose()
      this.disposeProgram()

      console.log(`dispose finished ${this._id}`)
    })
  }

  private async initializeAsync(canvas: HTMLCanvasElement) {
    console.log(`initialize started ${this._id}`)

    this._viewport = new Viewport(canvas)
    this._viewport.onResize.add((s) => {
      this._size = s
      this._aspectRatio = this._size.x / this._size.y
    })
    this._loop.onExecute.add(() => {
      this._stats?.update()
      this.update()
    })
    if (this._config.fillWindow) {
      this._viewport.fillWindow()
    }

    await this._gpu.initialize(canvas)
    this._device = this._gpu.device
    this._context = this._gpu.context

    console.log(`initialize finished ${this._id}`)
  }

  protected toggleStats() {
    this.showStats(!document.body.contains(this._stats?.dom ?? null))
  }

  protected showStats(show: boolean) {
    if (show) {
      if (!this._stats) {
        this._stats = Stats()
      }
      if (!document.body.contains(this._stats.dom)) {
        document.body.appendChild(this._stats.dom)
      }
    } else {
      this.removeStats()
    }
  }

  private removeStats() {
    if (document.body.contains(this._stats?.dom ?? null)) {
      document.body.removeChild(this._stats!.dom)
    }
  }

  protected abstract update(): void
  protected abstract disposeProgram(): void
}
