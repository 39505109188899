import { Sampler } from 'src/core/webGPU/textures/Sampler'
import { Textures } from '../Textures'
import renderToScreenShader from './shaders/renderToScreen.wgsl'
import { GPU } from 'src/core/webGPU/GPU'
import { Color } from 'src/core/Color'
import { RenderPipeline } from 'src/core/webGPU/RenderPipeline'
import { TexturePairBindGroup } from 'src/core/webGPU/TexturePairBindGroup'

export enum RenderMap {
  color = 'color',
  velocity = 'velocity',
}

export class RenderToScreen {
  private _gpu: GPU
  private _colorPipeline: RenderPipeline
  private _velocityPipeline: RenderPipeline
  private _sampler = new Sampler({
    magFilter: 'linear',
    minFilter: 'linear',
    addressModeU: 'mirror-repeat',
    addressModeV: 'mirror-repeat',
  })
  private _textures: Textures
  private _colorBindGroup: TexturePairBindGroup
  private _velocityBindGroup: TexturePairBindGroup

  renderMap: RenderMap

  constructor(gpu: GPU, textures: Textures) {
    this._gpu = gpu
    this._textures = textures
    this._colorBindGroup = new TexturePairBindGroup(this._sampler, this._textures.colors)
    this._velocityBindGroup = new TexturePairBindGroup(this._sampler, this._textures.velocity)
  }

  createPipeline() {
    this._colorPipeline = new RenderPipeline(
      {
        label: 'RenderToScreenPass color pipeline',
        vertex: {
          module: { code: renderToScreenShader },
          entryPoint: 'vertex',
        },
        fragment: {
          module: { code: renderToScreenShader },
          entryPoint: 'color',
          targets: [
            {
              format: this._gpu.presentationFormat,
            } as GPUColorTargetState,
          ],
        },
        primitive: {
          topology: 'triangle-strip',
        },
      },
      [this._colorBindGroup]
    )
    this._colorPipeline.initialize(this._gpu.device)

    this._velocityPipeline = new RenderPipeline(
      {
        label: 'RenderToScreenPass velocity pipeline',
        vertex: {
          module: { code: renderToScreenShader },
          entryPoint: 'vertex',
        },
        fragment: {
          module: { code: renderToScreenShader },
          entryPoint: 'velocity',
          targets: [
            {
              format: this._gpu.presentationFormat,
            } as GPUColorTargetState,
          ],
        },
        primitive: {
          topology: 'triangle-strip',
        },
      },
      [this._velocityBindGroup]
    )
    this._velocityPipeline.initialize(this._gpu.device)
  }

  run() {
    const device = this._gpu.device
    const commandEncoder = device.createCommandEncoder({
      label: 'RenderToScreenPass color',
    })

    const textureView = this._gpu.context.getCurrentTexture().createView()
    const renderPassDescriptor: GPURenderPassDescriptor = {
      colorAttachments: [
        {
          view: textureView,
          clearValue: Color.black.toGPUColor(),
          loadOp: 'clear',
          storeOp: 'store',
        } as GPURenderPassColorAttachment,
      ],
    }

    const passEncoder = commandEncoder.beginRenderPass(renderPassDescriptor)
    if (this.renderMap === RenderMap.color) {
      this._colorPipeline.bindTo(passEncoder)
    } else if (this.renderMap === RenderMap.velocity) {
      this._velocityPipeline.bindTo(passEncoder)
    }

    passEncoder.draw(4, 1, 0, 0)
    passEncoder.end()

    device.queue.submit([commandEncoder.finish()])
  }
}
