import { Bindable } from '../Bindable'

export class Sampler implements Bindable {
  private _descriptor: GPUSamplerDescriptor
  private _sampler?: GPUSampler
  private _type: GPUSamplerBindingType

  constructor(
    descriptor: GPUSamplerDescriptor,
    type: GPUSamplerBindingType = 'filtering'
  ) {
    this._descriptor = descriptor
    this._type = type
  }

  getLayout(): Partial<GPUBindGroupLayoutEntry> {
    return {
      sampler: {
        type: this._type,
      },
    }
  }

  getOrCreateResource(device: GPUDevice): GPUBindingResource {
    if (this._sampler === undefined) {
      this._sampler = device.createSampler(this._descriptor)
    }
    return this._sampler
  }
}
