import { BindGroup } from 'src/core/webGPU/BindGroup'
import { GPU } from 'src/core/webGPU/GPU'
import { UniformStruct } from 'src/core/webGPU/Uniforms/UniformStruct'
import { Vector2 } from 'src/core/math/Vector2'
import { Vector3 } from 'src/core/math/Vector3'
import uniformArrayWGSL from './alignmentTest.wgsl'
import { AttributesBind } from 'src/core/webGPU/AttributesBind'
import { Attributes } from 'src/core/webGPU/Attributes'
import { assertIsDefined } from 'src/core/utils/asserts'

const emmiters = 2
const resultSize = 200

class Emitter extends UniformStruct {
  angle = this.addFloatField()
  speed = this.addFloatField()
  position = this.addVector2Field()
  float3 = this.addVector3Field()
}

class Emitters extends UniformStruct {
  constructor(private numberOfEmmiters: number) {
    super()
  }
  label = 'Emmiters'
  startPoint = this.addVector2Field()
  endPoint = this.addVector2Field()
  radius = this.addFloatField()
  emmiters = this.addArrayField(this.numberOfEmmiters, () => new Emitter())
}

export class AlignmentTest {
  private _gpu: GPU
  private _bindGroup: BindGroup
  private _computePipeline: GPUComputePipeline
  private _result = Attributes.fromTypes(['float32'])

  private _emmitersStruct = new Emitters(emmiters)

  constructor(gpu: GPU) {
    this._gpu = gpu
    this._bindGroup = new BindGroup(
      [
        this._emmitersStruct,
        new AttributesBind(this._result, {
          readOnly: false,
        }),
      ],
      GPUShaderStage.COMPUTE
    )
  }

  createPipeline() {
    const pipelineDescriptor: GPUComputePipelineDescriptor = {
      label: 'UniformArray test compute pipeline',
      layout: this._gpu.device.createPipelineLayout({
        label: 'UniformArray test compute layout',
        bindGroupLayouts: [this._bindGroup.getLayout(this._gpu.device)],
      }),
      compute: {
        module: this._gpu.device.createShaderModule({
          code: uniformArrayWGSL,
        }),
        entryPoint: 'main',
      },
    }
    this._computePipeline =
      this._gpu.device.createComputePipeline(pipelineDescriptor)

    this._result.createBuffer(
      resultSize,
      this._gpu.device,
      (_buffer) => {
        new Float32Array(_buffer)
      },
      GPUBufferUsage.STORAGE | GPUBufferUsage.COPY_SRC
    )
    this._bindGroup.initialize(this._gpu.device)
  }

  dispose() {
    // this._bindGroup.dispose()
    // this._result.dispose()
  }

  async run() {
    const device = this._gpu.device

    console.log(this._emmitersStruct)

    this._emmitersStruct.startPoint.setValue(new Vector2(1, 2))
    this._emmitersStruct.endPoint.setValue(new Vector2(3, 4))
    this._emmitersStruct.radius.setValue(5)

    const firstEmmiter = this._emmitersStruct.emmiters.at(0)
    firstEmmiter.angle.setValue(6)
    firstEmmiter.speed.setValue(7)
    firstEmmiter.position.setValue(new Vector2(8, 9))
    firstEmmiter.float3.setValue(new Vector3(10, 11, 12))

    const secondEmmiter = this._emmitersStruct.emmiters.at(1)
    secondEmmiter.angle.setValue(13)
    secondEmmiter.speed.setValue(14)
    secondEmmiter.position.setValue(new Vector2(15, 16))
    secondEmmiter.float3.setValue(new Vector3(17, 18, 19))

    const commandEncoder = device.createCommandEncoder({
      label: 'UniformArray test command encoder',
    })

    const passEncoder = commandEncoder.beginComputePass()
    passEncoder.setPipeline(this._computePipeline)
    this._bindGroup.bindTo(passEncoder)
    passEncoder.dispatchWorkgroups(1)
    passEncoder.end()

    const readBufferGPU = this._gpu.device.createBuffer({
      size: resultSize * Float32Array.BYTES_PER_ELEMENT,
      usage: GPUBufferUsage.MAP_READ | GPUBufferUsage.COPY_DST,
    })
    assertIsDefined(this._result._buffer, 'input buffer')
    commandEncoder.copyBufferToBuffer(
      this._result._buffer,
      0,
      readBufferGPU,
      0,
      resultSize * Float32Array.BYTES_PER_ELEMENT
    )

    const gpuCommands = commandEncoder.finish()
    device.queue.submit([gpuCommands])
    await device.queue.onSubmittedWorkDone()
    await readBufferGPU.mapAsync(GPUMapMode.READ)
    const arrayBuffer = readBufferGPU.getMappedRange()
    const result = new Float32Array(arrayBuffer)
    console.log(result)
  }
}
