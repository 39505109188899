import { useEffect, useRef } from "react"
import { Reflection } from "./Reflection";

export const ReflectionPage = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const r = new Reflection(canvasRef.current);
    return () => {
      r.dispose();
    }
  }, []);

  return <canvas ref={canvasRef} />
}