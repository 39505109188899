import { useEffect, useState } from 'react'
import styles from './PageInformation.module.css'
import { Keyboard } from 'src/core/Keyboard'
import { Key } from 'src/core/Key'

export interface PageInformationProps {
  lines: string[]
}

export const PageInformation = ({ lines }: PageInformationProps) => {
  const [hidden, setHidden] = useState(false)

  const buttonText = hidden ? 'Information' : 'Close'
  const toggleHidden = () => setHidden((previous) => !previous)

  useEffect(() => {
    const keyboard = new Keyboard()
    keyboard.onKeyPress.add(k => {
      if (k === Key.h) {
        toggleHidden()
      }
    })
    return () => {
      keyboard.dispose()
    }
  }, [])

  const content = hidden ? null : (
    <ul className={styles.content}>
      {lines.map((line, index) => (
        <li key={index}>
          <span>{line}</span>
        </li>
      ))}
    </ul>
  )

  return (
    <div className={styles.container}>
      {content}
      <div className={styles.button} onClick={toggleHidden}>
        {buttonText}
      </div>
    </div>
  )
}
