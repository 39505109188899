import { Camera } from './Camera';
import { Vector2 } from '../math/Vector2';
import { Vector3 } from '../math/Vector3';

export class CameraChaser2D {
    private _camera: Camera;
    private _target: Vector2;
    speed: number;

    constructor(camera: Camera, speed = 1) {
        this._camera = camera;
        this.speed = speed;
    }

    setTargetPosition(t: Vector2) {
        this._target = t;
    }
    update(elapsedTime: number) {
        let factor = elapsedTime * this.speed;

        let diff = this._target.subtract(this._camera.getPosition().toVector2());
        let change = diff.multiplyScalar(factor);
        this._camera.move(Vector3.fromVector2(change));
    }
}