import { Texture } from './Texture';

export class Textures {
    private _gl: WebGLRenderingContext;
    private _cache: { [key: string]: Texture } = {};

    constructor(gl: WebGLRenderingContext) {
        this._gl = gl;
    }
    get(url: string, done: (t: Texture) => void) {
        let existing = this._cache[url];
        if (!existing) {
            this._cache[url] = existing = new Texture(this._gl);
        }

        existing.loadFromUrl(url, () => done(existing));
    }
    fromFile(file: File, done: (t: Texture) => void) {
        let result = new Texture(this._gl);
        result.loadFromFile(file, () => done(result));
    }
    remove(texture: Texture) {
        if (texture.delete())
            delete this._cache[texture.source];
    }
    dispose() {
        let keys = Object.keys(this._cache);
        for (let key of keys)
            this.remove(this._cache[key]);
    }
}