import { IRenderable } from 'src/core/rendering/webgl/IRenderable';
import { Vector2 } from 'src/core/math/Vector2';
import { geometries } from 'src/core/rendering/webgl/geometries';
import { Renderer2D } from 'src/core/rendering/webgl2/Renderer2D';
import { Color } from 'src/core/Color';
import { Wall } from './Wall';
import { PieceSides } from './PieceSides';

export class Piece implements IRenderable {
    /** The square size in x and y for each piece. */
    static size = 200;
    openings: number;
    position: Vector2;
    walls: Wall[];

    constructor(openings: PieceSides, position: Vector2) {
        this.openings = openings;
        this.position = position;
    }

    rendererUpdade(renderer: Renderer2D) {
        let base = this.position.multiplyScalar(Piece.size);

        let floor = geometries.rectangle2D(base, new Vector2(Piece.size, Piece.size));
        floor.color = new Color(128, 128, 128, 255);
        renderer.addGeometry(floor);

        this.walls = [];
        this.addWall(PieceSides.Left);
        this.addWall(PieceSides.Right);
        this.addWall(PieceSides.Top);
        this.addWall(PieceSides.Bottom);

        for (let wall of this.walls)
            wall.rendererUpdade(renderer);
    }

    private isOpen(side: PieceSides): boolean {
        return (this.openings & side) > 0;
    }
    private addWall(side: PieceSides) {
        if (this.isOpen(side))
            return;

        let halfSize = Piece.size / 2;
        let wallPoints = [
            new Vector2(-halfSize, halfSize),
            new Vector2(halfSize, halfSize),
        ];

        // Adds thickness to close the gaps on the corners 
        let cw = this.getNext(side, true);
        if (this.isOpen(cw))
            wallPoints[1].x += Wall.thickness;
        let ccw = this.getNext(side, false);
        if (this.isOpen(ccw))
            wallPoints[0].x -= Wall.thickness;

        let translation = this.position.multiplyScalar(Piece.size);
        let rotation = this.getSideRotation(side);

        this.walls.push(new Wall(wallPoints, translation, rotation));
    }
    private getSideRotation(side: PieceSides): number {
        switch (side) {
            case PieceSides.Left:
                return Math.PI / 2;
            case PieceSides.Right:
                return -Math.PI / 2;
            case PieceSides.Top:
                return 0;
            case PieceSides.Bottom:
                return Math.PI;
        }
    }
    private getNext(side: PieceSides, clockwise: boolean): PieceSides {
        switch (side) {
            case PieceSides.Left:
                return clockwise ? PieceSides.Top : PieceSides.Bottom;
            case PieceSides.Right:
                return clockwise ? PieceSides.Bottom : PieceSides.Top;
            case PieceSides.Top:
                return clockwise ? PieceSides.Right : PieceSides.Left;
            case PieceSides.Bottom:
                return clockwise ? PieceSides.Left : PieceSides.Right;
        }
    }
}
