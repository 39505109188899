import { IRenderable } from 'src/core/rendering/webgl/IRenderable';
import { Piece } from './Piece';
import { Renderer2D } from 'src/core/rendering/webgl2/Renderer2D';
import { Vector2 } from 'src/core/math/Vector2';
import { Wall } from './Wall';

export class Map implements IRenderable {
    private pieces: { [key: number]: { [key: number]: Piece } };

    constructor() {
        this.pieces = [];
    }

    addPiece(piece: Piece) {
        if (!this.pieces[piece.position.x])
            this.pieces[piece.position.x] = {};
        this.pieces[piece.position.x][piece.position.y] = piece;
    }
    getPieceAt(position: Vector2): Piece {
        let line = this.pieces[position.x];
        if (!line)
            return null;
        return line[position.y];
    }
    getAllWalls(): Wall[] {
        let walls = new Array<Wall>();
        for (let x in this.pieces)
            for (let y in this.pieces[x])
                walls = walls.concat(this.pieces[x][y].walls);
        return walls;
    }

    rendererUpdade(renderer: Renderer2D) {
        for (let x in this.pieces) {
            let line = this.pieces[x];
            for (let y in line)
                this.pieces[x][y].rendererUpdade(renderer);
        }
    }
}