export class EventHandler<T> {
  private _c = new Array<(data: T) => void>()
  /** Number of listeners. */
  get length(): number {
    return this._c.length
  }
  add(c: (data: T) => void) {
    this._c.push(c)
  }
  remove(c: (data: T) => void) {
    const index = this._c.indexOf(c)
    if (index >= 0) {
      this._c.splice(index, 1)
    }
  }
  call(data: T) {
    for (let c of this._c) c(data)
  }
  dispose() {
    this._c.splice(0)
  }
}
