import { ChangeEvent } from "react";
import { SelectData } from "./OptionData";

export const SelectOption = ({ values, value, onChange }: SelectData) => {
  const changeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange && onChange(e.target.value);
  };
  return (
    <select onChange={changeHandler} value={value}>
      {values.map((valueItem) => (
        <option
          key={valueItem.id}
          value={valueItem.id}
        >
          {valueItem.name}
        </option>
      ))}
    </select>
  );
};
