import { Vector2 } from 'src/core/math/Vector2'
import { Texture } from 'src/core/webGPU/textures/Texture'
import { GPU } from 'src/core/webGPU/GPU'
import { RenderPipeline } from 'src/core/webGPU/RenderPipeline'
import accumulatorShader from './shaders/accumulator.wgsl'
import { BindGroup } from 'src/core/webGPU/BindGroup'
import { Sampler } from 'src/core/webGPU/textures/Sampler'
import { Color } from 'src/core/Color'

export class Accumulator {
  private _gpu: GPU
  private _pipeline: RenderPipeline
  private _texture = new Texture()
  private _bindGroup = new BindGroup([
    new Sampler({
      magFilter: 'linear',
      minFilter: 'linear',
      addressModeU: 'repeat',
      addressModeV: 'repeat',
    }),
    this._texture,
  ])

  constructor(gpu: GPU) {
    this._gpu = gpu
  }

  getTextureView(): GPUTextureView {
    return this._texture.getOrCreateResource(this._gpu.device)
  }

  getDescriptor(): GPURenderPassDescriptor{
    return {
      label: 'Accumulate pass',
      colorAttachments: [
        {
          view: this.getTextureView(),
          loadOp: 'load',
          storeOp: 'store',
        } as GPURenderPassColorAttachment,
      ],
    }
  }

  initialize(size: Vector2) {
    this._texture.fromConfig({
      width: size.x,
      height: size.y,
      format: 'bgra8unorm',
    })
    this._pipeline = new RenderPipeline(
      {
        label: 'Accumulator',
        vertex: {
          module: { code: accumulatorShader },
          entryPoint: 'vertex',
        },
        fragment: {
          module: { code: accumulatorShader },
          entryPoint: 'fragment',
          targets: [
            {
              format: 'bgra8unorm',
            } as GPUColorTargetState,
          ],
        },
        primitive: {
          topology: 'triangle-strip',
        },
      },
      [this._bindGroup]
    )
    this._pipeline.initialize(this._gpu.device)
  }

  resize(size: Vector2) {
    this._texture.resize(size)
  }

  render(commandEncoder: GPUCommandEncoder) {
    const textureView = this._gpu.context.getCurrentTexture().createView()
    const renderPassDescriptor: GPURenderPassDescriptor = {
      label: 'Accumulator to screen pass',
      colorAttachments: [
        {
          view: textureView,
          clearValue: new Color(0, 0, 0, 255).toGPUColor(),
          loadOp: 'clear',
          storeOp: 'store',
        } as GPURenderPassColorAttachment,
      ],
    }

    const passEncoder = commandEncoder.beginRenderPass(renderPassDescriptor)
    this._pipeline.bindTo(passEncoder)
    this._bindGroup.bindTo(passEncoder)
    passEncoder.draw(4, 1, 0, 0)
    passEncoder.end()
  }
}
