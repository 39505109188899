import { Vector3 } from 'src/core/math/Vector3'
import { RenderLoop } from 'src/core/rendering/RenderLoop'
import { RenderTarget } from 'src/core/rendering/RenderTarget'
import { ProgramException } from 'src/core/rendering/webgl/programs/BaseProgram'
import { Viewport } from 'src/core/Viewport'
import { FragmentShaderComposer } from './FragmentShaderComposer'
import { Program } from './Program'
import { isEmpty } from 'lodash'
import { Vector2 } from 'src/core/math/Vector2'

export class EffectComposer {
  private _gl: WebGL2RenderingContext
  private _viewport: Viewport
  private _target: RenderTarget
  private _source: RenderTarget
  private _loop: RenderLoop
  private _initPromise: Promise<void> | undefined
  private _program: Program | undefined
  private _totalTime = 0
  private _composer = new FragmentShaderComposer()
  private _size: Vector2

  initialize(canvas: HTMLCanvasElement): Promise<void> {
    this._initPromise = this.initializeAsync(canvas)
    return this._initPromise
  }
  start() {
    this._loop.start()
  }
  dispose() {
    this._initPromise.finally(() => {
      this._viewport?.dispose()
      this._source?.dispose()
      this._target?.dispose()
      this._loop?.dispose()
      this._program?.dispose()
    })
  }
  setShader(userShader: string): string[] {
    const shader = this._composer.generate(userShader)
    const logs = this.setFullShader(shader)
    if (!isEmpty(logs)) {
      const shaderWithLines = shader
        .split('\n')
        .map((line, index) => `${index + 1}\t${line}`)
        .join('\n')
      console.warn(
        `Error compiling user shader.\n${logs.join('\n')}\n${shaderWithLines}`
      )
    }
    return logs
  }

  private async initializeAsync(canvas: HTMLCanvasElement) {
    this._viewport = new Viewport(canvas)
    await this.initGL(canvas)
    this._loop = new RenderLoop()
    this.registerEvents()
    this._viewport.fillWindow()
  }
  private async initGL(canvas: HTMLCanvasElement) {
    const options = {
      alpha: false,
      antialias: false,
      depth: false,
      powerPreference: 'high-performance',
      premultipliedAlpha: false,
      preserveDrawingBuffer: true,
      stencil: false,
    } as WebGLContextAttributes
    this._gl = canvas.getContext('webgl2', options)

    this._gl.getExtension('OES_texture_float_linear')
    this._gl.getExtension('OES_texture_half_float_linear')
    this._gl.getExtension('EXT_texture_filter_anisotropic')
    this._gl.getExtension('EXT_color_buffer_float')
    this._gl.getExtension('WEBGL_debug_shaders')
    //const mAsynchCompile = this._gl.getExtension("KHR_parallel_shader_compile");

    this._gl.hint(this._gl.FRAGMENT_SHADER_DERIVATIVE_HINT, this._gl.NICEST)

    this._source = new RenderTarget(this._gl, 800, 600)
    this._target = new RenderTarget(this._gl, 800, 600)
    // this._target.enable();
    // this._sprite = new FullScreenSprite(this._gl);

    this._gl.clearColor(0, 0, 0, 0)
    this._gl.enable(this._gl.CULL_FACE)
    this._gl.blendEquation(this._gl.FUNC_ADD)
    this._gl.enable(this._gl.DEPTH_TEST)
    this._gl.depthFunc(this._gl.LEQUAL)
  }
  private registerEvents() {
    this._viewport.onResize.add((s) => {
      this._size = s
      this._gl.viewport(0, 0, s.x, s.y)
      this._source.setSize(s.x, s.y)
      this._target.setSize(s.x, s.y)
      this._program?.setResolution(new Vector3(s.x, s.y, s.x / s.y))
    })
    this._viewport.onPointerMove.add(({ position }) => {
      this._program?.setMousePosition(
        new Vector2(position.x, this._size.y - position.y)
      )
    })
    this._loop.onExecute.add((elapsed) => this.draw(elapsed))
  }
  private createProgram(shader: string): string[] {
    try {
      this._program = new Program(this._gl, shader)
      const { x, y } = this._viewport.size
      this._program.setResolution(new Vector3(x, y, x / y))
    } catch (e) {
      if (e instanceof ProgramException) {
        return e.logs
      } else {
        throw e
      }
    }
    return this._program.getLogs()
  }
  private setFullShader(shader: string): string[] {
    if (!this._program) {
      return this.createProgram(shader)
    }
    return this._program.setFragmentShader(shader)
  }
  private switchTargets() {
    let newSource = this._target
    this._target = this._source
    this._source = newSource
  }
  private draw(elapsed: number) {
    //this._target.enable();
    this._totalTime += elapsed
    this._program?.setTime(this._totalTime)
    this._program?.render(this._source.texture)
    //this._target.disable();
    //this.switchTargets();
  }
}
