import { Geometry3D } from "./Geometry3D";
import { Vector3 } from '../../math/Vector3';
import { Vector2 } from '../../math/Vector2';
import { Geometry2D } from './Geometry2D';
import { sguid } from 'src/core/sguid';

export module geometries {
    export function dome(center: Vector3, radius: number, hSegments = 60, vSegments = 30): Geometry3D {
        let phiStart = 0;
        let phiLength = Math.PI * 2;
        let thetaStart = 0;
        let thetaLength = Math.PI;
        let thetaEnd = thetaStart + thetaLength;

        let vertices = new Array<Vector3>();
        let coords = new Array<Vector2>();
        for (let iy = 0; iy <= vSegments; iy++) {
            let v = iy / vSegments;
            for (let ix = 0; ix <= hSegments; ix++) {
                let u = ix / hSegments;

                let zAngle = thetaStart + v * thetaLength;
                let xyAngle = phiStart + u * phiLength;
                let zSin = Math.sin(thetaStart + v * thetaLength);

                let point = new Vector3(
                    - radius * Math.cos(xyAngle) * zSin,
                    radius * Math.sin(xyAngle) * zSin,
                    radius * Math.cos(zAngle)
                ).selfAdd(center);

                vertices.push(point);
                coords.push(new Vector2(u, 1 - v));
            }
        }

        let indices = new Array<number>();
        for (let iy = 0; iy < vSegments; iy++) {
            for (let ix = 0; ix < hSegments; ix++) {
                let a = iy * (hSegments + 1) + ix + 1;
                let b = iy * (hSegments + 1) + ix;
                let c = (iy + 1) * (hSegments + 1) + ix;
                let d = (iy + 1) * (hSegments + 1) + ix + 1;

                if (iy !== 0 || thetaStart > 0)
                    indices.push(a, d, b);
                if (iy !== vSegments - 1 || thetaEnd < Math.PI)
                    indices.push(b, d, c);
            }
        }

        let result = new Geometry3D();
        result.vertices = vertices;
        result.coords = coords;
        result.indices = indices;
        return result;
    }

    export function rectangle2D(center: Vector2, size: Vector2, id?: string, texCoords = false): Geometry2D {
        let half = size.multiplyScalar(0.5);
        let result = new Geometry2D();
        result.id = id ?? sguid();
        result.vertices = [
            new Vector2(center.x - half.x, center.y - half.y),
            new Vector2(center.x - half.x, center.y + half.y),
            new Vector2(center.x + half.x, center.y + half.y),
            new Vector2(center.x + half.x, center.y - half.y),
        ];
        result.indices = [
            0, 1, 2,
            2, 3, 0
        ];

        if (texCoords) {
            result.coords = [
                new Vector2(0, 0),
                new Vector2(0, 1),
                new Vector2(1, 1),
                new Vector2(1, 0),
            ];
        }

        return result;
    }
}