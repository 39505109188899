export module shaders {
    export const coloredFaceVS = `
    attribute vec3 aPosition;
    uniform mat4 uViewProjection;
    uniform mat4 uWorld;
    void main(void) {
        gl_Position = uViewProjection * uWorld * vec4(aPosition, 1.0);
    }`;

    export const coloredFacePS = `
    precision mediump float;
    uniform vec4 uColor;
    void main(void) {
        gl_FragColor = vec4(uColor.w * uColor.xyz, uColor.w);
    }`;

    export const faceTexVSSource = `
    attribute vec3 aPosition;
    attribute vec2 aTexCoord;
    uniform mat4 uViewProjection;
    uniform mat4 uWorld;
    varying highp vec2 vTexCoord;
    void main(void) {
        vTexCoord = aTexCoord;
        gl_Position = uViewProjection * uWorld * vec4(aPosition, 1.0);
    }`;

    export const faceTexPSSource = `
    precision mediump float;
    uniform sampler2D uSampler;
    varying vec2 vTexCoord;
    void main(void) {
        vec4 tex = texture2D(uSampler, vec2(vTexCoord.s, 1.0 - vTexCoord.t));
        gl_FragColor = vec4(tex.w * tex.xyz, tex.w);
    }`;
}