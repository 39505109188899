import { RenderLoop } from 'src/core/rendering/RenderLoop'
import { Viewport } from 'src/core/Viewport'
import * as THREE from 'three'
import { Vector2 } from '../math/Vector2'
import { Camera, CameraType } from './Camera'
import { IDisposable } from './IDisposable'
import Stats from 'three/examples/jsm/libs/stats.module'

export interface ThreeAppOptions {
  preserveDrawingBuffer?: boolean
  orthographicCamera?: boolean
}

const defaultOptions: Required<ThreeAppOptions> = {
  preserveDrawingBuffer: false,
  orthographicCamera: false,
}

export abstract class ThreeApp implements IDisposable {
  protected _viewport: Viewport
  protected _loop: RenderLoop
  protected _scene?: THREE.Scene
  protected _camera: Camera
  protected _renderer: THREE.WebGLRenderer
  private _disposables: IDisposable[] = []
  /** Seconds from the start of the app. */
  private _totalTime = 0
  private _options: Required<ThreeAppOptions>
  private _stats?: Stats

  initialize(canvas: HTMLCanvasElement, options?: ThreeAppOptions) {
    window['__threeApp'] = this

    this._options = { ...defaultOptions, ...options }

    const { preserveDrawingBuffer, orthographicCamera } = this._options

    this._scene = new THREE.Scene()
    this._renderer = new THREE.WebGLRenderer({
      canvas,
      preserveDrawingBuffer,
    })

    this._viewport = new Viewport(canvas)
    this._loop = new RenderLoop()
    this.registerEvents()
    this._viewport.fillWindow()

    const cameraType = orthographicCamera
      ? CameraType.Orthographic
      : CameraType.Perspective
    this._camera = new Camera(cameraType, this._viewport.size, this._renderer)

    this._loop.start()
    this.addDisposable(this._renderer, this._viewport, this._loop)
  }

  dispose() {
    for (const disposable of this._disposables) {
      disposable.dispose()
    }
    this.removeStats()
  }

  private registerEvents() {
    this._viewport.onResize.add((size) => this.resize(size))
    this._loop.onExecute.add((elapsed) => this.update(elapsed))
  }

  private update(elapsedSeconds: number) {
    this._totalTime += elapsedSeconds
    this._camera.update()
    this.draw(elapsedSeconds)
  }

  private draw(elapsedSeconds: number) {
    this.beforeDraw(elapsedSeconds, this._totalTime)
    this._stats?.update()
    this._renderer.render(this._scene, this._camera.camera)
  }

  private resize(size: Vector2) {
    this._renderer.setSize(size.x, size.y)
    this._renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  }

  /**
   * Executed by ThreeApp before drawing.
   * @param elapsedSeconds Elapsed seconds from the last frame.
   * @param totalSeconds Elapsed seconds from the start of the app.
   */
  protected abstract beforeDraw(elapsedSeconds: number, totalSeconds: number)

  protected addBox() {
    const box = new THREE.BoxGeometry()
    const material = new THREE.MeshBasicMaterial()
    const mesh = new THREE.Mesh(box, material)
    this._scene.add(mesh)
    this.addDisposable(box, material)
  }

  protected addDisposable(...disposables: IDisposable[]) {
    this._disposables.push(...disposables)
  }

  protected showStats(show: boolean) {
    if (show) {
      if (!this._stats) {
        this._stats = Stats()
      }
      if (!document.body.contains(this._stats.dom)) {
        document.body.appendChild(this._stats.dom)
      }
    }
    else {
      this.removeStats()
    }
  }

  private removeStats() {
    if (document.body.contains(this._stats?.dom)) {
      document.body.removeChild(this._stats.dom)
    }
  }
}
