import { Piece } from './Piece';
import { PieceSides } from "./PieceSides";
import { Map } from './Map';
import { Vector2 } from 'src/core/math/Vector2';
import { Parameters } from './Parameters';
import { Wall } from './Wall';

export class MapGenerator {
    private _openPieces: Piece[];
    private _map: Map;
    private _pieceCount: number;
    private _openIndex: number;
    private _sides = [PieceSides.Left, PieceSides.Right, PieceSides.Bottom, PieceSides.Top];
    private _oppositeSides = [PieceSides.Right, PieceSides.Left, PieceSides.Top, PieceSides.Bottom];

    generate(parameters: Parameters): Map {

        Piece.size = parameters.pieceSize;
        Wall.thickness = parameters.wallThickness;
        Wall.color = parameters.wallColor;

        this._map = new Map();
        this._openPieces = [];
        this._pieceCount = 0;
        this._openIndex = 0;

        // this.addPiece(new Vector2(0, 0));
        // this.addPiece(new Vector2(0, 1));
        // this.addPiece(new Vector2(1, 1));
        // this.addPiece(new Vector2(2, 1));
        // this.addPiece(new Vector2(2, 0));
        // this.addPiece(new Vector2(2, -1));
        // this.addPiece(new Vector2(1, -1));
        // return this._map;

        this.addPiece(new Vector2(0, 0));
        while (this._pieceCount < parameters.piecesNumber) {
            let current = this._openPieces[this._openIndex];

            this.addRandomNeighbor(current);
            this._openPieces.splice(this._openIndex, 1);

            this._openIndex--;
            if (this._openIndex < 0)
                this._openIndex = this._openPieces.length - 1;
        }

        return this._map;
    }

    private addRandomNeighbor(piece: Piece) {
        let v = Math.floor(Math.random() * 4);
        let pos = this.getNeighborPosition(piece.position, v);
        this.addPiece(pos);
    }
    private addPiece(position: Vector2) {
        let piece = this._map.getPieceAt(position);

        if (!piece) {
            piece = new Piece(PieceSides.None, position);
            this._map.addPiece(piece);
            this._pieceCount++;

            for (let i = 0; i < 4; i++) {
                let np = this.getNeighborPosition(position, i);
                let neighbor = this._map.getPieceAt(np);
                if (neighbor) {
                    piece.openings = piece.openings | this._sides[i];
                    neighbor.openings = neighbor.openings | this._oppositeSides[i];
                }
            }
        }
        this._openPieces.push(piece);
    }
    private getNeighborPosition(position: Vector2, neighbor: number) {
        switch (neighbor) {
            case 0:
                return new Vector2(position.x - 1, position.y);
            case 1:
                return new Vector2(position.x + 1, position.y);
            case 2:
                return new Vector2(position.x, position.y - 1);
            case 3:
                return new Vector2(position.x, position.y + 1);
        }
    }
}