import { Vector3 } from "src/core/math/Vector3";
import { Geometry3D } from "src/core/rendering/webgl/Geometry3D";
import { Renderer } from "src/core/rendering/webgl/Renderer";
import { Camera } from "src/core/rendering/Camera";
import { Color } from "src/core/Color";
import { ViewportDragEvent, Viewport } from "src/core/Viewport";
import { geometries } from "src/core/rendering/webgl/geometries";
import { RenderLoop } from "src/core/rendering/RenderLoop";

export class Panorama {
  private _renderer: Renderer;
  private _camera: Camera;
  private _movementMultiplier: number;
  private _dome: Geometry3D;
  private _viewport: Viewport;
  private _renderLoop: RenderLoop;

  /* 
   TODO: 
   Shitty resolution when changing device orientation
   Handle camera rotate over top and bottom
   Zoom
   Image url as a param
   */

  constructor(canvas: HTMLCanvasElement) {
    this._renderer = new Renderer(canvas);
    this._renderer.setClearColor(new Color(255, 0, 0));
    this._dome = geometries.dome(new Vector3(0, 0, 0), 1000);
    this._dome.id = "dome";
    this._dome.texture = "panorama.jpg";

    this._renderer.addGeometry(this._dome);

    this._camera = new Camera();
    this._camera.setValues(
      new Vector3(0, 0, 0),
      new Vector3(0, -1, 0),
      new Vector3(0, 0, 1)
    );

    this._viewport = new Viewport(canvas);
    this._viewport.setCamera(this._camera);
    this._viewport.setRenderer(this._renderer);
    this._viewport.fillWindow();

    this.registerEvents();

    this._movementMultiplier = this._camera.getFov() / this._viewport.size.y;
    this._renderLoop = new RenderLoop();
    this._renderLoop.onExecute.add(() => this._renderer.render(this._camera));
    this._renderLoop.start();
  }
  dispose() {
    this._renderLoop.dispose();
    this._viewport.dispose();
    document.removeEventListener("paste", this.handlePaste);
  }
  private registerEvents() {
    this._viewport.onResize.add((s) => {
      this._movementMultiplier = this._camera.getFov() / this._viewport.size.y;
    });
    this._viewport.onAnyDrag.add(this.handleDrag);

    document.addEventListener("paste", this.handlePaste);
  }
  private handleDrag = (event: ViewportDragEvent) => {
    this._camera.rotate(event.change.multiplyScalar(this._movementMultiplier));
  };
  private handlePaste = (e: ClipboardEvent) => {
    for (let i = 0; i < e.clipboardData.items.length; i++) {
      let item = e.clipboardData.items[i];
      if (item.type.startsWith("image")) {
        let file = item.getAsFile();
        this._renderer.setGeometryFileTexture(this._dome.id, file);
      }
    }
  };
}
