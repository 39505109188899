import React from 'react'

import App from './App'
import { CanvasEffectsPage } from './pages/CanvasEffects/CanvasEffectsPage'
import { EffectComposerPage } from './pages/EffectComposer/EffectComposerPage'
import { MoniPage } from './pages/Moni/MoniPage'
import { PanoramaPage } from './pages/Panorama/PanoramaPage'
import { ReflectionPage } from './pages/Reflection/ReflectionPage'
import { ShaderExperimentPage } from './pages/ShaderExperiment/ShaderExperimentPage'
import { ShaderFluidPage } from './pages/ShaderFluid/ShaderFluidPage'
import { TetrisPage } from './pages/Tetris/TetrisPage'
import { GalaxyGeneratorPage } from './pages/GalaxyGenerator/GalaxyGeneratorPage'
import { ShaderTerrainPage } from './pages/ShaderTerrain/ShaderTerrainPage'
import { GalaxyGenerator2Page } from './pages/GalaxyGenerator2/GalaxyGenerator2Page'
import { GalaxyGenerator3Page } from './pages/GalaxyGenerator3/GalaxyGenerator3Page'
import { GalaxyGenerator4Page } from './pages/GalaxyGenerator4/GalaxyGenerator4Page'
import { ParticleLifeWebGPUPage } from './pages/ParticleLifeWebGPU/ParticleLifeWebGPUPage'
import { FluidSimulationPage } from './pages/FluidSimulation/FluidSimulationPage'
import { PlaygroundPage } from './pages/Playground/PlaygroundPage'

export type Page = {
  name: string
  link: string
  element: JSX.Element
  hidden?: boolean
}

export const pages = (): Page[] => [
  { name: 'Home', link: '/', element: <App />, hidden: true },
  { name: 'Tetris', link: '/tetris', element: <TetrisPage /> },
  {
    name: 'Canvas effects',
    link: '/canvas-effects',
    element: <CanvasEffectsPage />,
  },
  { name: 'Panorama', link: '/panorama', element: <PanoramaPage /> },
  {
    name: 'Shader experiment',
    link: '/shader-experiment',
    element: <ShaderExperimentPage />,
  },
  { name: 'Shader fluid', link: '/shader-fluid', element: <ShaderFluidPage /> },
  //{ name: "Effect composer", link: "/effect-composer" },
  {
    name: 'Galaxy',
    link: '/galaxy',
    element: <GalaxyGeneratorPage />,
  },
  {
    name: 'Galaxy 2',
    link: '/galaxy-2',
    element: <GalaxyGenerator2Page />,
  },
  {
    name: 'Galaxy 3 - WebGPU',
    link: '/galaxy-3',
    element: <GalaxyGenerator3Page />,
  },
  {
    name: 'Galaxy 4 - WebGPU',
    link: '/galaxy-4',
    element: <GalaxyGenerator4Page />,
  },
  {
    name: 'Particle life - WebGPU',
    link: '/particle-life-webgpu',
    element: <ParticleLifeWebGPUPage />,
  },
  {
    name: 'Effect composer',
    link: '/effect-composer',
    element: <EffectComposerPage />,
  },
  {
    name: 'Shader terrain',
    link: '/shader-terrain',
    element: <ShaderTerrainPage />,
  },
  { name: 'Moni', link: '/moni', element: <MoniPage />, hidden: true },
  {
    name: 'Reflection',
    link: '/reflection',
    element: <ReflectionPage />,
    hidden: true,
  },
  {
    name: 'Fluid simulation - WebGPU',
    link: '/fluid-simulation',
    element: <FluidSimulationPage />,
    hidden: true
  },
  {
    name: 'Playground',
    link: '/playground',
    element: <PlaygroundPage />,
    hidden: true
  }
]
