import { BindGroup } from "./BindGroup";
import { BindGroupArray } from "./BindGroupArray";
import { Sampler } from "./textures/Sampler";
import { TexturePair } from "./textures/TexturePair";


export class TexturePairBindGroup extends BindGroupArray {
  private _texturePair: TexturePair;

  constructor(
    sampler: Sampler,
    texturePair: TexturePair,
    visibility: GPUShaderStageFlags = GPUShaderStage.VERTEX |
      GPUShaderStage.FRAGMENT
  ) {
    super([
      new BindGroup([sampler, texturePair.source], visibility),
      new BindGroup([sampler, texturePair.destination], visibility),
    ]);
    this._texturePair = texturePair;
  }

  bindTo(passEncoder: GPURenderPassEncoder | GPUComputePassEncoder, index?: number) {
    this.activeIndex = this._texturePair.sourceIndex;
    super.bindTo(passEncoder, index);
  }
}
