import { BaseProgram } from './BaseProgram'
import { shaders } from './shaders'
import { Camera } from 'src/core/rendering/Camera'
import { BufferedMesh } from '../BufferedMesh'
import { assertDefined } from 'src/core/utils/asserts'

export class ColoredFace extends BaseProgram {
  private _uViewProjection: WebGLUniformLocation
  private _uWorld: WebGLUniformLocation
  private _uColor: WebGLUniformLocation

  constructor(gl: WebGLRenderingContext) {
    super(gl, shaders.coloredFaceVS, shaders.coloredFacePS)

    this.createVertexAttribs('aPosition')
    this._uViewProjection = assertDefined(this._gl.getUniformLocation(
      this._program,
      'uViewProjection'
    ), 'uViewProjection location')
    this._uWorld = assertDefined(this._gl.getUniformLocation(this._program, 'uWorld'), 'uWorld location')
    this._uColor = assertDefined(this._gl.getUniformLocation(this._program, 'uColor'), 'uColor location')
  }

  render(
    items: { [id: string]: BufferedMesh },
    camera: Camera,
    transparent = false
  ) {
    this.beforeRender()

    this._gl.uniformMatrix4fv(
      this._uViewProjection,
      false,
      camera.getViewProjection().values
    )

    for (let id in items) {
      let item = items[id]

      if (item.texture) continue

      if (item.transparent !== transparent) continue

      this._gl.uniformMatrix4fv(this._uWorld, false, item.matrix)
      this._gl.uniform4fv(this._uColor, item.color)

      this._gl.bindBuffer(this._gl.ARRAY_BUFFER, item.vertexBuffer)
      this._gl.vertexAttribPointer(
        this._attribs['aPosition'],
        item.vertexSize,
        this._gl.FLOAT,
        false,
        0,
        0
      )
      this._gl.bindBuffer(this._gl.ELEMENT_ARRAY_BUFFER, item.indexBuffer)
      this._gl.drawElements(
        this._gl.TRIANGLES,
        item.indexCount,
        this._gl.UNSIGNED_SHORT,
        0
      )
    }

    this.afterRender()
  }
}
