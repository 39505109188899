import { EventHandler } from '../EventHandler'

const defaultFrameTime = 1000 / 60
const maxStep = defaultFrameTime * 4

export class RenderLoop {
  private _started = false
  private _paused = false
  private _speedFactor = 1
  private _lastTime?: number
  private _lastElapsedSeconds: number
  private _totalElapsedSeconds = 0
  private _currentAnimationFrame = 0
  private _frame = 0

  onExecute = new EventHandler<number>()

  get paused(): boolean {
    return this._paused
  }
  set paused(value: boolean) {
    value ? this.pause() : this.resume()
  }
  /** Seconds elapsed from the last frame. */
  get elapsedSeconds(): number {
    return this._lastElapsedSeconds
  }
  /** Seconds elapsed from the start. */
  get totalSeconds(): number {
    return this._totalElapsedSeconds
  }
  /** Multiplier for the elapsed time. */
  get speed(): number {
    return this._speedFactor
  }
  /** Multiplier for the elapsed time. */
  set speed(value: number) {
    this._speedFactor = value
  }
  /** Current number of executed frames. */
  get frame(): number {
    return this._frame
  }

  start() {
    if (this._started) return
    this._started = true
    this.loop()
  }
  switchPaused() {
    this._paused ? this.resume() : this.pause()
  }
  pause() {
    if (this._paused) return
    this._paused = true
  }
  resume() {
    if (!this._paused) return
    this._paused = false
    this._lastTime = undefined
    this.loop()
  }
  step() {
    if (!this._paused) {
      console.warn('Step can be executed only when paused.')
      return
    }
    this._currentAnimationFrame = requestAnimationFrame(() => {
      this.increaseTime(defaultFrameTime)
      this.onExecute.call(this.elapsedSeconds)
    })
  }
  dispose() {
    cancelAnimationFrame(this._currentAnimationFrame)
    this.pause()
    this.onExecute.dispose()
  }

  private loop() {
    this._currentAnimationFrame = requestAnimationFrame((time: number) => {
      if (this._paused) return

      this.updateTime(time)
      this.onExecute.call(this.elapsedSeconds)
      this.loop()
    })
  }
  private updateTime(totalElapsed: number) {
    if (!this._lastTime) {
      this.increaseTime(defaultFrameTime)
      this._lastTime = totalElapsed
    }
    else {
      const delta = totalElapsed - this._lastTime
      this.increaseTime(delta)
      this._lastTime = totalElapsed
    }
  }
  private increaseTime(milliseconds: number) {
    if (milliseconds > maxStep) {
      milliseconds = defaultFrameTime
    }
    this._frame++
    const elapsed = (milliseconds * this._speedFactor) / 1000
    if (this._lastElapsedSeconds) {
      // allow only 10% increased frame time form the last frame
      // avoids physics integration problems
      this._lastElapsedSeconds = Math.min(elapsed, this._lastElapsedSeconds * 1.1)
    }
    else {
      this._lastElapsedSeconds = elapsed
    }
    this._totalElapsedSeconds += this._lastElapsedSeconds
  }
}
