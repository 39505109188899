import { GUI, GUIController } from 'dat.gui'

export class Colors {
  static MAX = 16

  private _guiFolder: GUI
  private _colorControllers: GUIController[] = []
  private _obj: {}

  setGui(gui: GUI, obj: {}) {
    this._obj = obj
    
    this._guiFolder = gui.addFolder('colors')
  }

  setQuantity(value: number) {
    while (this._colorControllers.length !== value) {
      if (this._colorControllers.length > value) {
        const cc = this._colorControllers.pop()
        cc?.remove()
      } else {
        const index = this._colorControllers.length
        this._colorControllers.push(
          this._guiFolder.addColor(this._obj, index.toString())
        )
      }
    }
  }
}
