import { GalaxyConfigurator } from './GalaxyConfigurator'
import { OrbitControls } from 'src/core/rendering/OrbitControls'
import { Color } from 'src/core/Color'
import { Vector3 } from 'src/core/math/Vector3'
import { UniformStruct } from 'src/core/webGPU/Uniforms/UniformStruct'
import { Attributes } from 'src/core/webGPU/Attributes'
import { WebGPUProgram } from 'src/core/webGPU/WebGPUProgram'
import { BindGroup } from 'src/core/webGPU/BindGroup'

import starstriangleVertWGSL from './shaders/stars.vert.wgsl'
import starsFragWGSL from './shaders/stars.frag.wgsl'

class CameraStruct extends UniformStruct {
  matrix = this.addMatrix4Field()
  up = this.addVector3Field()
  right = this.addVector3Field()
}

class ConfigStruct extends UniformStruct {
  starSizeMin = this.addFloatField()
  starSizeMax = this.addFloatField()
  time = this.addFloatField()
  color1 = this.addFloatRGBColorField()
  color2 = this.addFloatRGBColorField()
}

export class GalaxyGenerator3 extends WebGPUProgram {
  private _configurator?: GalaxyConfigurator
  private _orbitControls?: OrbitControls
  private _pipeline: GPURenderPipeline

  private _cameraStruct = new CameraStruct()
  private _configStruct = new ConfigStruct()

  private _uniformGroup = new BindGroup([
    this._cameraStruct,
    this._configStruct,
  ])

  private _attributes = Attributes.fromTypes([
    'float32x3',
    'float32',
    'float32',
  ])

  async initialize(canvas: HTMLCanvasElement) {
    await super.initialize(canvas)

    this._configurator = new GalaxyConfigurator()
    this._configurator.onChange = this.optionsChangeHandler
    this._orbitControls = new OrbitControls(this._viewport)
    this._orbitControls.camera.setValues(
      new Vector3(0, 1, 0),
      new Vector3(0, 0, 0),
      new Vector3(0, 0, -1)
    )

    this.createGalaxy()
    this.createPipeline()
  }

  protected disposeProgram() {
    this._configurator?.dispose()
    this._orbitControls?.dispose()
    this._cameraStruct.dispose()
    this._configStruct.dispose()
    this._attributes.dispose()
  }

  private createPipeline() {
    this._uniformGroup.initialize(this._device)
    this._pipeline = this._device.createRenderPipeline({
      layout: this._device.createPipelineLayout({
        bindGroupLayouts: [this._uniformGroup.getLayout(this._device)],
      }),
      vertex: {
        module: this._device.createShaderModule({
          code: starstriangleVertWGSL,
        }),
        entryPoint: 'main',
        buffers: [
          {
            stepMode: 'instance',
            attributes: this._attributes.descriptor,
            arrayStride: this._attributes.elementSize,
          } as GPUVertexBufferLayout,
        ],
      },
      fragment: {
        module: this._device.createShaderModule({
          code: starsFragWGSL,
        }),
        entryPoint: 'main',
        targets: [
          {
            format: this._gpu.presentationFormat,
            blend: {
              color: {
                operation: 'add',
                srcFactor: 'src-alpha',
                dstFactor: 'one-minus-src-alpha',
              },
              alpha: {
                operation: 'add',
                srcFactor: 'one',
                dstFactor: 'one',
              },
            },
          } as GPUColorTargetState,
        ],
      },
      primitive: {
        topology: 'triangle-strip',
      },
    })
  }

  private optionsChangeHandler = () => {
    this.createGalaxy()
  }

  private createGalaxy() {
    this._attributes.createBuffer(
      this._configurator.options.stars,
      this._device,
      this._mapper
    )
  }

  protected update() {
    const { speed, stars } = this._configurator.options
    this._loop.speed = speed
    this.updateUniforms()

    const commandEncoder = this._device.createCommandEncoder()
    const textureView = this._context.getCurrentTexture().createView()
    const renderPassDescriptor: GPURenderPassDescriptor = {
      colorAttachments: [
        {
          view: textureView,
          clearValue: Color.black.toGPUColor(),
          loadOp: 'clear',
          storeOp: 'store',
        } as GPURenderPassColorAttachment,
      ],
    }

    const passEncoder = commandEncoder.beginRenderPass(renderPassDescriptor)
    passEncoder.setPipeline(this._pipeline)
    this._uniformGroup.bindTo(passEncoder)
    this._attributes.setVertexBuffer(passEncoder)
    passEncoder.draw(4, stars, 0, 0)
    passEncoder.end()

    this._device.queue.submit([commandEncoder.finish()])
  }

  private updateUniforms() {
    const { viewProjection, camera } = this._orbitControls
    const { options } = this._configurator

    this._cameraStruct.matrix.setValue(viewProjection)
    this._cameraStruct.up.setValue(camera.getUp())
    this._cameraStruct.right.setValue(camera.getRight())

    this._configStruct.starSizeMin.setValue(options.starSizeMin)
    this._configStruct.starSizeMax.setValue(options.starSizeMax)
    this._configStruct.time.setValue(this._loop.totalSeconds)
    this._configStruct.color1.setValue(Color.fromHexString(options.color1))
    this._configStruct.color2.setValue(Color.fromHexString(options.color2))
  }

  private _mapper = (buffer: ArrayBuffer): void => {
    const array = new Float32Array(buffer)
    const { options } = this._configurator
    let index = 0
    for (let star = 0; star < options.stars; star++) {
      const angle = Math.random() * Math.PI * 2

      const radius0to1 = Math.random()
      const radius = radius0to1 * options.radius

      const height = Math.random() * options.height

      // position x, y, z
      array[index++] = angle
      array[index++] = radius
      array[index++] = height
      // size 0-1
      array[index++] = Math.random()
      // start height
      array[index++] = Math.random()
    }
  }
}
