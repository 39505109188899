import { Matrix } from '../math/Matrix'
import { Quaternion } from '../math/Quaternion'
import { Vector2 } from '../math/Vector2'
import { Vector3 } from '../math/Vector3'
import { Viewport, ViewportDragEvent } from '../Viewport'
import { Camera } from './Camera'

export class OrbitControls {
  private _camera = new Camera()
  private _viewport: Viewport
  private _orbitUpAxis = new Vector3(0, 1, 0)

  constructor(viewport: Viewport) {
    this._viewport = viewport

    this._camera.setNear(0.01)
    this._camera.setFar(1000)
    this._camera.setSize(viewport.size)
    this.orbit(new Vector2(0, -60))

    this.registerEvents()
  }

  dispose() {
    this._viewport.onAnyDrag.remove(this.handleDrag)
    this._viewport.onWheel.remove(this.handleWheel)
    this._viewport.onResize.remove(this.handleResize)
  }

  get viewProjection(): Matrix {
    return this._camera.getViewProjection()
  }

  get camera(): Camera {
    return this._camera
  }

  private registerEvents() {
    this._viewport.onAnyDrag.add(this.handleDrag)
    this._viewport.onWheel.add(this.handleWheel)
    this._viewport.onResize.add(this.handleResize)
  }

  private orbit(change: Vector2) {
    const pixelsToAngle = 0.01
    const cameraDir = this._camera.getDirection()
    const cameraUp = this._camera.getUp()
    const cameraPosition = this._camera.getPosition()
    const cameraTarget = this._camera.getTarget()

    let bestForwardDirection = cameraDir
    const verticalDot = cameraDir.dot(this._orbitUpAxis)
    if (verticalDot > 0.9) {
      bestForwardDirection = cameraUp.negate()
    } else if (verticalDot < -0.9) {
      bestForwardDirection = cameraUp
    }

    const side = bestForwardDirection.cross(this._orbitUpAxis).normalize()

    const horizontalRotation = Quaternion.fromAxisAngle(
      this._orbitUpAxis,
      -change.x * pixelsToAngle
    )
    const verticalRotation = Quaternion.fromAxisAngle(
      side,
      -change.y * pixelsToAngle
    )
    const rotation = verticalRotation.multiply(horizontalRotation)

    const newPosition = cameraPosition.rotateAroundPoint(cameraTarget, rotation)
    const newUp = cameraUp.transformQuaternion(rotation)
    if (newUp.dot(this._orbitUpAxis) > 0) {
      this._camera.setValues(newPosition, cameraTarget, newUp)
    }
  }

  private pan(change: Vector2) {
    const pixelsToUnits = 0.001
    const up = this._camera.getUp()

    const rightChange = this._camera
      .getRight()
      .multiplyScalar(-change.x * pixelsToUnits)
    const upChange = up.multiplyScalar(change.y * pixelsToUnits)
    const change3D = rightChange.add(upChange)
    this._camera.move(change3D)
  }

  private zoom(value: number) {
    const delta = Math.pow(0.02, value / 10000) - 1
    const clampedDelta = Math.min(1, Math.max(-1, delta))
    const position = this._camera.getPosition()
    const target = this._camera.getTarget()

    const distance = position.distance(target) * clampedDelta
    const direction = this._camera.getDirection()
    const newPosition = position.add(direction.multiplyScalar(distance))
    this._camera.setPosition(newPosition)
  }

  private handleDrag = (e: ViewportDragEvent) => {
    if (e.mouse.right) {
      this.pan(e.change)
    } else if (e.mouse.left) {
      this.orbit(e.change)
    }
  }

  private handleWheel = (value: number) => {
    this.zoom(value)
  }

  private handleResize = (value: Vector2) => {
    this.camera.setSize(value)
  }
}
