import { Geometry3D } from './Geometry3D';
import { BufferedMesh } from './BufferedMesh';
import { BaseProgram } from './programs/BaseProgram';
import { ColoredFace } from './programs/ColoredFace';
import { TexturedFace } from './programs/TexturedFace';
import { Camera } from 'src/core/rendering/Camera';
import { Color } from '../../Color';
import { Textures } from './Textures';
import { Vector2 } from '../../math/Vector2';
import { IBaseRenderer } from '../IBaseRenderer';

export class Renderer implements IBaseRenderer {
    private _gl: WebGLRenderingContext;
    private _items: { [id: string]: BufferedMesh } = {};
    private _programs = new Array<BaseProgram>();
    private _textures: Textures;

    constructor(canvas: HTMLCanvasElement) {
        this._gl = canvas.getContext("webgl");
        this._gl.enable(this._gl.CULL_FACE);
        this._gl.cullFace(this._gl.FRONT);

        this._programs.push(new ColoredFace(this._gl));
        this._programs.push(new TexturedFace(this._gl));
        this._textures = new Textures(this._gl);
    }

    addGeometry(g: Geometry3D) {
        let bm = this._items[g.id] = new BufferedMesh(g, this._gl);
        if (g.texture) {
            this._textures.get(g.texture, t => bm.setTexture(t));
        }
    }
    setGeometryColor(id: string, color: [number, number, number, number]) {
        this._items[id].color = color;
    }
    setGeometryFileTexture(id: string, file: File) {
        this._textures.fromFile(file, t => this._items[id].setTexture(t));
    }
    setClearColor(color: Color) {
        let values = color.toFloatRGBA();
        this._gl.clearColor(values[0], values[1], values[2], values[3]);
    }
    setSize(size: Vector2) {
        this._gl.viewport(0, 0, size.x, size.y);
    }
    render(camera: Camera) {
        this._gl.clear(this._gl.COLOR_BUFFER_BIT | this._gl.DEPTH_BUFFER_BIT);

        for (let p of this._programs) {
            p.render(this._items, camera);
        }
    }
}