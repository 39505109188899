import { WebGPUProgram } from 'src/core/webGPU/WebGPUProgram'
import { UniformStruct } from 'src/core/webGPU//Uniforms/UniformStruct'
import { Textures } from './Textures'
import { ResetColors } from './passes/ResetColors'
import { ResetVelocities } from './passes/ResetVelocities'
import { UpdateColors } from './passes/UpdateColors'
import { RenderToScreen, RenderMap } from './passes/RenderToScreen'
import { Vector2 } from 'src/core/math/Vector2'
import { UpdateVelocities } from './passes/UpdateVelocities'
import { Keyboard } from 'src/core/Keyboard'
import { Key } from 'src/core/Key'
import { UpdateDivergence } from './passes/UpdateDivergence'
import { UpdatePressure } from './passes/UpdatePressure'
import { UpdateVelocitiesIntegrate } from './passes/UpdateVelocitiesIntegrate'
import { ResetPressure } from './passes/ResetPressure'
import { ViewportDragEvent } from 'src/core/Viewport'
import { isNil } from 'lodash'
import { Configurator } from './Configurator'
import { AddColorAndVelocity } from './passes/AddColorAndVelocity'

class RenderConfigStruct extends UniformStruct {
  aspectRatio = this.addFloatField()
  time = this.addFloatField()
  resolution = this.addVector2Field()
  density = this.addFloatField()
  noise = this.addVector2Field()
}

export class Program extends WebGPUProgram {
  private _renderConfigStruct = new RenderConfigStruct()

  private _textures = new Textures(new Vector2(800, 600))
  private _configurator = new Configurator()

  private _resetColors = true
  private _resetColorsPass = new ResetColors(
    this._gpu,
    this._renderConfigStruct
  )

  private _resetVelocities = true
  private _resetVelocitiesPass = new ResetVelocities(
    this._gpu,
    this._renderConfigStruct
  )

  private _resetPressurePass = new ResetPressure(
    this._gpu,
    this._renderConfigStruct
  )

  private _addColorAndVelocityPass = new AddColorAndVelocity(
    this._gpu,
    this._renderConfigStruct,
    this._textures
  )

  private _updateVelocitiesPass = new UpdateVelocities(
    this._gpu,
    this._renderConfigStruct,
    this._textures
  )

  private _updateDivergencePass = new UpdateDivergence(
    this._gpu,
    this._renderConfigStruct,
    this._textures
  )

  private _updatePressurePass = new UpdatePressure(
    this._gpu,
    this._renderConfigStruct,
    this._textures
  )

  private _updateVelocitiesIntegratePass = new UpdateVelocitiesIntegrate(
    this._gpu,
    this._renderConfigStruct,
    this._textures
  )

  private _updateColorsPass = new UpdateColors(
    this._gpu,
    this._renderConfigStruct,
    this._textures
  )

  private _colorsToScreenPass = new RenderToScreen(this._gpu, this._textures)

  private _keyboard = new Keyboard()
  private _movement?: ViewportDragEvent

  constructor() {
    super({})
  }

  async initialize(canvas: HTMLCanvasElement): Promise<void> {
    await super.initialize(canvas)
    this._textures.resize(this._viewport.size)
    this.setPatterns()

    this._resetColorsPass.createPipeline()
    this._resetVelocitiesPass.createPipeline()
    this._resetPressurePass.createPipeline()
    this._addColorAndVelocityPass.createPipeline()
    this._updateVelocitiesPass.createPipeline()
    this._updateDivergencePass.createPipeline()
    this._updatePressurePass.createPipeline()
    this._updateVelocitiesIntegratePass.createPipeline()
    this._updateColorsPass.createPipeline()
    this._colorsToScreenPass.createPipeline()

    this._keyboard.onKeyPress.add((key) => {
      if (key === Key.r) {
        this._resetColors = true
        this._resetVelocities = true
      }
    })
    this._viewport.onAnyDrag.add((e) => (this._movement = e))
    this._viewport.onResize.add((size) => this.resize(size))
    this._configurator.onChange(['colorPattern', 'velocityPattern'], () => {
      this._resetColors = true
      this._resetVelocities = true
      this.setPatterns()
    })

    this._loop.speed = 20
  }

  protected disposeProgram(): void {
    this._renderConfigStruct?.dispose()
    this._textures.dispose()
    this._configurator.dispose()
  }

  protected update(): void {
    const { renderVelocity, density, stats } = this._configurator.options
    this.showStats(stats)

    this._renderConfigStruct.aspectRatio.setValue(this._aspectRatio)
    this._renderConfigStruct.time.setValue(this._loop.elapsedSeconds)
    this._renderConfigStruct.resolution.setValue(this._size)
    this._renderConfigStruct.density.setValue(density)

    this._colorsToScreenPass.renderMap = renderVelocity
      ? RenderMap.velocity
      : RenderMap.color

    if (this._resetColors) {
      this._resetColorsPass.run(this._textures.colors.source)
      this._resetColorsPass.run(this._textures.colors.destination)
      this._resetColors = false
    }
    if (this._resetVelocities) {
      this._resetVelocitiesPass.run(this._textures.velocity.source)
      this._resetVelocitiesPass.run(this._textures.velocity.destination)
      this._resetVelocities = false
    }

    if (this._configurator.options.addVelocityPattern === 'mouseDrag') {
      this.tryAddMouseVelocity()
    } else {
      this._addColorAndVelocityPass.run(
        new Vector2(),
        new Vector2(),
        this._loop.totalSeconds
      )
    }

    this._updateVelocitiesPass.run()
    this._updateDivergencePass.run()
    this._resetPressurePass.run(this._textures.pressure.source)
    for (let i = 0; i < 4; i++) {
      this._updatePressurePass.run()
    }
    this._updateVelocitiesIntegratePass.run()
    this._updateColorsPass.run()
    this._colorsToScreenPass.run()
  }

  private resize(size: Vector2) {
    this._textures.resize(size)
    this._resetColors = true
    this._resetVelocities = true
  }

  private setPatterns() {
    const { colorPattern, velocityPattern, addVelocityPattern } =
      this._configurator.options
    this._resetColorsPass.setPattern(colorPattern)
    this._resetVelocitiesPass.setPattern(velocityPattern)
    this._addColorAndVelocityPass.setPattern(addVelocityPattern)
  }

  private tryAddMouseVelocity() {
    if (isNil(this._movement) || this._movement.change.isZero()) {
      return
    }
    const start = this._movement.position.subtract(this._movement.change)
    this._addColorAndVelocityPass.run(
      start,
      this._movement.position,
      this._loop.totalSeconds
    )
    this._movement = undefined
  }
}
