import { useEffect, useRef } from "react";
import { ShaderFluid } from "./ShaderFluid";

export const ShaderFluidPage = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const sf = new ShaderFluid();
    sf.initialize(canvasRef.current!);
    return () => {
      sf.dispose();
    };
  }, []);

  return <canvas ref={canvasRef}/>;
};
