import { useEffect, useRef, useState } from "react";
import { EffectComposer } from "./EffectComposer";
import { Configuration } from "./components/Configuration";

import styles from "./EffectComposerPage.module.css";

export const EffectComposerPage = () => {
  const canvasRef = useRef<HTMLCanvasElement>();
  const composer = useRef<EffectComposer>();
  const [logs, setLogs] = useState<string[]>([]);

  const handleShaderChange = (shader: string) => {
    setLogs(composer.current?.setShader(shader));
  };

  useEffect(() => {
    composer.current = new EffectComposer();
    composer.current.initialize(canvasRef.current).then(() => {
      composer.current.start();
    });

    return () => {
      composer.current.dispose();
    };
  }, []);

  return (
    <>
      <canvas className={styles.canvas} ref={canvasRef} />
      <Configuration logs={logs} onShaderChanged={handleShaderChange} />
    </>
  );
};
