import { Vector2 } from './math/Vector2'
import { EventHandler } from './EventHandler'
import { Camera } from './rendering/Camera'
import { IBaseRenderer } from './rendering/IBaseRenderer'

export interface ViewportMoveEvent {
  /** Current mouse position. */
  position: Vector2
  change?: Vector2
  ctrl: boolean
}

export interface ViewportDragEvent extends ViewportMoveEvent {
  mouse: {
    left: boolean
    right: boolean
    middle: boolean
  }
  change: Vector2
}

export class Viewport {
  private _canvas: HTMLCanvasElement
  private _lastMousePosition: Vector2
  private _lastTouches: TouchList
  private _filledWindow = false
  private _camera: Camera
  private _renderer: IBaseRenderer
  private _size: Vector2

  onAnyDrag = new EventHandler<ViewportDragEvent>()
  onWheel = new EventHandler<number>()
  onPoiterStart = new EventHandler<Vector2>()
  onPointerMove = new EventHandler<ViewportMoveEvent>()
  onResize = new EventHandler<Vector2>()

  constructor(element: HTMLCanvasElement) {
    this._canvas = element
    this._size = new Vector2(this._canvas.width, this._canvas.height)
    this.registerEvents()
  }

  get size(): Vector2 {
    return this._size
  }
  setCamera(val: Camera) {
    this._camera = val
    this._camera.setSize(this._size)
  }
  setRenderer(val: IBaseRenderer) {
    this._renderer = val
    this._renderer.setSize(this._size)
  }
  fillWindow() {
    const targetSize = this.getWindowSize()
    this._canvas.style.position = 'absolute'
    this._canvas.style.left = '0px'
    this._canvas.style.top = '0px'
    this._filledWindow = true
    this.setSize(targetSize)
  }
  getWindowSize() {
    return new Vector2(window.innerWidth, window.innerHeight)
  }
  dispose() {
    this._canvas.removeEventListener('mousedown', this.mouseDown)
    this._canvas.removeEventListener('mousemove', this.mouseMove)
    this._canvas.removeEventListener('touchstart', this.touchStart)
    this._canvas.removeEventListener('touchmove', this.touchMove)
    this._canvas.removeEventListener('touchend', this.touchEnd)
    this._canvas.removeEventListener('wheel', this.wheel)

    window.removeEventListener('resize', this.resize)
    this.onAnyDrag.dispose()
    this.onWheel.dispose()
    this.onPointerMove.dispose()
    this.onPoiterStart.dispose()
    this.onResize.dispose()
  }
  private setSize(size: Vector2) {
    this._canvas.width = size.x
    this._canvas.height = size.y
    this._size = new Vector2(this._canvas.width, this._canvas.height)

    this._camera?.setSize(size)
    this._renderer?.setSize(size)

    this.onResize.call(size)
  }
  private registerEvents() {
    // Prevents selection and context menu on right click
    this._canvas.oncontextmenu = () => false
    this._canvas.onselectstart = () => false

    this._canvas.addEventListener('mousedown', this.mouseDown)
    this._canvas.addEventListener('mousemove', this.mouseMove)
    this._canvas.addEventListener('touchstart', this.touchStart)
    this._canvas.addEventListener('touchmove', this.touchMove)
    this._canvas.addEventListener('touchend', this.touchEnd)
    this._canvas.addEventListener('wheel', this.wheel)

    window.addEventListener('resize', this.resize)
  }

  private mouseDown = (e: MouseEvent) => {
    const pos = new Vector2(e.x, e.y)
    this.onPoiterStart.call(pos)
  }
  private mouseMove = (e: MouseEvent) => {
    const position = new Vector2(e.x, e.y)
    const change = this._lastMousePosition
      ? position.subtract(this._lastMousePosition)
      : null
    const moveEvent: ViewportMoveEvent = {
      position,
      change,
      ctrl: e.ctrlKey,
    }
    this.onPointerMove.call(moveEvent)
    if (e.buttons && change != null) {
      this.onAnyDrag.call({
        ...moveEvent,
        change,
        mouse: {
          left: (e.buttons & 1) === 1,
          right: (e.buttons & 2) === 2,
          middle: (e.buttons & 4) === 4,
        },
      })
    }
    this._lastMousePosition = position
  }
  private touchStart = (e: TouchEvent) => {
    let pos = new Vector2(
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY
    )
    this.onPoiterStart.call(pos)

    // Prevents mousedown event
    e.preventDefault()
  }
  private touchMove = (e: TouchEvent) => {
    let position = new Vector2(
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY
    )
    this.onPointerMove.call({
      position,
      ctrl: false,
    })

    if (this.onAnyDrag.length > 0) {
      this.checkDrag(e.touches)
    }

    this._lastTouches = e.touches

    // Prevents pull-to-refresh behavior on chrome
    e.preventDefault()
  }
  private touchEnd = (e: TouchEvent) => {
    if (e.touches.length === 0) this._lastTouches = null
  }
  private wheel = (e: WheelEvent) => {
    this.onWheel.call(e.deltaY)
  }
  private resize = () => {
    if (this._filledWindow) {
      this.fillWindow()
    }
  }
  private checkDrag(touches: TouchList) {
    if (!this._lastTouches) return

    for (let i = 0; i < touches.length; i++) {
      let first = touches[i]
      for (let j = 0; j < this._lastTouches.length; j++) {
        let second = this._lastTouches[j]
        if (second.identifier === first.identifier) {
          let p1 = new Vector2(first.clientX, first.clientY)
          let p2 = new Vector2(second.clientX, second.clientY)
          this.onAnyDrag.call({
            position: p1,
            change: p1.subtract(p2),
            mouse: {
              left: true,
              right: false,
              middle: false,
            },
            ctrl: false,
          })
          return
        }
      }
    }
  }
}
