import { Character } from './Character';
import { Renderer2D } from 'src/core/rendering/webgl2/Renderer2D';
import { geometries } from 'src/core/rendering/webgl/geometries';
import { Vector2 } from 'src/core/math/Vector2';
import { sguid } from 'src/core/sguid';
import { Matrix } from 'src/core/math/Matrix';
import { Color } from 'src/core/Color';
import { Vector3 } from 'src/core/math/Vector3';
import { Circle } from 'src/core/physics2D/collision/shapes/Circle';

export class Player extends Character {
    private _geometryId: string;
    static readonly _size = 10;

    constructor() {
        super();
        this._life = 100;
        this._totalLife = 100;
        this._position = new Vector2();
        this.shape = new Circle(new Vector2(), Player._size / 2);
    }

    rendererUpdade(renderer: Renderer2D) {
        if (!this._geometryId) {
            this._geometryId = sguid();
            let g = geometries.rectangle2D(new Vector2(0, 0), new Vector2(Player._size, Player._size), this._geometryId);
            g.color = Color.blue;
            renderer.addGeometry(g);
        }

        renderer.setGeometryTransform(this._geometryId, Matrix.translation(Vector3.fromVector2(this.position, 1)));
    }

}