import { useEffect, useRef, useState } from "react";
import { CanvasEffects } from "./CanvasEffects";

export const CanvasEffectsPage = () => {
  const [effectName, setEffectName] = useState("");
  const canvasRef = useRef<HTMLCanvasElement>();

  useEffect(() => {
    const ce = new CanvasEffects(canvasRef.current);
    ce.onEffectChange.add(setEffectName);
    ce.initialize();
    return () => {
      ce.dispose();
    };
  }, []);

  return (
    <div>
      <canvas ref={canvasRef} width="640" height="640"></canvas>
      <p>Arrows to change effect.</p>
      <p>{effectName}</p>
    </div>
  );
};
