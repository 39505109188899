import { ChangeEvent } from "react";
import { RangeData } from "./OptionData";

type Props = {
  data: RangeData;
};

export const RangeOption = ({ data }: Props) => {
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    data.onChange(e.target.valueAsNumber);
  };

  return (
    <input
      type="range"
      min={data.min}
      max={data.max}
      defaultValue={data.value}
      step={data.step}
      onChange={changeHandler}
    />
  );
};
