import { BaseProgram } from './BaseProgram'

export class Sprite extends BaseProgram {
  private _aPosition = 'aPosition'
  private _aTexCoord = 'aTexCoord'

  constructor(gl: WebGLRenderingContext, vsSource: string, psSource: string) {
    super(gl, vsSource, psSource)

    this.createVertexAttribs(this._aPosition, this._aTexCoord)

    this.uniforms.setInt('uSampler', 0)
  }

  render(
    vertexBuffer: WebGLBuffer,
    texCoordBuffer: WebGLBuffer,
    transparent?: boolean,
    renderTarget?: WebGLBuffer
  ): void {
    this.beforeRender()

    this._gl.activeTexture(this._gl.TEXTURE0)
    this._gl.bindTexture(this._gl.TEXTURE_2D, renderTarget)

    this._gl.bindBuffer(this._gl.ARRAY_BUFFER, vertexBuffer)
    this._gl.vertexAttribPointer(
      this.getAttrib(this._aPosition),
      2,
      this._gl.FLOAT,
      false,
      0,
      0
    )
    this._gl.bindBuffer(this._gl.ARRAY_BUFFER, texCoordBuffer)
    this._gl.vertexAttribPointer(
      this.getAttrib(this._aTexCoord),
      2,
      this._gl.FLOAT,
      false,
      0,
      0
    )
    this._gl.drawArrays(this._gl.TRIANGLE_STRIP, 0, 4)

    this.afterRender()
  }
}
