import { Vector2 } from 'src/core/math/Vector2'
import { Bindable } from '../Bindable'
import { EventHandler } from 'src/core/EventHandler'

export interface TextureConfig {
  width: number
  height: number
  format?: GPUTextureFormat
  samplerType?: GPUTextureSampleType
}

export class Texture implements Bindable {
  private _descriptor?: GPUTextureDescriptor
  private _resource?: { texture: GPUTexture; view: GPUTextureView }
  private _imageBitmapPromise?: Promise<ImageBitmap>
  private _imageBitmap?: ImageBitmap
  private _config?: TextureConfig
  
  readonly onUpdated = new EventHandler<void>()

  async finishLoading(): Promise<void> {
    this._imageBitmap = await this._imageBitmapPromise
  }

  fromUrl(url: string): this {
    this._imageBitmapPromise = this.loadFromUrl(url)
    return this
  }

  setImage(image: ImageBitmap): this {
    this._imageBitmap?.close()
    this._imageBitmap = image
    return this
  }

  fromConfig(config: TextureConfig): this {
    this._descriptor = {
      size: [config.width, config.height, 1],
      format: config.format ?? 'rgba8unorm',
      usage:
        GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.RENDER_ATTACHMENT,
    }
    return this
  }

  getLayout(): Partial<GPUBindGroupLayoutEntry> {
    return {
      texture: {
        sampleType: this._config?.samplerType ?? 'float',
      },
    }
  }

  getOrCreateResource(device: GPUDevice): GPUTextureView {
    if (this._resource) {
      return this._resource.view
    }
    return this.createResource(device)
  }

  createResource(device: GPUDevice): GPUTextureView {
    if (this._resource) {
      this._resource.texture.destroy()
    }
    const texture = this._imageBitmap
      ? this.createResourceFromImageBitmap(device)
      : device.createTexture(this._descriptor)
    this._resource = {
      texture,
      view: texture.createView(),
    }
    return this._resource.view
  }

  resize(size: Vector2): void {
    this._descriptor = { ...this._descriptor, size: [size.x, size.y] }
    if (this._resource) {
      this._resource.texture.destroy()
      this._resource = null
    }
    this.onUpdated.call()
  }

  dispose(): void {
    this._resource?.texture.destroy()
    this._imageBitmap?.close()
    this.onUpdated.dispose()
  }

  private createResourceFromImageBitmap(device: GPUDevice): GPUTexture {
    this._descriptor = {
      size: [this._imageBitmap.width, this._imageBitmap.height, 1],
      format: 'rgba8unorm',
      usage:
        GPUTextureUsage.TEXTURE_BINDING |
        GPUTextureUsage.COPY_DST |
        GPUTextureUsage.RENDER_ATTACHMENT,
    }
    const texture = device.createTexture(this._descriptor)
    device.queue.copyExternalImageToTexture(
      { source: this._imageBitmap },
      { texture: texture },
      [this._imageBitmap.width, this._imageBitmap.height]
    )
    return texture
  }

  private async loadFromUrl(url: string): Promise<ImageBitmap> {
    const response = await fetch(url)
    return await createImageBitmap(await response.blob())
  }
}
