import styles from "./Help.module.css";

type Props = { onHide: () => void };

export const Help = ({ onHide }: Props) => {
  return (
    <div className={styles.help}>
      <a href="http://reznik.lt/">Example from reznik.lt</a>
      <span>Paste an image from the clipboard.</span>
      <span>Drag to look around.</span>
      <button onClick={onHide}>Hide this</button>
    </div>
  );
};
