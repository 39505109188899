import { Vector2 } from 'src/core/math/Vector2'
import { Geometry } from './Geometry'

export class Geometry2D extends Geometry {
  vertices?: Vector2[]

  getVertices(): Float32Array {
    return Vector2.float32Array(this.vertices ?? [])
  }
  get vertexSize() {
    return 2
  }
}
