import { Vector2 } from 'src/core/math/Vector2'
import { Texture, TextureConfig } from 'src/core/webGPU/textures/Texture'
import { TexturePair } from 'src/core/webGPU/textures/TexturePair'

const colorConfig: Partial<TextureConfig> = {
  format: 'rgba8unorm',
}
const velocityConfig: Partial<TextureConfig> = {
  // TODO: is it possible to use filterable 32 bit?
  format: 'rg16float',
}
const divergenceConfig: Partial<TextureConfig> = {
  format: 'r16float',
}
const pressureConfig: Partial<TextureConfig> = {
  format: 'r16float',
}

export class Textures {
  private _baseConfig: TextureConfig
  readonly colors: TexturePair
  readonly velocity: TexturePair
  readonly divergence: Texture
  readonly pressure: TexturePair

  constructor(size: Vector2) {
    this._baseConfig = {
      width: Math.floor(size.x),
      height: Math.floor(size.y),
    }

    this.colors = new TexturePair([
      new Texture().fromConfig(this.makeConfig(colorConfig)),
      new Texture().fromConfig(this.makeConfig(colorConfig)),
    ])
    this.velocity = new TexturePair([
      new Texture().fromConfig(this.makeConfig(velocityConfig)),
      new Texture().fromConfig(this.makeConfig(velocityConfig)),
    ])
    this.divergence = new Texture().fromConfig(this.makeConfig(divergenceConfig))
    this.pressure = new TexturePair([
      new Texture().fromConfig(this.makeConfig(pressureConfig)),
      new Texture().fromConfig(this.makeConfig(pressureConfig)),
    ])
  }

  private makeConfig(config: Partial<TextureConfig>): TextureConfig {
    return { ...this._baseConfig, ...config }
  }

  dispose() {
    this.colors.dispose()
    this.velocity.dispose()
    this.divergence.dispose()
    this.pressure.dispose()
  }

  resize(size: Vector2) {
    this.colors.resize(size)
    this.velocity.resize(size)
    this.divergence.resize(size)
    this.pressure.resize(size)
  }
}
