import { IRenderable } from 'src/core/rendering/webgl/IRenderable';
import { Vector2 } from 'src/core/math/Vector2';
import { Renderer2D } from 'src/core/rendering/webgl2/Renderer2D';
import { geometries } from 'src/core/rendering/webgl/geometries';
import { Color } from 'src/core/Color';
import { Matrix } from 'src/core/math/Matrix';
import { Vector3 } from 'src/core/math/Vector3';
import { ICollidable } from 'src/core/physics2D/collision/ICollidable';
import { Line } from 'src/core/physics2D/collision/shapes/Line';

export class Wall implements IRenderable, ICollidable {
    static thickness = 10;
    static color = new Color(50, 50, 50);
    points: Vector2[];
    normal: Vector2;
    translation: Vector2;
    rotation: number;
    shape: Line;

    constructor(points: Vector2[], translation: Vector2, rotation: number) {
        this.points = points;
        this.normal = new Vector2(0, -1).rotateZ(rotation);
        this.translation = translation;
        this.rotation = rotation;
        let change = this.normal.multiplyScalar(Wall.thickness).selfAdd(translation);
        this.shape = new Line(this.points.map(p => p.rotateZ(rotation).selfAdd(change)));
    }

    rendererUpdade(renderer: Renderer2D) {
        let center = this.points[0].lerp(this.points[1], 0.5).add(new Vector2(0, -Wall.thickness / 2));
        let size = this.points[0].distance(this.points[1]);
        let g = geometries.rectangle2D(center, new Vector2(size, Wall.thickness));
        g.color = Wall.color;
        renderer.addGeometry(g);

        let transform = Matrix.rotationZ(this.rotation).multiply(Matrix.translation(Vector3.fromVector2(this.translation, 1)))
        renderer.setGeometryTransform(g.id, transform);
    }

}