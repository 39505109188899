import { Camera } from 'src/core/rendering/Camera'
import { BufferedMesh } from '../BufferedMesh'
import {
  createFragmentShader,
  createVertexShader,
} from '../shaders/shaderHelper'
import { Uniforms } from '../Uniforms'
import { ShaderCompilationResult } from '../shaders/ShaderCompilationResult'
import { assertDefined } from 'src/core/utils/asserts'

export class ProgramException extends Error {
  constructor(public logs: string[]) {
    super()
  }
}

export abstract class BaseProgram {
  protected _gl: WebGLRenderingContext
  protected _program: WebGLProgram
  protected _attribs: { [key: string]: number }
  private _uniforms: Uniforms
  private _vertexResult: ShaderCompilationResult
  private _fragmentResult: ShaderCompilationResult

  constructor(gl: WebGLRenderingContext, vsSource: string, fsSource: string) {
    this._gl = gl
    this._attribs = {}

    this._vertexResult = createVertexShader(this._gl, vsSource)
    this._fragmentResult = createFragmentShader(this._gl, fsSource)

    if (!this._vertexResult.success || !this._fragmentResult.success) {
      throw new ProgramException(this.getLogs())
    }

    this._program = assertDefined(this._gl.createProgram(), 'program')
    this._gl.attachShader(this._program, this._vertexResult.shader)
    this._gl.attachShader(this._program, this._fragmentResult.shader)
    this._gl.linkProgram(this._program)

    this._uniforms = new Uniforms(this._program, this._gl)

    this.checkLinkStatus()
  }

  abstract render(
    items: { [id: string]: BufferedMesh },
    camera: Camera,
    transparent?: boolean
  ): void

  private replaceShader(current: WebGLShader, replacement: WebGLShader) {
    this._gl.detachShader(this._program, current)
    this._gl.deleteShader(current)
    this._gl.attachShader(this._program, replacement)

    this._gl.linkProgram(this._program)
    this.checkLinkStatus()

    this._uniforms.setProgram(this._program)
  }
  private checkLinkStatus() {
    if (!this._gl.getProgramParameter(this._program, this._gl.LINK_STATUS)) {
      const log = this._gl.getProgramInfoLog(this._program)
      throw new ProgramException(['Could not link WebGL program', log ?? ''])
    }
  }

  protected createVertexAttribs(...names: string[]) {
    for (let name of names)
      this._attribs[name] = this._gl.getAttribLocation(this._program, name)
  }
  protected createUniform(name: string): WebGLUniformLocation {
    return assertDefined(
      this._gl.getUniformLocation(this._program, name),
      `uniform ${name}`
    )
  }
  protected getAttrib(name: string): number {
    return this._attribs[name]
  }
  protected beforeRender() {
    this._gl.useProgram(this._program)
    for (let key in this._attribs)
      this._gl.enableVertexAttribArray(this._attribs[key])

    this._uniforms.update()
  }
  protected afterRender() {
    for (let key in this._attribs)
      this._gl.disableVertexAttribArray(this._attribs[key])
  }
  protected get uniforms(): Uniforms {
    return this._uniforms
  }

  setVertexShader(shader: string): string[] {
    const result = createVertexShader(this._gl, shader)
    if (result.success) {
      this.replaceShader(this._vertexResult.shader, result.shader)
      this._vertexResult = result
    }
    return result.logs
  }
  setFragmentShader(shader: string): string[] {
    const result = createFragmentShader(this._gl, shader)
    if (result.success) {
      this.replaceShader(this._fragmentResult.shader, result.shader)
      this._fragmentResult = result
    }
    return result.logs
  }
  getLogs(): string[] {
    return [...this._vertexResult.logs, ...this._fragmentResult.logs]
  }
  dispose() {
    this._gl.deleteProgram(this._program)
    this._gl.deleteShader(this._vertexResult.shader)
    this._gl.deleteShader(this._fragmentResult.shader)
  }
}
