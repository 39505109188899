import { Vector3 } from 'src/core/math/Vector3';
import { Geometry } from './Geometry';

export class Geometry3D extends Geometry {
    vertices?: Vector3[];

    getVertices(): Float32Array {
        return Vector3.float32Array(this.vertices ?? []);
    }
    get vertexSize() {
        return 3;
    }
}