import { makeNoise2D } from "fast-simplex-noise";
import { Color } from "src/core/Color";
import { Vector2 } from "src/core/math/Vector2";

export class Emmiter {
  color = Color.random
  position = Vector2.randomDirection.multiplyScalar(0.3).selfAdd(new Vector2(0.5, 0.5))
  angle = 0
  speed = 0
  radius = 10
  private _noise2D = makeNoise2D()

  constructor(position?: Vector2) {
    this.position = position ?? this.position
  }

  update(totalTime: number) {
    this.angle = Math.PI * 2 * this._noise2D(totalTime * 0.01, 0)
    this.speed = 0.5 + Math.abs(this._noise2D(0, totalTime * 0.1))
  }
}