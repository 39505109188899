import { RenderLoop } from "../../core/rendering/RenderLoop";
import { RenderTarget } from "../../core/rendering/RenderTarget";
import { FullScreenSprite } from "../../core/rendering/webgl/programs/FullScreenSprite";
import { WebGLTools } from "../../core/rendering/WebGLTools";
import { Viewport } from "../../core/Viewport";
import { Program } from "./Program";

/* TODO: ideas and fixes
 *
 */

export class Shaders1 {
  private _gl: WebGLRenderingContext;
  private _viewport: Viewport;
  private _indexCount: number;
  private _vBuffer: WebGLBuffer;
  private _iBuffer: WebGLBuffer;
  private _program: Program;
  private _target: RenderTarget;
  private _sprite: FullScreenSprite;
  private _loop: RenderLoop;

  private _opacity = 0.125;
  private _blend = true;
  private _inverted = false;
  private _smooth = true;
  private _rgbSpeeds = [1, 1, 1];
  private _rgbPhases = [0, 0, 0];
  private _rgbTimes = [0, 0, 0];
  private _blendFunctions = [
    "ZERO",
    "ONE",
    "SRC_COLOR",
    "ONE_MINUS_SRC_COLOR",
    "DST_COLOR",
    "ONE_MINUS_DST_COLOR",
    "SRC_ALPHA",
    "ONE_MINUS_SRC_ALPHA",
    "DST_ALPHA",
    "ONE_MINUS_DST_ALPHA",
  ];
  private _sourceBlendFunction = "ONE_MINUS_DST_ALPHA";
  private _destinationBlendFunction = "DST_COLOR";
  private _shaderFunctions: { [key: string]: Program | null } = {
    diamond: null,
    circle: null,
  };
  private _shaderFunction = "diamond";

  id = Math.random();

  constructor() {
    this._loop = new RenderLoop();
    this._loop.speed = 0.2;
  }

  async initialize(canvas: HTMLCanvasElement) {
    this._viewport = new Viewport(canvas);
    await this.initGL(canvas);
    this.registerEvents();
    this._viewport.fillWindow();
  }
  dispose() {
    this._loop.dispose();
    this._viewport.dispose();
    this._program?.dispose();
    this._target?.dispose();
    this._sprite?.dispose();
    if (this._iBuffer) {
      this._gl.deleteBuffer(this._iBuffer);
    }
    if (this._vBuffer) {
      this._gl.deleteBuffer(this._vBuffer);
    }
  }

  setRGBSpeed(index: 0 | 1 | 2, value: number) {
    this._rgbSpeeds[index] = value;
  }
  getRGBSpeed(): number[] {
    return this._rgbSpeeds;
  }
  setRGBPhase(index: 0 | 1 | 2, value: number) {
    this._rgbPhases[index] = value;
  }
  getRGBPhases(): number[] {
    return this._rgbPhases;
  }
  getSpeedFactor(): number {
    return this._loop.speed;
  }
  setSpeedFactor(value: number) {
    this._loop.speed = value;
  }
  setBlend(value: boolean) {
    this._blend = value;
    if (this._blend) {
      this._gl.enable(this._gl.BLEND);
      this._gl.blendEquation(this._gl.FUNC_ADD);
      this.applyBlendFunc();
    } else {
      this._gl.disable(this._gl.BLEND);
    }
  }
  getBlend(): boolean {
    return this._blend;
  }
  setInverted(value: boolean) {
    this._inverted = value;
  }
  getInverted(): boolean {
    return this._inverted;
  }
  getSmooth(): boolean {
    return this._smooth;
  }
  setSmooth(value: boolean) {
    this._smooth = value;
    this._program.setSmooth(this._smooth);
  }
  getFunctions(): string[] {
    return Object.keys(this._shaderFunctions);
  }
  getFunction(): string {
    return this._shaderFunction;
  }
  setFunction(value: string) {
    this._shaderFunction = value;
    this.updateProgram();
  }
  setOpacity(value: number) {
    this._opacity = value;
    this.applyOpacity();
  }
  getOpacity(): number {
    return this._opacity;
  }
  getBlendFunctions(): string[] {
    return this._blendFunctions;
  }
  getSourceBlendFunction(): string {
    return this._sourceBlendFunction;
  }
  getDestinationBlendFunction(): string {
    return this._destinationBlendFunction;
  }
  setSourceBlendFunction(value: string) {
    this._sourceBlendFunction = value;
    this.applyBlendFunc();
  }
  setDestinationBlendFunction(value: string) {
    this._destinationBlendFunction = value;
    this.applyBlendFunc();
  }
  start() {
    this._loop.start();
  }
  drawTarget() {
    this._target.enable();
    this._program.renderVertices(
      this._vBuffer,
      this._iBuffer,
      this._indexCount
    );
    this._target.disable();
  }
  drawCanvas() {
    this._gl.clear(this._gl.COLOR_BUFFER_BIT | this._gl.DEPTH_BUFFER_BIT);
    this._sprite.render(this._target.texture);
    this._target.enable();
  }
  draw(elapsed: number) {
    this.updateTime(elapsed);
    this.drawTarget();
    this.drawCanvas();
  }
  increaseTime(seconds: number) {
    let times = [0, 0, 0];
    for (let i = 0; i < 3; i++) {
      this._rgbTimes[i] += seconds * this._rgbSpeeds[i];
      times[i] = this._rgbTimes[i] + this._rgbPhases[i];
    }
    this._program.setTime(times);
  }

  private async initGL(canvas: HTMLCanvasElement) {
    const context = canvas.getContext("webgl")
    if (!context) {
      throw new Error('Failed to create webgl context')
    }
    this._gl = context
    this._target = new RenderTarget(this._gl, 800, 600);
    this._target.enable();
    this._sprite = new FullScreenSprite(this._gl);

    await this.createShaders();
    this.updateProgram();

    this._gl.clearColor(0, 0, 0, 0);
    this._gl.enable(this._gl.CULL_FACE);
    this._gl.blendEquation(this._gl.FUNC_ADD);
    this._gl.enable(this._gl.DEPTH_TEST);
    this._gl.depthFunc(this._gl.LEQUAL);

    this.applyBlendFunc();
    this.applyOpacity();
    this.setBlend(true);
  }
  private registerEvents() {
    this._viewport.onResize.add((s) => {
      this.createBuffers();
      this._gl.viewport(0, 0, s.x, s.y);
      this._target.setSize(s.x, s.y);
      this._program.setSize(s);
    });
    this._loop.onExecute.add((elapsed) => this.draw(elapsed));
  }
  private createBuffers() {
    // Buffers already exist. Delete them
    if (this._vBuffer) {
      this._gl.deleteBuffer(this._vBuffer);
      this._gl.deleteBuffer(this._iBuffer);
    }

    this._indexCount = 6;
    let positions = new Float32Array([-1, -1, -1, 1, 1, -1, 1, 1]);
    this._vBuffer = WebGLTools.createStaticVertexBuffer(this._gl, positions);

    const indices = new Uint16Array([0, 3, 1, 0, 2, 3]);
    this._iBuffer = WebGLTools.createStaticIndexBuffer(this._gl, indices);
  }
  private async createShaders() {
    let vsSource = await this.getShaderFromFile("vs");
    let psSource = await this.getShaderFromFile("ps1");

    for (let functionName in this._shaderFunctions) {
      let source = psSource;
      for (let i = 0; i < 3; i++)
        source = source.replace("function", functionName);

      this._shaderFunctions[functionName] = new Program(
        this._gl,
        vsSource,
        source
      );
    }
  }
  private async getShaderFromFile(fileName: string): Promise<string> {
    return await (
      await fetch(`shaders/shaders1/${fileName}.glsl`)
    ).text();
  }
  private updateProgram() {
    this._program = this._shaderFunctions[this._shaderFunction]!;
    this._program.setSize(this._viewport.size);
    this._program.setOpacity(this._opacity);
    this._program.setSmooth(this._smooth);
  }
  private updateTime(elapsed: number) {
    if (this._inverted) elapsed = -elapsed;

    this.increaseTime(elapsed);
  }
  private applyOpacity() {
    if (this._gl) this._program.setOpacity(this._opacity);
  }
  private applyBlendFunc() {
    if (this._gl) {
      // let src = this._gl[this._sourceBlendFunction];
      // let dst = this._gl[this._destinationBlendFunction];
      this._gl.blendEquation(this._gl.FUNC_ADD);
      //this._gl.blendFunc(src, dst);
      //this._gl.blendFuncSeparate(src, dst, this._gl.ONE, this._gl.ONE_MINUS_SRC_ALPHA);
      this._gl.blendFuncSeparate(
        this._gl.SRC_ALPHA,
        this._gl.ONE_MINUS_SRC_ALPHA,
        this._gl.SRC_ALPHA,
        this._gl.ONE
      );
    }
  }
}
