import { Rectangle } from "src/core/math/Rectangle";
import { Vector2 } from "src/core/math/Vector2";
import { sguid } from "src/core/sguid";
import { IShape, ShapeType } from "../IShape";

export abstract class BaseShape implements IShape {
  readonly id: string;
  readonly type: ShapeType;
  abstract get rectangle(): Rectangle;
  abstract intersectsRectangle(rectangle: Rectangle): boolean;
  abstract move(change: Vector2): void;

  constructor(type: ShapeType) {
    this.id = sguid();
  }
}
