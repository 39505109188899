import { GUI } from 'dat.gui'

export interface GalaxyOptions {
  stars: number
  branches: number
  branchConcentration: number
  spiralAngle: number
  radius: number
  height: number
  spin: boolean
}

export class GalaxyConfigurator {
  private _gui: GUI
  onChange: () => void
  options: GalaxyOptions = {
    stars: 1000,
    branches: 2,
    branchConcentration: 2,
    spiralAngle: Math.PI,
    radius: 4,
    height: 0.3,
    spin: true,
  }

  constructor() {
    this._gui = new GUI({ width: 300 })
    this._gui.useLocalStorage = true
    this._gui.remember(this.options)

    this._gui
      .add(this.options, 'stars')
      .min(10)
      .max(50000)
      .step(10)
      .onChange(this._onChangeHandler)

    this._gui
      .add(this.options, 'branches')
      .min(2)
      .max(20)
      .step(1)
      .onChange(this._onChangeHandler)

    this._gui
      .add(this.options, 'branchConcentration')
      .min(1)
      .max(6)
      .step(0.1)
      .onChange(this._onChangeHandler)

    this._gui
      .add(this.options, 'spiralAngle')
      .min(0)
      .max(Math.PI * 4)
      .step(0.1)
      .onChange(this._onChangeHandler)

    this._gui
      .add(this.options, 'radius')
      .min(1)
      .max(20)
      .step(0.1)
      .onChange(this._onChangeHandler)

    this._gui
      .add(this.options, 'height')
      .min(0.01)
      .max(5)
      .step(0.01)
      .onChange(this._onChangeHandler)

    this._gui.add(this.options, 'spin')
  }
  dispose() {
    this._gui.destroy()
  }

  private _onChangeHandler = () => {
    this._gui.save()
    this.onChange()
  }
}
