import { useEffect, useRef } from 'react'
import { GalaxyGenerator4 } from './GalaxyGenerator4'
import { PageInformation } from 'src/components/PageInformation'

export const GalaxyGenerator4Page = () => {
  const canvasRef = useRef<HTMLCanvasElement>()

  useEffect(() => {
    const program = new GalaxyGenerator4()
    const initPromise = program.initialize(canvasRef.current).then(() => {
      program.start()
    })
    return () => {
      initPromise.then(() => {
        program.dispose()
      })
    }
  }, [])

  const informations = [
    'Left mouse to rotate, right to pan, scroll to zoom',
    'P to pause, L to execute a single frame',
    'Hold CTRL and move the mouse cursor to move the center of gravity',
  ]

  return (
    <>
      <canvas ref={canvasRef} />
      <PageInformation lines={informations}></PageInformation>
    </>
  )
}
