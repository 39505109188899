import { ExternalLink } from './components/ExternalLink'
import { pages } from './pages'

function App() {
  const isDev = false
  return (
    <div>
      {pages()
        .filter((page) => isDev || !page.hidden)
        .map((page) => (
          <p key={page.name}>
            <a href={page.link}>{page.name}</a>
          </p>
        ))}
      <p>
        <ExternalLink
          text="Facebook"
          link="https://www.facebook.com/diego.tadiotto"
        />
        |
        <ExternalLink text="Blog" link="http://tadiotto.blogspot.com" />|
        <ExternalLink text="GitHub" link="https://github.com/tadiotto" />|
        <ExternalLink text="e-mail" link="mailto:tadiotto@gmail.com" />
      </p>
    </div>
  )
}

export default App
