import { Geometry2D } from '../webgl/Geometry2D';
import { BufferedMesh } from '../webgl/BufferedMesh';
import { BaseProgram } from '../webgl/programs/BaseProgram';
import { ColoredFace } from '../webgl/programs/ColoredFace';
import { TexturedFace } from '../webgl/programs/TexturedFace';
import { Camera } from 'src/core/rendering/Camera';
import { Color } from '../../Color';
import { Textures } from '../webgl/Textures';
import { Vector2 } from '../../math/Vector2';
import { IBaseRenderer } from '../IBaseRenderer';
import { Matrix } from 'src/core/math/Matrix';

export class Renderer2D implements IBaseRenderer {
    private _gl: WebGL2RenderingContext;
    private _items: { [id: string]: BufferedMesh } = {};
    private _programs = new Array<BaseProgram>();
    private _textures: Textures;

    constructor(canvas: HTMLCanvasElement) {
        this._gl = canvas.getContext("webgl2");
        this._gl.enable(this._gl.CULL_FACE);
        this._gl.cullFace(this._gl.FRONT);
        this._gl.enable(this._gl.DEPTH_TEST);

        this._programs.push(new ColoredFace(this._gl));
        this._programs.push(new TexturedFace(this._gl));
        this._textures = new Textures(this._gl);
    }

    addGeometry(g: Geometry2D) {
        let bm = this._items[g.id] = new BufferedMesh(g, this._gl);
        if (g.texture) {
            this._textures.get(g.texture, t => bm.setTexture(t));
        }
    }
    setGeometryColor(id: string, color: [number, number, number, number]) {
        this._items[id].color = color;
    }
    setGeometryFileTexture(id: string, file: File) {
        this._textures.fromFile(file, t => this._items[id].setTexture(t));
    }
    setGeometryTransform(id: string, transform: Matrix) {
        this._items[id].matrix = transform.values;
    }
    setClearColor(color: Color) {
        let values = color.toFloatRGBA();
        this._gl.clearColor(values[0], values[1], values[2], values[3]);
    }
    setSize(size: Vector2) {
        this._gl.viewport(0, 0, size.x, size.y);
    }
    render(camera: Camera) {
        this._gl.clear(this._gl.COLOR_BUFFER_BIT | this._gl.DEPTH_BUFFER_BIT);

        // Opaques
        this._gl.disable(this._gl.BLEND);
        for (let p of this._programs) {
            p.render(this._items, camera, false);
        }

        // Transparents
        this._gl.enable(this._gl.BLEND);
        this._gl.blendFuncSeparate(this._gl.SRC_ALPHA, this._gl.ONE_MINUS_SRC_ALPHA, this._gl.SRC_ALPHA, this._gl.ONE);
        for (let p of this._programs) {
            p.render(this._items, camera, true);
        }
    }
}