import { BindGroup } from 'src/core/webGPU/BindGroup'
import { Texture } from 'src/core/webGPU/textures/Texture'
import { Color } from 'src/core/Color'
import { Bindable } from 'src/core/webGPU/Bindable'
import resetColorsShader from './shaders/resetPressure.wgsl'
import { GPU } from 'src/core/webGPU/GPU'
import { RenderPipeline } from 'src/core/webGPU/RenderPipeline'

export class ResetPressure {
  private _gpu: GPU
  private _pipeline: RenderPipeline
  private _bindGroup: BindGroup

  constructor(gpu: GPU, configStruct: Bindable) {
    this._gpu = gpu
    this._bindGroup = new BindGroup([configStruct])
    this._pipeline = new RenderPipeline(
      {
        label: 'ResetPressurePass pipeline',
        vertex: {
          module: { code: resetColorsShader },
          entryPoint: 'vertex',
        },
        fragment: {
          module: { code: resetColorsShader },
          entryPoint: 'fragment',
          targets: [
            {
              format: 'r16float',
            } as GPUColorTargetState,
          ],
        },
        primitive: {
          topology: 'triangle-strip',
        },
      },
      [this._bindGroup]
    )
  }

  createPipeline() {
    this._pipeline.initialize(this._gpu.device)
  }

  run(destination: Texture) {
    const device = this._gpu.device
    const commandEncoder = device.createCommandEncoder({
      label: 'ResetPressurePass',
    })

    const textureView = destination.getOrCreateResource(device)
    const renderPassDescriptor: GPURenderPassDescriptor = {
      colorAttachments: [
        {
          view: textureView,
          clearValue: Color.black.toGPUColor(),
          loadOp: 'clear',
          storeOp: 'store',
        } as GPURenderPassColorAttachment,
      ],
    }

    const passEncoder = commandEncoder.beginRenderPass(renderPassDescriptor)
    this._pipeline.bindTo(passEncoder)
    passEncoder.draw(4, 1, 0, 0)
    passEncoder.end()

    device.queue.submit([commandEncoder.finish()])
  }
}
