import { Attributes } from './Attributes'
import { Bindable } from './Bindable'

export interface AttributesBindOptions {
  /** Default false. */
  readOnly: boolean
}

export class AttributesBind implements Bindable {
  private _options: AttributesBindOptions
  private _attributes: Attributes

  constructor(
    attributes: Attributes,
    options?: Partial<AttributesBindOptions>
  ) {
    this._attributes = attributes
    this._options = {
      readOnly: true,
      ...options,
    }
  }
  getLayout(): Partial<GPUBindGroupLayoutEntry> {
    return {
      buffer: {
        type: this._options.readOnly ? 'read-only-storage' : 'storage',
      },
    }
  }
  getOrCreateResource(device: GPUDevice): GPUBindingResource {
    return {
      buffer: this._attributes.buffer,
      offset: 0,
      size: this._attributes.buffer.size,
    }
  }
  dispose() {
    throw new Error('Method not implemented.')
  }
}
