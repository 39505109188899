import { Vector2 } from 'src/core/math/Vector2'
import { Collision } from './Collision'
import { IShape, ShapeType } from './IShape'
import { IShapeCollider } from './IShapeCollider'
import { CircleLine } from './colliders/CircleLine'

export class Scene {
  private _shapes: IShape[]

  constructor() {
    this._shapes = []
  }

  addShape(shape: IShape) {
    this._shapes.push(shape)
  }
  tryMove(shape: IShape, change: Vector2): Collision {
    let result = new Collision()
    if (change.isZero()) return result

    // A rectangle that encapsulates where the shape is and where it will be after moved
    let rect = shape.rectangle.clone()
    rect.increaseByVector(change)
    rect.increaseAllSides(0.0001)

    for (let other of this._shapes) {
      if (other === shape) continue
      if (!other.intersectsRectangle(rect)) continue

      let collider = this.getCollider(shape, other)
      if (!collider) {
        console.warn(
          `No collider found for types ${shape.type} and ${other.type}`
        )
        continue
      }

      result.mergeWith(collider.tryMove(shape, change, other))
    }
    return result
  }

  private getCollider(moving: IShape, other: IShape): IShapeCollider | null {
    switch (moving.type) {
      case ShapeType.Circle:
        switch (other.type) {
          case ShapeType.Line:
            return new CircleLine()
          default:
            return null
        }
      default:
        return null
    }
  }
}
