import { Vector2 } from "src/core/math/Vector2";
import { ShapeType } from "../IShape";
import { Rectangle } from "src/core/math/Rectangle";
import { BaseShape } from "./BaseShape";

export class Line extends BaseShape {
  points: Vector2[];
  normal: Vector2;
  direction: Vector2;
  normalDot: number;
  directionDots: number[];
  readonly _rectangle: Rectangle;

  constructor(points: Vector2[]) {
    super(ShapeType.Line);
    this.points = points;

    this.direction = points[0].directionTo(points[1]);
    this.directionDots = points
      .map((p) => p.dot(this.direction))
      .sort((a, b) => a - b);

    this.normal = this.direction.rotateZ(Math.PI / 2);
    this.normalDot = this.points[0].dot(this.normal);
    this._rectangle = Rectangle.fromPoints(this.points);
  }

  get rectangle(): Rectangle {
    return this._rectangle;
  }
  intersectsRectangle(rect: Rectangle): boolean {
    let nDots = [rect.min.dot(this.normal), rect.max.dot(this.normal)].sort(
      (a, b) => a - b
    );

    if (nDots[0] > this.normalDot || nDots[1] < this.normalDot) return false;

    let dDots = [
      rect.min.dot(this.direction),
      rect.max.dot(this.direction),
    ].sort((a, b) => a - b);

    if (dDots[0] > this.directionDots[1] || dDots[1] < this.directionDots[0])
      return false;

    return true;
  }
  move(change: Vector2) {}
  /**
   * Returns true if the projection of the point on the line direction is over the line.
   * @param point Point to test.
   */
  projectionInside(point: Vector2): boolean {
    let dot = point.dot(this.direction);
    return dot >= this.directionDots[0] && dot < this.directionDots[1];
  }
}
