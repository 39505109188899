import { BaseProgram } from "./BaseProgram";
import { shaders } from './shaders';
import { BufferedMesh } from '../BufferedMesh';
import { Camera } from 'src/core/rendering/Camera';

export class TexturedFace extends BaseProgram {
    private _uViewProjection: WebGLUniformLocation;
    private _uWorld: WebGLUniformLocation;
    private _uSampler: WebGLUniformLocation;

    constructor(gl: WebGLRenderingContext) {
        super(gl, shaders.faceTexVSSource, shaders.faceTexPSSource);

        this.createVertexAttribs("aPosition");
        this.createVertexAttribs("aTexCoord");

        this._uViewProjection = this._gl.getUniformLocation(this._program, "uViewProjection");
        this._uWorld = this._gl.getUniformLocation(this._program, "uWorld");
        this._uSampler = this._gl.getUniformLocation(this._program, "uSampler");
    }

    render(items: { [key: string]: BufferedMesh }, camera: Camera) {
        this.beforeRender();

        this._gl.uniformMatrix4fv(this._uViewProjection, false, camera.getViewProjection().values);

        for (let id in items) {
            let item = items[id];

            if (!item.texture)
                continue;

            this._gl.uniformMatrix4fv(this._uWorld, false, item.matrix);

            this._gl.activeTexture(this._gl.TEXTURE0);
            this._gl.bindTexture(this._gl.TEXTURE_2D, item.texture.value);
            this._gl.uniform1i(this._uSampler, 0);

            this._gl.bindBuffer(this._gl.ARRAY_BUFFER, item.vertexBuffer);
            this._gl.vertexAttribPointer(this._attribs["aPosition"], item.vertexSize, this._gl.FLOAT, false, 0, 0);
            this._gl.bindBuffer(this._gl.ARRAY_BUFFER, item.texCoordBuffer);
            this._gl.vertexAttribPointer(this._attribs["aTexCoord"], 2, this._gl.FLOAT, false, 0, 0);
            this._gl.bindBuffer(this._gl.ELEMENT_ARRAY_BUFFER, item.indexBuffer);
            this._gl.drawElements(this._gl.TRIANGLES, item.indexCount, this._gl.UNSIGNED_SHORT, 0);

        }

        this.afterRender();
    }
}