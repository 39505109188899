import { PerspectiveCamera, Quaternion, Vector3 } from 'three'

export class CameraAutoSpin {
  private _axis: Vector3
  private _camera: PerspectiveCamera

  constructor(camera: PerspectiveCamera) {
    this._camera = camera
    this._axis = new Vector3(0, 0, 1)
  }

  spin(elapsedSeconds: number) {
    const angle = elapsedSeconds * 0.5
    const rotation = new Quaternion().setFromAxisAngle(this._axis, angle)
    this._camera.position.applyQuaternion(rotation)
    this._camera.rotateOnWorldAxis(this._axis, angle)
  }
}
