import { Matrix } from 'src/core/math/Matrix'
import { Vector2 } from '../../math/Vector2'
import { Texture } from './Texture'
import { Color } from '../../Color'
import { Geometry } from './Geometry'
import { WebGLTools } from '../WebGLTools'
import { assertIsDefined } from 'src/core/utils/asserts'

export class BufferedMesh {
  private _gl: WebGLRenderingContext

  vertexBuffer: WebGLBuffer
  texCoordBuffer: WebGLBuffer
  indexBuffer: WebGLBuffer
  indexCount: number
  color: [number, number, number, number]
  texture: Texture
  matrix: Float32Array
  normalMatrix: Float32Array
  vertexSize: number
  transparent: boolean

  constructor(source: Geometry, gl: WebGLRenderingContext) {
    assertIsDefined(source.indices, 'source.indices')

    this._gl = gl

    this.vertexBuffer = WebGLTools.createStaticVertexBuffer(
      this._gl,
      source.getVertices()
    )
    this.indexBuffer = WebGLTools.createStaticIndexBuffer(
      this._gl,
      new Int16Array(source.indices)
    )
    this.indexCount = source.indices.length
    this.vertexSize = source.vertexSize
    this.transparent = source.transparent

    if (source.color) this.color = source.color.toFloatRGBA()
    else this.color = Color.white.toFloatRGBA()

    if (source.coords)
      this.texCoordBuffer = WebGLTools.createStaticVertexBuffer(
        this._gl,
        Vector2.float32Array(source.coords)
      )

    this.matrix = Matrix.identity().values
  }

  setTexture(texture: Texture) {
    this.texture = texture
  }
  setColor(color: [number, number, number, number]) {
    this.color = color
  }
  dispose() {
    this._gl.deleteBuffer(this.vertexBuffer)
    this._gl.deleteBuffer(this.indexBuffer)

    if (this.texCoordBuffer) this._gl.deleteBuffer(this.texCoordBuffer)
  }
}
