import { Matrix } from "./Matrix";
import { Vector3 } from "./Vector3";
import { BoundingBox } from "./BoundingBox";
import { Plane } from "./Plane";

export class Ray {
  constructor(
    public position = new Vector3(),
    public direction = new Vector3()
  ) {}

  positionAt(dist: number): Vector3 {
    return this.direction.multiplyScalar(dist).selfAdd(this.position);
  }
  transform(m: Matrix): Ray {
    return new Ray(
      this.position.transform(m),
      this.direction.transformNormal(m)
    );
  }
  intersectsBB(bb: BoundingBox, maxValue?: number): number | null {
    let result = 0;
    if (maxValue == null) maxValue = Number.MAX_VALUE;
    if (Math.abs(this.direction.x) < 1e-6) {
      if (this.position.x < bb.min.x || this.position.x > bb.max.x) return null;
    } else {
      let num8 = (bb.min.x - this.position.x) / this.direction.x;
      let num7 = (bb.max.x - this.position.x) / this.direction.x;
      if (num8 > num7) {
        let aux = num8;
        num8 = num7;
        num7 = aux;
      }
      result = Math.max(num8, result);
      maxValue = Math.min(num7, maxValue);
      if (result > maxValue) return null;
    }

    if (Math.abs(this.direction.y) < 1e-6) {
      if (this.position.y < bb.min.y || this.position.y > bb.max.y) return null;
    } else {
      let num6 = (bb.min.y - this.position.y) / this.direction.y;
      let num5 = (bb.max.y - this.position.y) / this.direction.y;
      if (num6 > num5) {
        let aux = num6;
        num6 = num5;
        num5 = aux;
      }
      result = Math.max(num6, result);
      maxValue = Math.min(num5, maxValue);
      if (result > maxValue) return null;
    }
    if (Math.abs(this.direction.z) < 1e-6) {
      if (this.position.z < bb.min.z || this.position.z > bb.max.z) return null;
    } else {
      let num4 = (bb.min.z - this.position.z) / this.direction.z;
      let num3 = (bb.max.z - this.position.z) / this.direction.z;
      if (num4 > num3) {
        let num12 = num4;
        num4 = num3;
        num3 = num12;
      }
      result = Math.max(num4, result);
      maxValue = Math.min(num3, maxValue);
      if (result > maxValue) return null;
    }
    return result;
  }
  intersectsSphere(pos: Vector3, r: number): number | null {
    // Posição do raio até centro da esfera
    let rpc = this.position.subtract(pos);
    let dot = rpc.dot(this.direction);
    let n = dot * dot - rpc.lengthSquared() + r * r;
    if (n < 0) return null;
    let sqrt = Math.sqrt(n);
    let d = Math.min(-dot + sqrt, -dot - sqrt);
    return d;
  }

  distanceToPlane(plane: Plane): number | null {
    const dot = this.direction.dot(plane.normal)
    const normalDistance = plane.normal.dot(this.position) - plane.d
    return normalDistance / -dot
  }
}
