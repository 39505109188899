export const getShaderFromFile = async (fileName: string): Promise<string> => {
  return await (
    await fetch(`shaders/effect-composer/${fileName}.glsl`, {
      cache: "no-cache",
    })
  ).text();
};

const shaderNames = [
  "solid-color",
  "solid-color-time-based",
  "color-by-pixel-and-time",
  "mouse",
  "refracted-rings"
];
export const builtInShaders = shaderNames.map((s) => ({ id: s, name: s }));
export const defaultShaderIndex = 4;