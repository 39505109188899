import { BooleanOption } from "./BooleanOption";
import { BooleanData, OptionData, RangeData, SelectData } from "./OptionData";
import { RangeOption } from "./RangeOption";
import { SelectOption } from "./SelectOption";

type OptionProps = {
  data: OptionData;
};

export const Option = ({ data }: OptionProps) => {
  switch (data.type) {
    case "range":
      return <RangeOption data={data as RangeData} />;
    case "boolean":
      return <BooleanOption data={data as BooleanData} />;
    case "select":
      const selectData = data as SelectData;
      return (
        <SelectOption
          values={selectData.values}
          value={selectData.value}
          onChange={selectData.onChange}
        />
      );
  }
};
