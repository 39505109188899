export class Column {
  name: string;
  isSelected: boolean;
  values: string[];
  private _distinct: { [key: string]: boolean };

  constructor(name: string) {
    this.name = name;
    this.values = [];
    this._distinct = {};
  }
  addValue(v: string) {
    v = v || "";
    if (v === "+") v = "'+";
    this.values.push(v);
    this._distinct[v] = true;
  }
  get distinct(): string[] {
    return Object.keys(this._distinct).sort();
  }
}
