import { FormEvent, useEffect, useState } from "react";
import { Processor } from "./Processor";

import styles from "./MoniPage.module.css";
import { Result } from "./Result";
import { ResultComponent } from "./ResultComponent";

export const MoniPage = () => {
  const [inputText, setInputText] = useState("");
  const [hideEmpty, setHideEmpty] = useState(true);
  const [result, setResult] = useState<Result>();

  const inputChangeHandler = (e: FormEvent<HTMLTextAreaElement>): void => {
    setInputText(e.currentTarget.value);
  };
  const hideEmptyChangeHandler = (e: FormEvent<HTMLInputElement>): void => {
    setHideEmpty(e.currentTarget.checked);
  };

  useEffect(() => {
    setResult(new Processor().run(inputText, hideEmpty));
  }, [inputText, hideEmpty]);

  return (
    <div>
      <p>Tabela de dados</p>
      <textarea className={styles.text} onChange={inputChangeHandler} />
      <br />
      <span>Ocultar zerados</span>
      <input
        type="checkbox"
        onChange={hideEmptyChangeHandler}
        checked={hideEmpty}
      />
      <ResultComponent result={result} />
    </div>
  );
};
