
export enum Key {
    a = 'KeyA',
    b = 'KeyB',
    c = 'KeyC',
    d = 'KeyD',
    e = 'KeyE',
    f = 'KeyF',
    g = 'KeyG',
    h = 'KeyH',
    i = 'KeyI',
    j = 'KeyJ',
    k = 'KeyK',
    l = 'KeyL',
    m = 'KeyM',
    n = 'KeyN',
    o = 'KeyO',
    p = 'KeyP',
    q = 'KeyQ',
    r = 'KeyR',
    s = 'KeyS',
    t = 'KeyT',
    u = 'KeyU',
    v = 'KeyV',
    w = 'KeyW',
    x = 'KeyX',
    y = 'KeyY',
    z = 'KeyZ',
    period = 'Period',
    ctrlLeft = 'ControlLeft',
    ctrlRight = 'ControlRight',
    numpad0 = 'Numpad0',
    numpad1 = 'Numpad1',
    numpad2 = 'Numpad2',
    numpad3 = 'Numpad3',
    numpad4 = 'Numpad4',
    numpad5 = 'Numpad5',
    numpad6 = 'Numpad6',
    numpad7 = 'Numpad7',
    numpad8 = 'Numpad8',
    numpad9 = 'Numpad9'
}
