import { useEffect, useRef, useState } from "react";
import { ConfigurationsSection } from "../../components/configurations/ConfigurationsSection";
import { OptionList } from "../../components/configurations/OptionList";
import { createProperties } from "./createProperties";
import { Shaders1 } from "./Shaders1";

import styles from "./ShaderExperimentPage.module.css";

export const ShaderExperimentPage = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [initialized, setInitialized] = useState(false);
  const shaders = useRef<Shaders1>();

  useEffect(() => {
    shaders.current = new Shaders1();
    shaders.current.initialize(canvasRef.current).then(() => {
      //this._optionsRepository.load();
      shaders.current.start();
      setInitialized(true);
    });
    return () => {
      shaders.current.dispose();
    };
  }, []);

  return (
    <div>
      <canvas className={styles.canvas} ref={canvasRef} />
      <div className={styles.configurations}>
        <ConfigurationsSection>
          {initialized && (
            <OptionList options={createProperties(shaders.current)} />
          )}
        </ConfigurationsSection>
      </div>
    </div>
  );
};
