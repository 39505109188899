import { IMatrix } from "./interfaces";

export class Vector2 {
  x: number;
  y: number;

  constructor(x?: number, y?: number) {
    this.x = x || 0;
    this.y = y || 0;
  }

  clone(): Vector2 {
    return new Vector2(this.x, this.y);
  }
  add(other: Vector2): Vector2 {
    return new Vector2(this.x + other.x, this.y + other.y);
  }
  subtract(other: Vector2): Vector2 {
    return new Vector2(this.x - other.x, this.y - other.y);
  }
  multiplyScalar(scalar: number): Vector2 {
    return new Vector2(this.x * scalar, this.y * scalar);
  }
  divide(other: Vector2): Vector2 {
    return new Vector2(this.x / other.x, this.y / other.y);
  }
  length(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }
  normalize(): Vector2 {
    var length = this.length();
    return new Vector2(this.x / length, this.y / length);
  }
  /**
   * Rotates the point counterclockwise relative to the origin.
   * @param angle Angle in radians.
   */
  rotateZ(angle: number): Vector2 {
    var sin = Math.sin(angle);
    var cos = Math.cos(angle);
    return new Vector2(
      this.x * cos - this.y * sin,
      this.x * sin + this.y * cos
    );
  }
  /**
   * Returns the linear interpolation between this and another point by a given factor.
   * Examples: factor 0 is the first point; factor 1 is the second point; factor 0.5 is the point between them.
   * @param other The second point.
   * @param factor The interpolation factor.
   */
  lerp(other: Vector2, factor: number): Vector2 {
    let diff = other.subtract(this);
    return this.add(diff.multiplyScalar(factor));
  }
  /**
   * Returns the distance between this point and another.
   * @param other The second point.
   */
  distance(other: Vector2): number {
    return other.subtract(this).length();
  }
  transform(m: IMatrix): Vector2 {
    return new Vector2(
      this.x * m.values[0] + this.y * m.values[4] + m.values[8] + m.values[12],
      this.x * m.values[1] + this.y * m.values[5] + m.values[9] + m.values[13]
    );
  }
  transformNormal(m: IMatrix): Vector2 {
    return new Vector2(
      this.x * m.values[0] + this.y * m.values[4] + m.values[8],
      this.x * m.values[1] + this.y * m.values[5] + m.values[9]
    );
  }
  /**
   * Returns the dot product between this vector and another one.
   * @param other Second vector.
   */
  dot(other: Vector2): number {
    return this.x * other.x + this.y * other.y;
  }
  /**
   * Returns a Vector2 where each component (x, y) is the smaller value between them and the parameter value.
   * @param value Value to test.
   */
  minScalar(value: number): Vector2 {
    return new Vector2(Math.min(this.x, value), Math.min(this.y, value));
  }
  /**
   * Returns the direction as a unit vector from this point to another.
   * @param other The other point.
   */
  directionTo(other: Vector2): Vector2 {
    return other.subtract(this).selfNormalize();
  }
  /**
   * Returns a new vector with the smallest x and y from this and the other vector.
   * @param other Other vector.
   */
  min(other: Vector2): Vector2 {
    return new Vector2(Math.min(this.x, other.x), Math.min(this.y, other.y));
  }
  /**
   * Returns a new vector with the biggest x and y from this and the other vector.
   * @param other Other vector.
   */
  max(other: Vector2): Vector2 {
    return new Vector2(Math.max(this.x, other.x), Math.max(this.y, other.y));
  }
  /**
   * Returns a new vector with the desired length.
   * @param length The desired length.
   */
  withLength(length: number): Vector2 {
    if (length === 0) return new Vector2(0, 0);
    let multiplier = length / this.length();
    return this.multiplyScalar(multiplier);
  }
  negate(): Vector2 {
    return new Vector2(-this.x, -this.y);
  }
  isZero(): boolean {
    return this.x === 0 && this.y === 0;
  }
  selfAdd(other: Vector2): Vector2 {
    this.x += other.x;
    this.y += other.y;
    return this;
  }
  selfSubtract(other: Vector2): Vector2 {
    this.x -= other.x;
    this.y -= other.y;
    return this;
  }
  selfMultiplyScalar(scalar: number): Vector2 {
    this.x *= scalar;
    this.y *= scalar;
    return this;
  }
  selfMultiply(other: Vector2): Vector2 {
    this.x *= other.x
    this.y *= other.y
    return this
  }
  selfDivide(other: Vector2): Vector2 {
    this.x /= other.x;
    this.y /= other.y;
    return this;
  }
  selfNormalize(): Vector2 {
    var length = this.length();
    this.x /= length;
    this.y /= length;
    return this;
  }
  selfNegate(): Vector2 {
    this.x = -this.x;
    this.y = -this.y;
    return this;
  }
  selfFloor(): Vector2 {
    this.x = Math.floor(this.x);
    this.y = Math.floor(this.y);
    return this;
  }
  selfMin(other: Vector2): Vector2 {
    this.x = Math.min(this.x, other.x);
    this.y = Math.min(this.y, other.y);
    return this;
  }
  selfMax(other: Vector2): Vector2 {
    this.x = Math.max(this.x, other.x);
    this.y = Math.max(this.y, other.y);
    return this;
  }
  toString(): string {
    return `{ x: ${this.x}, y: ${this.y} }`;
  }
  toArray(): Float32Array {
    return new Float32Array([this.x, this.y]);
  }

  static float32Array(source: Vector2[]): Float32Array {
    let result = new Float32Array(source.length * 2);
    for (let i = 0; i < source.length; i++) {
      let i2 = i * 2;
      result[i2] = source[i].x;
      result[i2 + 1] = source[i].y;
    }
    return result;
  }

  static get random(): Vector2 {
    return new Vector2(Math.random(), Math.random())
  }
  
  static get randomDirection(): Vector2 {
    const angle = Math.random() * Math.PI * 2
    return new Vector2(Math.cos(angle), Math.sin(angle))
  }
}
