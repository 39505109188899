import { UniformType } from './UniformField'

export const typesAlignments: Record<UniformType, number> = {
  float4x4: 4 * Float32Array.BYTES_PER_ELEMENT,
  float3: 4 * Float32Array.BYTES_PER_ELEMENT,
  float4: 4 * Float32Array.BYTES_PER_ELEMENT,
  float2: 2 * Float32Array.BYTES_PER_ELEMENT,
  float: 1 * Float32Array.BYTES_PER_ELEMENT,
}

export const typesSizes: Record<UniformType, number> = {
  float4x4: 4 * 4 * Float32Array.BYTES_PER_ELEMENT,
  float3: 3 * Float32Array.BYTES_PER_ELEMENT,
  float4: 4 * Float32Array.BYTES_PER_ELEMENT,
  float2: 2 * Float32Array.BYTES_PER_ELEMENT,
  float: 1 * Float32Array.BYTES_PER_ELEMENT,
}

export const getOffsetToFitValue = (
  startOffset: number,
  valueByteLength: number,
  valueMinAlignment: number,
  bufferAlignment: number
): number => {
  let offset = startOffset
  const bufferAlignmentOffset = startOffset % bufferAlignment
  const isAlignedToBuffer = bufferAlignmentOffset === 0
  if (!isAlignedToBuffer) {
    const offsetToAlign = bufferAlignment - bufferAlignmentOffset
    if (offsetToAlign < valueByteLength) {
      offset += offsetToAlign
    }
  }
  const itemAlignmentOffset = offset % valueMinAlignment
  const isAlignedToItemAlignment = itemAlignmentOffset === 0
  if (!isAlignedToItemAlignment) {
    const offsetToAlign = valueMinAlignment - itemAlignmentOffset
    offset += offsetToAlign
  }
  return offset
}

export const getByteLengthToAlign = (currentByte: number, bufferAlignment: number): number => {
  // complete the buffer size to reach the alignment
  const unalignedBytes = currentByte % bufferAlignment
  if (unalignedBytes > 0) {
    return bufferAlignment - unalignedBytes
  }
  return 0
}