import styles from "./TableComponent.module.css";

type Props = {
  header: string[];
  rows: string[][];
};

export const TableComponent = ({ header, rows }: Props) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {header.map((name, index) => (
            <th key={index}>
              {name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((values, rowIndex) => (
          <tr key={rowIndex}>
            {values.map((value, cellIndex) => (
              <td key={cellIndex}>
                {value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
