import { ChangeEvent } from "react";
import { BooleanData } from "./OptionData";

type Props = {
  data: BooleanData;
};

export const BooleanOption = ({ data }: Props) => {
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    data.onChange(e.target.checked);
  };
  return <input type="checkbox" defaultChecked={data.value} onChange={changeHandler} />;
};
