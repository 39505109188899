import { Vector2 } from 'src/core/math/Vector2'
import {
  getOffsetToFitValue,
  typesAlignments,
  typesSizes,
} from './alignment'
import { UniformField, UniformType } from './UniformField'
import { Vector3 } from 'src/core/math/Vector3'
import { Color } from 'src/core/Color'
import { Matrix } from 'src/core/math/Matrix'

export abstract class UniformValueField<T> implements UniformField {
  private _byteStart = 0
  private _buffer: GPUBuffer
  private _device: GPUDevice
  readonly type: UniformType
  readonly byteLength: number
  readonly minAlignment: number

  protected constructor(type: UniformType) {
    this.type = type
    this.byteLength = typesSizes[this.type]
    this.minAlignment = typesAlignments[this.type]
  }

  get byteStart(): number {
    return this._byteStart
  }

  get buffer(): GPUBuffer {
    return this._buffer
  }

  setByteRange(startByteIndex: number, bufferAlignment: number) {
    this._byteStart = getOffsetToFitValue(
      startByteIndex,
      this.byteLength,
      this.minAlignment,
      bufferAlignment
    )
  }

  setBuffer(buffer: GPUBuffer, device: GPUDevice): void {
    this._buffer = buffer
    this._device = device
  }

  protected setData(content: Float32Array | Uint32Array) {
    this._device.queue.writeBuffer(
      this._buffer,
      this._byteStart,
      content.buffer,
      content.byteOffset,
      content.byteLength
    )
  }

  abstract setValue(value: T): void
}

export class UniformFloatField extends UniformValueField<number> {
  constructor() {
    super('float')
  }
  setValue(value: number): void {
    this.setData(new Float32Array([value]))
  }
}
export class UniformVector2Field extends UniformValueField<Vector2> {
  constructor() {
    super('float2')
  }
  setValue(value: Vector2): void {
    this.setData(value.toArray())
  }
}
export class UniformVector3Field extends UniformValueField<Vector3> {
  constructor() {
    super('float3')
  }
  setValue(value: Vector3): void {
    this.setData(value.toArray())
  }
}
export class UniformFloatRGBColorField extends UniformValueField<Color> {
  constructor() {
    super('float3')
  }
  setValue(value: Color): void {
    this.setData(new Float32Array(value.toFloatRGB()))
  }
}
export class UniformMatrix4Field extends UniformValueField<Matrix> {
  constructor() {
    super('float4x4')
  }
  setValue(value: Matrix): void {
    this.setData(value.values)
  }
}
