export class WebGLTools {
  

  private static createShader(
    gl: WebGLRenderingContext,
    type: number,
    source: string
  ): WebGLShader {
    let shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      console.error(gl.getShaderInfoLog(shader));
      return null;
    }

    return shader;
  }
  private static createStaticBuffer(
    gl: WebGLRenderingContext,
    array: ArrayBuffer,
    target: number
  ): WebGLBuffer {
    let result = gl.createBuffer();
    gl.bindBuffer(target, result);
    gl.bufferData(target, array, gl.STATIC_DRAW);
    return result;
  }
  static createVertexShader(
    gl: WebGLRenderingContext,
    source: string
  ): WebGLShader {
    return this.createShader(gl, gl.VERTEX_SHADER, source);
  }
  static createFragmentShader(
    gl: WebGLRenderingContext,
    source: string
  ): WebGLShader {
    return this.createShader(gl, gl.FRAGMENT_SHADER, source);
  }
  static createStaticVertexBuffer(
    gl: WebGLRenderingContext,
    array: ArrayBuffer
  ): WebGLBuffer {
    return this.createStaticBuffer(gl, array, gl.ARRAY_BUFFER);
  }
  static createStaticIndexBuffer(
    gl: WebGLRenderingContext,
    array: ArrayBuffer
  ): WebGLBuffer {
    return this.createStaticBuffer(gl, array, gl.ELEMENT_ARRAY_BUFFER);
  }

  static create3DContext(
    canvas: HTMLCanvasElement,
    opt_attribs: any[]
  ): WebGLRenderingContext {
    let names = ["webgl", "experimental-webgl", "webkit-3d", "moz-webgl"];
    let context: WebGLRenderingContext = null;
    for (let ii = 0; ii < names.length; ++ii) {
      try {
        context = canvas.getContext(
          names[ii],
          opt_attribs
        ) as WebGLRenderingContext;
      } catch (e) {}
      if (context) {
        break;
      }
    }
    return context;
  }
}
