import { OptionData } from "../../components/configurations/OptionData";
import { Shaders1 } from "./Shaders1";

const range = (
  name: string,
  value: number,
  min: number,
  max: number,
  step: number,
  onChange: (value: number) => void
): OptionData => {
  return {
    id: name,
    type: "range",
    name,
    value,
    min,
    max,
    step,
    onChange,
  };
};
const bool = (
  name: string,
  value: boolean,
  onChange: (value: boolean) => void
): OptionData => {
  return {
    id: name,
    type: "boolean",
    name,
    value,
    onChange,
  };
};
const select = (
  name: string,
  value: string,
  values: string[],
  onChange: (value: string) => void
): OptionData => {
  return {
    id: name,
    type: "select",
    name,
    value,
    values: values.map((v) => {
      return { name: v, id: v };
    }),
    onChange,
  };
};

export const createProperties = (source: Shaders1): OptionData[] => {
  return [
    range("Speed", source.getSpeedFactor(), 0, 1, 0.01, (value) =>
      source.setSpeedFactor(value)
    ),
    bool("Invert", source.getInverted(), (value) => source.setInverted(value)),
    range("Red speed", source.getRGBSpeed()[0], 0, 1, 0.01, (value) =>
      source.setRGBSpeed(0, value)
    ),
    range("Green speed", source.getRGBSpeed()[1], 0, 1, 0.01, (value) =>
      source.setRGBSpeed(1, value)
    ),
    range("Blue speed", source.getRGBSpeed()[2], 0, 1, 0.01, (value) =>
      source.setRGBSpeed(2, value)
    ),

    range("Red phase", source.getRGBPhases()[0], 0, 1, 0.01, (value) =>
      source.setRGBPhase(0, value)
    ),
    range("Green phase", source.getRGBPhases()[1], 0, 1, 0.01, (value) =>
      source.setRGBPhase(1, value)
    ),
    range("Blue phase", source.getRGBPhases()[2], 0, 1, 0.01, (value) =>
      source.setRGBPhase(2, value)
    ),

    select("Function", source.getFunction(), source.getFunctions(), (value) =>
      source.setFunction(value)
    ),

    range("Opacity", source.getOpacity(), 0, 1, 0.01, (value) =>
      source.setOpacity(value)
    ),
    // new SelectOption(
    //   'Source blend function',
    //   source.getSourceBlendFunction(),
    //   source.getBlendFunctions(),
    //   value => source.setSourceBlendFunction(value)),
    // new SelectOption(
    //   'Destination blend function',
    //   source.getDestinationBlendFunction(),
    //   source.getBlendFunctions(),
    //   value => source.setDestinationBlendFunction(value)),
    bool("Blend", source.getBlend(), (value) => source.setBlend(value)),
    bool("Smooth", source.getSmooth(), (value) => source.setSmooth(value)),
  ];
};
