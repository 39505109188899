import { Vector2 } from "src/core/math/Vector2";
import { ShapeType } from "../IShape";
import { Rectangle } from "src/core/math/Rectangle";
import { BaseShape } from "./BaseShape";

export class Circle extends BaseShape {
  center: Vector2;
  radius: number;
  _rectangle: Rectangle;

  constructor(center: Vector2, radius: number) {
    super(ShapeType.Circle);
    this.center = center;
    this.radius = radius;
    this._rectangle = new Rectangle(new Vector2(), new Vector2());
    this.updateRect();
  }
  get rectangle(): Rectangle {
    return this._rectangle;
  }
  intersectsRectangle(rectangle: Rectangle): boolean {
    // Closest point on rectangle
    let cpr = this.center.min(rectangle.max).max(rectangle.min);
    return cpr.distance(this.center) <= this.radius;
  }
  move(change: Vector2) {
    this.center.selfAdd(change);
    this.updateRect();
  }

  private updateRect() {
    this._rectangle.min.x = this.center.x - this.radius;
    this._rectangle.max.x = this.center.x + this.radius;
    this._rectangle.min.y = this.center.y - this.radius;
    this._rectangle.max.y = this.center.y + this.radius;
  }
}
