import { isEmpty } from "lodash";

type Props = {
  logs: string[];
};

export const ShaderLogs = ({ logs }: Props) => {
  if (isEmpty(logs)) {
    return null;
  }
  return (
    <>
      <span>Logs:</span>
      <ul>
        {logs.map((e, index) => (
          <li key={index}>{e}</li>
        ))}
      </ul>
    </>
  );
};
