
export enum PieceSides {
    None = 0,
    Left = 1,
    Right = 2,
    LeftRight = 3,
    Top = 4,
    LeftTop = 5,
    RightTop = 6,
    LeftRightTop = 7,
    Bottom = 8,
    LeftBottom = 9,
    RightBottom = 10,
    LeftRightBottom = 11,
    LeftRightTopBottom = 15
}
