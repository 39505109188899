import { Vector2 } from "../../core/math/Vector2";
import { Vector3 } from "../../core/math/Vector3";
import { Camera } from "../../core/rendering/Camera";
import { BufferedMesh } from "../../core/rendering/webgl/BufferedMesh";
import { BaseProgram } from "../../core/rendering/webgl/programs/BaseProgram";

export class Program extends BaseProgram {
  constructor(
    gl: WebGLRenderingContext,
    vertexShader: string,
    fragmentShader: string
  ) {
    super(gl, vertexShader, fragmentShader);

    this.createVertexAttribs("aPosition");
  }

  setSize(size: Vector2) {
    this.uniforms.setFloat2("uSize", size);
  }
  setOpacity(value: number) {
    this.uniforms.setFloat("uOpacity", value);
  }
  setSmooth(value: boolean) {
    this.uniforms.setInt("uSmooth", value ? 1 : 0);
  }
  setTime(values: number[]) {
    this.uniforms.setFloat3("uTime", Vector3.fromArray(values));
  }

  renderVertices(
    vBuffer: WebGLBuffer,
    iBuffer: WebGLBuffer,
    indexCount: number
  ) {
    this.beforeRender();

    this._gl.bindBuffer(this._gl.ARRAY_BUFFER, vBuffer);
    this._gl.vertexAttribPointer(
      this._attribs["aPosition"],
      2,
      this._gl.FLOAT,
      false,
      0,
      0
    );
    this._gl.bindBuffer(this._gl.ELEMENT_ARRAY_BUFFER, iBuffer);
    this._gl.drawElements(
      this._gl.TRIANGLES,
      indexCount,
      this._gl.UNSIGNED_SHORT,
      0
    );

    this.afterRender();
  }
  render(
    items: { [id: string]: BufferedMesh },
    camera: Camera,
    transparent?: boolean
  ) {
    throw new Error("Method not implemented.");
  }
}
