import * as React from 'react'

export type InitFunction = (canvas: HTMLCanvasElement) => DisposeFunction
export type DisposeFunction = () => void
export interface WebGPUCanvasProps extends React.ComponentProps<"canvas"> {
  initFunction: InitFunction
}
export const WebGPUCanvas = ({
  initFunction,
  ...canvasProps
}: WebGPUCanvasProps) => {
  const canvasRef =
    React.useRef<HTMLCanvasElement>() as React.MutableRefObject<HTMLCanvasElement | null>

  React.useEffect(() => initFunction(canvasRef.current!), [initFunction])

  return <canvas {...canvasProps} ref={canvasRef} />
}
