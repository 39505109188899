import { Column } from "./Column";
import { Result } from "./Result";
import { Table } from "./Table";

export class Processor {
  run(inputText: string, hideEmpty: boolean): Result {
    let lines = inputText
      .split("\n")
      .map((l) => l.split("\t").map((v) => v.trim()));

    if (lines.length < 2) {
      return {
        output: "",
        header: [],
        rows: [],
      };
    }

    const table = new Table();
    for (let name of lines[0]) {
      table.columns.push(new Column(name));
    }

    for (let l = 1; l < lines.length; l++) {
      table.addline(lines[l]);
    }

    const output = table.getOutput(!hideEmpty);
    let data = output.split("\n").map((l) => l.split("\t"));
    const headers = data[0];
    const rows = data.slice(1);
    return {
      output,
      header: headers,
      rows,
    };
  }
}
