import { UniformTypes } from "src/core/rendering/webgl/Uniforms";

export interface Uniform {
  name: string;
  type: UniformTypes;
  description: string;
}

export const uniforms = {
  resolution: {
    name: "iResolution",
    type: UniformTypes.float3,
    description:
      "The viewport resolution (z is pixel aspect ratio, usually 1.0)",
  } as Uniform,
  time: {
    name: "iTime",
    type: UniformTypes.float,
    description: "Current time in seconds",
  } as Uniform,
  timeDelta: {
    name: "iTimeDelta",
    type: UniformTypes.float,
    description: "Time it takes to render a frame, in seconds",
  } as Uniform,
  frame: {
    name: "iFrame",
    type: UniformTypes.int,
    description: "Current frame",
  } as Uniform,
  mouse: {
    name: "iMouse",
    type: UniformTypes.float4,
    description: "xy = current pixel coords (if LMB is down). zw = click pixel",
  } as Uniform,
};
