import { GUI } from 'dat.gui'
import { Key } from 'src/core/Key'
import { Keyboard } from 'src/core/Keyboard'
import { Colors } from './Colors'
import { isNil } from 'lodash'

const defaultOptions = {
  particles: 2000,
  colorQuantity: 5,
  particleSize: 0.02,
  speed: 1,
  force: 0.15,
  repulsionForce: 0.2,
  dampening: 0.36,
  rmin: 0.01,
  rmax: 0.3,
  updatesPerFrame: 1,
  followAParticle: false,
  autoClear: true,
  stats: false,

  // colors
  0: '#ffd700',
  1: '#ff4500',
  2: '#2a5ee2',
  3: '#32cd32',
  4: '#00bfff',
  5: '#ff69b4',
  6: '#ff1493',
  7: '#00ced1',
  8: '#ff6347',
  9: '#9400d3',
  10: '#ffa500',
  11: '#adff2f',
  12: '#9370db',
  13: '#ffc0cb',
  14: '#00ff00',
  15: '#8b008b',

  randomizeForces: () => {},
  restoreDefaults: () => {},
}

export type Options = typeof defaultOptions

export class Configurator {
  private readonly _keyboard = new Keyboard()
  private _isOpen = true
  private _gui: GUI
  private _colors = new Colors()
  onStartOptionsChange: () => void
  randomizeForces: () => void

  options = {
    ...defaultOptions,
    randomizeForces: () => {
      if (!isNil(this.randomizeForces)) {
        this.randomizeForces()
      }
    },
    restoreDefaults: () => {
      for (const key in defaultOptions) {
        const value = defaultOptions[key]
        if (typeof value !== 'function') {
          this.options[key] = value
        }
      }
      this._gui.updateDisplay()
    },
  }

  constructor() {
    this._gui = new GUI({ width: 300 })
    this._gui.useLocalStorage = true
    this._gui.remember(this.options)
    this.initialize()

    this._keyboard.onKeyPress.add((key) => {
      switch (key) {
        case Key.h:
          this._isOpen = !this._isOpen
          if (this._isOpen) {
            this._gui.open()
            this._gui.updateDisplay()
          } else {
            this.options.stats = false
            this._gui.close()
          }
          break
        case Key.r:
          this.randomizeForces()
          break
      }
    })
  }

  initialize() {
    const advanced = this._gui.addFolder('advanced')
    advanced.add(this.options, 'speed').min(0).max(15).step(0.01)
    advanced
      .add(this.options, 'rmin')
      .min(0.001)
      .max(0.1)
      .step(0.001)
      .name('repulsion radius')
    advanced
      .add(this.options, 'rmax')
      .min(0)
      .max(1)
      .step(0.001)
      .name('force radius')
    advanced
      .add(this.options, 'updatesPerFrame')
      .min(1)
      .max(10)
      .step(1)
      .name('updates per frame')
    advanced.add(this.options, 'stats')

    this._colors.setGui(this._gui, this.options)
    this._colors.setQuantity(this.options.colorQuantity)

    const initialState = this._gui.addFolder('initial state')
    initialState.open()

    initialState
      .add(this.options, 'particles')
      .min(2)
      .max(10000)
      .step(1)
      .onChange(this._onStartOptionsChangeHandler)
    initialState
      .add(this.options, 'colorQuantity', 2, Colors.MAX, 1)
      .onChange((q) => {
        this._colors.setQuantity(q)
        this._onStartOptionsChangeHandler()
      })

    this._gui
      .add(this.options, 'particleSize')
      .min(0.002)
      .max(0.05)
      .step(0.001)
      .name('particle size')
    this._gui.add(this.options, 'force').min(0).max(1).step(0.0001)
    this._gui
      .add(this.options, 'repulsionForce')
      .min(0)
      .max(1)
      .step(0.0001)
      .name('close repulsion force')
    this._gui.add(this.options, 'dampening').min(0).max(1).step(0.001)
    this._gui.add(this.options, 'followAParticle').name('follow a particle')
    this._gui.add(this.options, 'autoClear').name('auto clear')
    this._gui.add(this.options, 'randomizeForces').name('randomize forces')
    this._gui.add(this.options, 'restoreDefaults').name('restore defaults')
  }

  dispose() {
    this._gui.destroy()
    this._keyboard.dispose()
  }

  private _onStartOptionsChangeHandler = () => {
    this._gui.save()
    this.onStartOptionsChange()
  }
}
