import React, { useEffect } from "react";
import { createRef } from "react";
import { Tetris } from "./Tetris";

export const TetrisPage = () => {
  const canvasRef = createRef<HTMLCanvasElement>();

  useEffect(() => {
    const tetris = new Tetris(canvasRef.current!);
    return () => tetris.dispose();
  }, [canvasRef]);

  return (
    <div>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};
