import { ThreeApp } from 'src/core/three/ThreeApp'
import { CameraAutoSpin } from './CameraAutoSpin'
import { Galaxy } from './Galaxy'
import { GalaxyConfigurator } from './GalaxyOptions'
import * as THREE from 'three'

export class GalaxyGenerator extends ThreeApp {
  private _spinner: CameraAutoSpin
  private _galaxy?: Galaxy
  private _configurator: GalaxyConfigurator

  initialize(canvas: HTMLCanvasElement) {
    super.initialize(canvas)
    this._camera.position.set(0, -4, 4)
    this._camera.lookAt(new THREE.Vector3(0, -1, 0))
    this._spinner = new CameraAutoSpin(this._camera.camera as THREE.PerspectiveCamera)
    this._configurator = new GalaxyConfigurator()
    this._configurator.onChange = this.optionsChangeHandler
    this.addDisposable(this._configurator)
    this.createGalaxy()
  }

  dispose() {
    super.dispose()
    this._galaxy?.dispose()
  }

  private optionsChangeHandler = () => {
    this.createGalaxy()
  }

  private createGalaxy() {
    this._galaxy?.dispose()
    this._galaxy = new Galaxy(this._configurator.options, this._scene)
  }

  protected beforeDraw(elapsedSeconds: number): void {
    if (this._configurator.options.spin) {
      this._spinner.spin(elapsedSeconds)
    }
  }
}
