import { BindGroup } from './BindGroup'
import { IBindGroup } from './IBindGroup'

export class BindGroupArray implements IBindGroup {
  private readonly _bindGroups: BindGroup[]
  private _activeIndex = 0

  constructor(bindGroups: BindGroup[]) {
    this._bindGroups = bindGroups
  }

  set activeIndex(value: number) {
    this._activeIndex = value
  }

  get activeIndex(): number {
    return this._activeIndex
  }

  getLayout(device: GPUDevice, label?: string): GPUBindGroupLayout {
    return this._bindGroups[0].getLayout(device, label)
  }
  initialize(device: GPUDevice): void {
    this._bindGroups.forEach(g => g.initialize(device))
  }
  bindTo(passEncoder: GPURenderPassEncoder | GPUComputePassEncoder, index?: number) {
    this._bindGroups[this._activeIndex].bindTo(passEncoder, index)
  }
  dispose(): void {
    this._bindGroups.forEach(g => g.dispose())
  }
}
