import { Option } from "./Option";
import { OptionData } from "./OptionData";

import style from "./OptionList.module.css";

type Props = {
  options: OptionData[];
};

export const OptionList = ({ options }: Props) => {

  return (
    <ul className={style.list}>
      {options.map((option) => (
        <li key={option.id}>
          <span>{option.name}</span>
          <Option data={option} />
        </li>
      ))}
    </ul>
  );
};
