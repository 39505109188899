import {
  AdditiveBlending,
  BufferGeometry,
  Color,
  Float32BufferAttribute,
  Points,
  Scene,
  ShaderMaterial,
} from 'three'
import { GalaxyOptions } from './GalaxyOptions'

import vertexShader from './vertex.glsl'
import fragmentShader from './fragment.glsl'

export class Galaxy {
  _geometry: BufferGeometry
  _material: ShaderMaterial
  _points: Points
  _scene: Scene

  constructor(options: GalaxyOptions, scene: Scene) {
    const positions = new Float32Array(options.stars * 3)
    const sizeArray = new Float32Array(options.stars)
    const startHeightArray = new Float32Array(options.stars)

    for (let star = 0; star < options.stars; star++) {
      sizeArray[star] = Math.random()

      const startHeight = Math.random()
      startHeightArray[star] = startHeight

      const angle = Math.random() * Math.PI * 2

      const radius0to1 = Math.random()
      const radius = radius0to1 * options.radius

      const height = Math.random() * options.height

      const index = star * 3
      positions[index] = angle
      positions[index + 1] = radius
      positions[index + 2] = height
    }

    this._geometry = new BufferGeometry()
    this._geometry.setAttribute(
      'position',
      new Float32BufferAttribute(positions, 3)
    )
    this._geometry.setAttribute(
      'aSize',
      new Float32BufferAttribute(sizeArray, 1)
    )
    this._geometry.setAttribute(
      'aStartHeight',
      new Float32BufferAttribute(startHeightArray, 1)
    )
    this._material = new ShaderMaterial({
      //vertexColors: true,
      transparent: true,
      depthTest: false,
      blending: AdditiveBlending,
      vertexShader,
      fragmentShader,
      uniforms: {
        uTime: { value: 0 },
        uStarSizeMin: { value: 1 },
        uStarSizeMax: { value: 1 },
        uMinColor: { value: new Color(options.color1) },
        uMaxColor: { value: new Color(options.color2) },
      },
    })
    this._points = new Points(this._geometry, this._material)

    scene.add(this._points)
    this._scene = scene
  }
  setTime(value: number) {
    this._material.uniforms.uTime.value = value
  }
  setOptions(options: GalaxyOptions) {
    this._material.uniforms.uStarSizeMin.value = options.starSizeMin
    this._material.uniforms.uStarSizeMax.value = options.starSizeMax
    this._material.uniforms.uMinColor.value.set(options.color1)
    this._material.uniforms.uMaxColor.value.set(options.color2)
  }
  dispose() {
    this._geometry.dispose()
    this._material.dispose()
    this._scene.remove(this._points)
  }
}
