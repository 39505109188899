import { IBody } from './IBody';
import { Vector2 } from '../math/Vector2';
import { IShape } from './collision/IShape';
import { Collision } from './collision/Collision';
import { Scene } from './collision/Scene';

export class Integrator {
    private _bodies: IBody[];
    private _scene: Scene;

    constructor() {
        this._bodies = [];
        this._scene = new Scene();
    }

    move(body: IBody, change: Vector2) {
        if (body.shape) {
            let collision: Collision;
            let count = 0;
            do {
                collision = this._scene.tryMove(body.shape, change);
                change = collision.getChange(change);
                count++;
            } while (collision.exists && count < 10);

            body.shape.move(change);
        }
        body.position.selfAdd(change);
    }
    addBody(b: IBody) {
        this._bodies.push(b);
        if (b.shape)
            this._scene.addShape(b.shape);
    }
    addShape(s: IShape) {
        this._scene.addShape(s);
    }
}