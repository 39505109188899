import { IEffect } from './IEffect';
import { Vector2 } from 'src/core/math/Vector2';
import { Color } from 'src/core/Color';

export class XEffect implements IEffect {
    center: Vector2;
    maxDist: number;
    centerIndex: number;
    width: number;
    height: number;
    centerX: number;
    centerY: number;
    color: Color;
    nextColor: Color;

    constructor(public context: CanvasRenderingContext2D) {
        this.width = context.canvas.width;
        this.height = context.canvas.height;

        this.center = new Vector2(this.width / 2, this.height / 2);
        this.maxDist = Math.max(Math.ceil(this.center.x), Math.ceil(this.center.y));

        this.centerX = ~~this.center.x;
        this.centerY = ~~this.center.y;

        this.nextColor = Color.random;
        this.color = Color.random;
    }

    getName(): string {
        return "X";
    }

    update() {
        this.color.r += this.color.r > this.nextColor.r ? -1 : 1;
        this.color.g += this.color.g > this.nextColor.g ? -1 : 1;
        this.color.b += this.color.b > this.nextColor.b ? -1 : 1;

        if (this.color.r === this.nextColor.r)
            this.nextColor.r = Math.round(Math.random() * 255);
        if (this.color.g === this.nextColor.g)
            this.nextColor.g = Math.round(Math.random() * 255);
        if (this.color.b === this.nextColor.b)
            this.nextColor.b = Math.round(Math.random() * 255);
    }

    private getIndex(x: number, y: number) {
        x += this.centerX;
        y += this.centerY;
        if (x < 0 || x >= this.width || y < 0 || y >= this.height)
            return -1;

        return (x + y * this.width) * 4;
    }

    private changePixel(data: Uint8ClampedArray, x: number, y: number) {
        //let dir = new Vector2(x, y).selfNormalize().selfNegate();

        let index = this.getIndex(x, y);
        if (index < 0)
            return;

        if (x === 0 && y === 0) {
            data[index] = this.color.r;
            data[index + 1] = this.color.g;
            data[index + 2] = this.color.b;
            return;
        }

        let sourceX = x > 0 ? x - 1 : (x < 0 ? x + 1 : x);
        let sourceY = y > 0 ? y - 1 : (y < 0 ? y + 1 : y);
        let sourceIndex = this.getIndex(sourceX, sourceY);
        if (sourceIndex < 0)
            return;

        data[index] = data[sourceIndex] + Math.round(Math.random() * 2 - 1);
        data[index + 1] = data[sourceIndex + 1] + Math.round(Math.random() * 2 - 1);
        data[index + 2] = data[sourceIndex + 2] + Math.round(Math.random() * 2 - 1);
    }

    draw() {

        let imageData = this.context.getImageData(0, 0, this.width, this.height);
        let data = imageData.data;
        for (let i = 0; i < this.maxDist; i++) {
            for (let x = -i; x <= i; x++) {
                this.changePixel(data, x, -i);
                this.changePixel(data, x, i);
                if (x === i || x === -i) {
                    for (let y = -i + 1; y < i; y++) {
                        this.changePixel(data, x, y);
                    }
                }
            }
        }

        //Blur
        let factor = 0.6;
        let invFactor = 1 - factor;
        for (let i = 0; i < this.width; i++) {
            for (let j = 0; j < this.height - 1; j++) {

                let index1 = (i + (j - 1) * this.width) * 4;
                let index2 = (i + j * this.width) * 4;
                if (index1 < 0) {
                    data[index2] = data[index2] * factor;
                    data[index2 + 1] = data[index2 + 1] * factor;
                    data[index2 + 2] = data[index2 + 2] * factor;
                }
                else {
                    data[index2] = data[index1] * invFactor + data[index2] * factor;
                    data[index2 + 1] = data[index1 + 1] * invFactor + data[index2 + 1] * factor;
                    data[index2 + 2] = data[index1 + 2] * invFactor + data[index2 + 2] * factor;
                }
            }
        }

        this.context.putImageData(imageData, 0, 0);
    }
}