// TODO: review
/*
  It's not clear how the source can be. What it's known:
  - Can be multiple lines, with one information/line
  - It can start with a type, like "ERROR"
  - It can reffer to a line and a token, like "0:6: ','".
    Not clear what the first number is. Second number is the line.
  For now it's safer to just use the 'source' property.
*/
export class ShaderLogInfo {
  private readonly _line?: number;
  private readonly _message?: string;
  private readonly _source: string;

  constructor(source: string) {
    this._source = source;
    const parts = source.split(":");
    if (parts.length > 2) {
      this._line = Number(parts[2]);
      if (parts.length > 3) {
        this._message = parts.slice(3).join(":").trim();
      }
    }
  }

  get line(): number | undefined {
    return this._line;
  }

  get message(): string | undefined {
    return this._message;
  }

  get source(): string {
    return this._source;
  }

  static parseArray(logs: string): ShaderLogInfo[] {
    return logs
      .split("\n")
      .filter((l) => l !== "\0")
      .map((l) => new ShaderLogInfo(l));
  }
}
