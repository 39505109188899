import { Vector2 } from './math/Vector2';
import { Color } from './Color';

export class CanvasButton {
    position = new Vector2(0, 0);
    size = new Vector2(75, 25);
    color = Color.white;
    highLightedColor = Color.white.multiplyRGBScalar(0.8);
    borderColor = Color.white;
    text: string;
    textColor: Color;
    textFont: string;
    private _highlighted = false;

    private isOver(point: Vector2): boolean {
        if (point.x < this.position.x || point.y < this.position.y)
            return false;
        if (point.x > this.position.x + this.size.x || point.y > this.position.y + this.size.y)
            return false;
        return true;
    }
    pointerOver(point: Vector2) {
        this._highlighted = this.isOver(point);
    }
    click(point: Vector2): boolean {
        return this.isOver(point);
    }
    draw(context: CanvasRenderingContext2D) {
        context.save();
        context.translate(this.position.x, this.position.y);

        context.fillStyle = this._highlighted ? this.highLightedColor.toStringRGBA() : this.color.toStringRGBA();
        context.fillRect(1, 1, this.size.x - 2, this.size.y - 2);

        context.beginPath();
        context.moveTo(0, this.size.y);
        context.lineTo(0, 0);
        context.lineTo(this.size.x, 0);
        context.strokeStyle = this.borderColor.toStringRGBA();
        context.stroke();
        context.beginPath();
        context.moveTo(this.size.x, 0);
        context.lineTo(this.size.x, this.size.y);
        context.lineTo(0, this.size.y);
        context.strokeStyle = this.borderColor.addRGBScalar(-150).toStringRGBA();
        context.stroke();

        if (this.text) {
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.font = this.textFont;
            context.fillStyle = this.textColor.toStringRGBA();
            context.fillText(this.text, this.size.x / 2, this.size.y / 2);
            //context.strokeText(this.text, this._size.x / 2, this._size.y / 2);
        }

        context.restore();
    }
}