import { Viewport } from "src/core/Viewport";
import { Renderer2D } from "src/core/rendering/webgl2/Renderer2D";
import { Camera } from "src/core/rendering/Camera";
import { Color } from "src/core/Color";
import { Vector3 } from "src/core/math/Vector3";
import { Vector2 } from "src/core/math/Vector2";
import { Map } from "./map/Map";
import { MapGenerator } from "./map/MapGenerator";
import { Parameters } from "./map/Parameters";
import { Keyboard } from "src/core/Keyboard";
import { Key } from "src/core/Key";
import { Player } from "./characters/Player";
import { CameraChaser2D } from "src/core/rendering/CameraChaser2D";
import { Integrator } from "src/core/physics2D/Integrator";
import { RenderLoop } from "src/core/rendering/RenderLoop";

export class Reflection {
  private _renderer: Renderer2D;
  private _camera: Camera;
  private _cameraChaser: CameraChaser2D;
  private _viewport: Viewport;
  private _keyboard: Keyboard;
  private _map: Map;
  private _player: Player;
  private _integrator: Integrator;
  private _loop: RenderLoop;

  constructor(canvas: HTMLCanvasElement) {
    this._renderer = new Renderer2D(canvas);
    this._renderer.setClearColor(new Color(0, 0, 0));

    this._camera = new Camera();
    this._camera.setValues(
      new Vector3(0, 0, 100),
      new Vector3(0, 0, 0),
      new Vector3(0, 1, 0)
    );
    this._camera.ortographic = true;
    this._cameraChaser = new CameraChaser2D(this._camera, 5);

    this._viewport = new Viewport(canvas);
    this._viewport.setCamera(this._camera);
    this._viewport.setRenderer(this._renderer);
    this._viewport.fillWindow();

    let generator = new MapGenerator();
    this._map = generator.generate(new Parameters());
    this._map.rendererUpdade(this._renderer);

    this._player = new Player();
    this._player.rendererUpdade(this._renderer);

    this._integrator = new Integrator();
    this._integrator.addBody(this._player);
    for (let w of this._map.getAllWalls()) this._integrator.addShape(w.shape);

    this._keyboard = new Keyboard();
    this._loop = new RenderLoop();
    this._loop.start();

    this.registerEvents();
  }

  dispose() {
    this._viewport.dispose();
    this._keyboard.dispose();
    this._loop.dispose();
  }
  private registerEvents() {
    this._loop.onExecute.add((elapsed) => this.update(elapsed));
  }
  private update(elapsed: number) {
    let playerSpeed = 3;
    let playerMovement = new Vector2(0, 0);
    if (this._keyboard.isKeyDown(Key.w)) {
      playerMovement.y = 1;
    }
    if (this._keyboard.isKeyDown(Key.s)) {
      playerMovement.y = -1;
    }
    if (this._keyboard.isKeyDown(Key.d)) {
      playerMovement.x = 1;
    }
    if (this._keyboard.isKeyDown(Key.a)) {
      playerMovement.x = -1;
    }

    if (!playerMovement.isZero()) {
      this._integrator.move(
        this._player,
        playerMovement.normalize().multiplyScalar(playerSpeed)
      );
      this._player.rendererUpdade(this._renderer);
    }

    this._cameraChaser.setTargetPosition(this._player.position);
    this._cameraChaser.update(elapsed);

    this._renderer.render(this._camera);
  }
}
