import { TableComponent } from "./TableComponent";
import styles from "./MoniPage.module.css";
import { Result } from "./Result";

export const ResultComponent = ({ result }: { result: Result }) => {
  if (result == null) {
    return null;
  }

  return (
    <>
      <p>Resultado</p>
      <TableComponent header={result.header} rows={result.rows} />
      <br />
      <textarea className={styles.text} readOnly value={result.output} />
    </>
  );
};
