import { ParticleLifeWebGPU } from './ParticleLifeWebGPU'
import { PageInformation } from 'src/components/PageInformation'
import { WebGPUCanvas } from 'src/core/webGPU/WebGPUCanvas'

export const ParticleLifeWebGPUPage = () => {
  const initFunction = (canvas: HTMLCanvasElement) => {
    const program = new ParticleLifeWebGPU()
    const initPromise = program.initialize(canvas).then(() => {
      program.start()
    })
    return () => {
      initPromise.then(() => {
        program.dispose()
      })
    }
  }

  const informations = [
    'P to pause, L to execute a single frame',
    'R to randomize interaction forces',
    'H to hide all UI',
  ]

  return (
    <>
      <WebGPUCanvas initFunction={initFunction} />
      <PageInformation lines={informations}></PageInformation>
    </>
  )
}
