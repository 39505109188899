import { Column } from "./Column";

export class Table {
  columns: Column[] = [];
  private lineCount = 0;

  addline(values: string[]) {
    if (values.every((v) => !v)) return;

    for (let i = 0; i < this.columns.length; i++)
      this.columns[i].addValue(values[i]);

    this.lineCount++;
  }
  getOutput(empty: boolean): string {
    let result = this.createResult();
    let lines = this.getLines();
    for (let l of lines) result[l]++;

    if (!empty) {
      for (let k in result) if (!result[k]) delete result[k];
    }

    let sum = 0;
    for (let k in result) sum += result[k];

    return (
      this.columns.map((c) => c.name).join("\t") +
      "\tTotal\n" +
      Object.keys(result)
        .map((k) => k + "\t" + result[k])
        .join("\n") +
      "\n" +
      this.columns.map(() => "\t").join("") +
      sum
    );
  }

  private createResult(
    columnIndex: number = 0,
    input: { [key: string]: number } = {}
  ): { [key: string]: number } {
    if (columnIndex === this.columns.length) return input;

    let result = {};
    if (Object.keys(input).length === 0) {
      for (let j of this.columns[columnIndex].distinct) {
        result[j] = 0;
      }
    } else {
      for (let i in input) {
        for (let j of this.columns[columnIndex].distinct) {
          result[i + "\t" + j] = 0;
        }
      }
    }

    return this.createResult(columnIndex + 1, result);
  }
  private getLines(): string[] {
    let result = [];
    for (let l = 0; l < this.lineCount; l++) {
      let line = this.columns.map((c) => c.values[l]).join("\t");
      result.push(line);
    }
    return result;
  }
}
