import { ReactNode, useState } from "react";

type Props = {
  className?: string;
  children: ReactNode;
};

export const ConfigurationsSection = ({ className, children }: Props) => {
  const [hidden, setHidden] = useState(true);

  const onHideHandler = () => {
    setHidden(true);
  };
  const onShowHandler = () => {
    setHidden(false);
  };

  const renderContent = () => {
    if (hidden) {
      return <button onClick={onShowHandler}>Config</button>;
    } else {
      return (
        <>
          {children}
          <button onClick={onHideHandler}>Hide</button>
        </>
      );
    }
  };

  return <div className={className}>{renderContent()}</div>;
};
