import { Vector2 } from 'src/core/math/Vector2';
import { Collision } from '../Collision';
import { IShapeCollider } from '../IShapeCollider';
import { Circle } from '../shapes/Circle';
import { Line } from '../shapes/Line';

export class CircleLine implements IShapeCollider {
    tryMove(circle: Circle, change: Vector2, line: Line): Collision {
        let result = new Collision();

        let normal = line.normal;
        let lineNormalDot = line.normalDot;
        let sourcePtDot = normal.dot(circle.center);

        // Normal should be pointing to the side of the original circle center
        if (sourcePtDot < lineNormalDot) {
            sourcePtDot = -sourcePtDot;
            normal = normal.negate();
            lineNormalDot = -lineNormalDot;
        }

        let dist = lineNormalDot - sourcePtDot;

        // First checking if the circle crosses in the center of the line
        let changeDot = normal.dot(change);
        let penetration = dist - changeDot + circle.radius;
        if (penetration > 0) {
            let distPerc = 1 - Math.abs(penetration / changeDot);
            let changeToCollision = change.multiplyScalar(distPerc);
            let collisionCenter = circle.center.add(changeToCollision);
            // Only has a collision if the circle center projection is over the line
            if (line.projectionInside(collisionCenter)) {
                result.setValues(changeToCollision.length(), normal);
                return result;
            }
        }

        // Now checking collision with the line points
        let changeLength = change.length();
        let changeDirection = change.multiplyScalar(1 / changeLength);
        let changeNormal = changeDirection.rotateZ(Math.PI / 2);
        let centerNormalDot = changeNormal.dot(circle.center);
        for (let point of line.points) {
            let pointNormalDot = changeNormal.dot(point);
            let pointProjDist = pointNormalDot - centerNormalDot;

            // Projection outside the bounds of the circle
            if (Math.abs(pointProjDist) > circle.radius)
                continue;

            let sin = pointProjDist / circle.radius;
            let angle = Math.asin(sin);
            let cos = Math.cos(angle);
            let collisionDistance = changeDirection.dot(point) - changeDirection.dot(circle.center) - cos * circle.radius;

            // The collision is beyond the change length or is on the opposite direction
            if (collisionDistance > changeLength || collisionDistance < 0)
                continue;

            result.setValues(collisionDistance, changeDirection.rotateZ(Math.PI + angle));
        }

        return result;
    }

}