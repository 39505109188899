import { Key } from "../Key";
import { Keyboard } from "../Keyboard";
import { RenderLoop } from "./RenderLoop";

export class RenderLoopKeyboardController {
  private readonly _keyboard = new Keyboard()
  constructor(loop: RenderLoop) {
    this._keyboard.onKeyPress.add(key => {
      if (key === Key.p) {
        loop.switchPaused()
      }
      else if (key === Key.l && loop.paused) {
        loop.step()
      }
    })
  }

  dispose() {
    this._keyboard.dispose()
  }
}