import { IEffect } from './IEffect';
import { Vector2 } from 'src/core/math/Vector2';
import { Color } from 'src/core/Color';

export class CurvesEffect implements IEffect {
    color: Color = new Color(255, 0, 0);
    c = 0;
    v1 = 0;
    v2 = 0;
    sign = false;
    points: Array<Vector2>;

    constructor(public context: CanvasRenderingContext2D) {
        this.points = new Array<Vector2>(context.canvas.width);
        for (let i = 0; i < this.points.length; i++) {
            this.points[i] = new Vector2(i, 0);
        }
    }

    getName(): string {
        return "Curves";
    }

    update() {
        let waveLength = (2.0 * Math.PI);
        //let hDisp = Math.random() * canvas.width;
        //let frequence = 2 * waveLength * (Math.random() * 0.1 + 0.9);

        if (this.sign)
            this.v1 += 0.1;
        else
            this.v1 -= 0.1;
        if (this.v1 > 5 || this.v1 < 0) {
            this.sign = !this.sign;
        }

        let hDisp = 0 * this.context.canvas.width;
        let frequence = 2 * waveLength * this.v1;

        let half = this.context.canvas.height * 0.31;
        let height = 0.3 * this.context.canvas.height;
        for (let i = 0; i < this.points.length; i++) {
            this.points[i].y = half + height * Math.sin((hDisp + i) / this.context.canvas.width * frequence);
        }
    }

    changeColor(color: Color, index: number, change: number) {
        if (index === 0)
            color.r += change;
        else if (index === 1)
            color.g += change;
        else if (index === 2)
            color.b += change;
    }

    draw() {
        this.context.beginPath();
        this.context.moveTo(this.points[0].x, this.points[0].y);
        for (let i = 1; i < this.points.length; i++) {
            this.context.lineTo(this.points[i].x, this.points[i].y);
        }
        this.context.lineWidth = 2;

        let c1 = this.c + 1;
        if (this.c > 2)
            this.c = 0;
        else if (c1 > 2)
            c1 = 0;

        this.changeColor(this.color, this.c, -5);
        this.changeColor(this.color, c1, 5);

        if (this.color.getComponent(this.c) === 0)
            this.c++;

        this.context.strokeStyle = this.color.toString();
        this.context.stroke();

        let width = this.context.canvas.width;
        let height = this.context.canvas.height;
        let factor = 0.3;
        let invFactor = 1 - factor;
        let imageData = this.context.getImageData(0, 0, width, height);
        let data = imageData.data;
        for (let i = 0; i < width; i++) {
            for (let j = 0; j < height - 1; j++) {

                let index1 = (i + (j - 1) * width) * 4;
                let index2 = (i + j * width) * 4;
                if (index1 < 0) {
                    data[index2] = data[index2] * factor;
                    data[index2 + 1] = data[index2 + 1] * factor;
                    data[index2 + 2] = data[index2 + 2] * factor;
                }
                else {
                    data[index2] = data[index1] * invFactor + data[index2] * factor;
                    data[index2 + 1] = data[index1 + 1] * invFactor + data[index2 + 1] * factor;
                    data[index2 + 2] = data[index1 + 2] * invFactor + data[index2 + 2] * factor;
                }
            }
        }

        this.context.putImageData(imageData, 0, 0);
    }
}