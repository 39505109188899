import { EventHandler } from "./EventHandler";
import { Key } from "./Key";

/**
 * Class for handling keyboard events.
 */
export class Keyboard {
    private _downKeys: { [key: string]: boolean };
    private _keyValues: { [key: string]: boolean };
    element?: Element;
    onKeyPress = new EventHandler<Key>();
    onCopy = new EventHandler<void>();
    onPaste = new EventHandler<void>();

    /**
     * Creates a Keyboard.
     * @param element Handle key events only when this element is focused. If null, all events will be handled.
     */
    constructor(element?: Element) {
        this.element = element;
        this._downKeys = {};

        this._keyValues = {};
        for (let id in Key)
            this._keyValues[Key[id]] = true;

        document.addEventListener('keydown', this.handleUpDown);
        document.addEventListener('keyup', this.handleUpDown);
        document.addEventListener('keypress', this.handlePress);
    }

    dispose() {
        document.removeEventListener('keydown', this.handleUpDown);
        document.removeEventListener('keyup', this.handleUpDown);
        document.removeEventListener('keypress', this.handlePress);
    }
    // TODO: Use Keys
    isKeyDown(code: Key): boolean {
        return this._downKeys[code] || false;
    }

    private isOnElement(e: KeyboardEvent): boolean {
        // Ignoring key events when target element is not focused
        if (this.element && e.target !== this.element && e.srcElement !== this.element)
            return false;
        return true;
    }
    private getCode(e: KeyboardEvent): string {
        return e.code;
    }
    private handlePress = (e: KeyboardEvent) => {
        if (!this.isOnElement(e))
            return;
        let code = this.getCode(e);
        if (this._keyValues[code])
            this.onKeyPress.call(code as Key);
    }
    private handleUpDown = (e: KeyboardEvent) => {
        if (!this.isOnElement(e))
            return;
        let code = this.getCode(e);

        if (code) {
            let down = e.type === "keydown";
            let press = down && !this._downKeys[code];
            this._downKeys[code] = down;

            if (this.isCtrlDown() && press) {
                if (code === Key.c)
                    this.onCopy.call();
                if (code === Key.v)
                    this.onPaste.call();
            }
        }
    }
    private isCtrlDown(): boolean {
        return this._downKeys[Key.ctrlLeft] || this._downKeys[Key.ctrlRight];
    }

    static readonly keys = {
        backspace: 8,
        ctrl: 17,
        escape: 27,
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        del: 46,
        a: 65,
        c: 67,
        d: 68,
        i: 73,
        p: 80,
        q: 81,
        r: 82,
        s: 83,
        v: 86,
        w: 87,
        meta: 91,
        numPlus: 107,
        numMinus: 109,
        equals: 187,
        lessThan: 188,
        minus: 189,
        greaterThan: 190,
    }

}
