import { IEffect } from "./IEffect";
import { EventHandler } from "src/core/EventHandler";
import { FibonacciEffect } from "./FibonacciEffect";
import { CurvesEffect } from "./CurvesEffect";
import { XEffect } from "./XEffect";
import { RenderLoop } from "src/core/rendering/RenderLoop";
import { sguid } from "src/core/sguid";

export class CanvasEffects {
  private _context: CanvasRenderingContext2D;
  private _currentEffect: number;
  private _effects: Array<IEffect>;
  private _renderLoop: RenderLoop;
  private _id: string;
  onEffectChange = new EventHandler<string>();

  constructor(public canvas: HTMLCanvasElement) {
    this._context = canvas.getContext("2d");
    this._effects = new Array<IEffect>(
      new FibonacciEffect(this._context),
      new CurvesEffect(this._context),
      new XEffect(this._context)
    );
    this._renderLoop = new RenderLoop();
    this._id = sguid();
  }

  initialize() {
    this._context.fillStyle = "black";
    this._context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this._renderLoop.onExecute.add(() => this.update());
    this._renderLoop.start();
    document.addEventListener("keydown", this.keyDown);
    this.changeEffect(0);
  }

  dispose() {
    this._renderLoop.dispose();
    this.onEffectChange.dispose();
    document.removeEventListener("keydown", this.keyDown);
  }

  private update() {
    this._effects[this._currentEffect].update();
    this._effects[this._currentEffect].draw();
  }

  private changeEffect(effectIndex: number) {
    this._currentEffect = effectIndex;
    this.onEffectChange.call(this._effects[this._currentEffect].getName());
  }

  private keyDown = (e: KeyboardEvent) => {
    if (e.keyCode >= 37 && e.keyCode <= 40) e.preventDefault();

    if (e.keyCode === 37 || e.keyCode === 38) {
      console.log(`left`, this._id);
      this.changeEffect(
        this._currentEffect === 0
          ? this._effects.length - 1
          : this._currentEffect - 1
      );
    } else if (e.keyCode === 39 || e.keyCode === 40) {
      console.log(`right`, this._id);
      this.changeEffect(
        this._currentEffect === this._effects.length - 1
          ? 0
          : this._currentEffect + 1
      );
    }
  };
}
