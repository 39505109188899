import { Keyboard } from 'src/core/Keyboard'
import { Key } from 'src/core/Key'
import { Vector2 } from 'src/core/math/Vector2'
import { RenderLoop } from 'src/core/rendering/RenderLoop'
import { RenderTargetPair } from 'src/core/rendering/RenderTargetPair'
import { FullScreenSprite } from 'src/core/rendering/webgl/programs/FullScreenSprite'
import { Viewport } from 'src/core/Viewport'
import { Program } from './Program'

export class ShaderFluid {
  private _gl: WebGLRenderingContext
  private _viewport: Viewport
  private _keyboard: Keyboard
  private _program: Program
  private _rtPair: RenderTargetPair
  private _coloringPass: FullScreenSprite

  private _loop: RenderLoop
  private _startPoint: Vector2

  async initialize(canvas: HTMLCanvasElement) {
    this._viewport = new Viewport(canvas)
    await this.initGL(canvas)
    this._loop = new RenderLoop()
    this._keyboard = new Keyboard()
    this.registerEvents()
    this._viewport.fillWindow()

    this._loop.start()
  }
  dispose() {
    this._rtPair?.dispose()
    this._loop?.dispose()
    this._viewport?.dispose()
    this._keyboard?.dispose()
  }

  private drawTarget() {
    this._rtPair.target.enable()
    this._program.render(this._rtPair.source.texture)
    this._rtPair.target.disable()
  }
  private drawCanvas() {
    this._gl.clear(this._gl.COLOR_BUFFER_BIT | this._gl.DEPTH_BUFFER_BIT)
    this._coloringPass.render(this._rtPair.target.texture)
  }
  private draw(elapsed: number) {
    this._program.setElapsedTime(elapsed)
    this.drawTarget()
    this.drawCanvas()
    this._rtPair.swap()
  }
  private async initGL(canvas: HTMLCanvasElement) {
    const context = canvas.getContext('webgl')
    if (!context) {
      throw new Error('Failed to create webgl context')
    }
    this._gl = context
    this._rtPair = new RenderTargetPair(this._gl, 800, 600)

    let coloringShader = await this.getShaderFromFile('color-fs')
    this._coloringPass = new FullScreenSprite(
      this._gl,
      undefined,
      coloringShader
    )

    await this.createProgram()

    this._gl.clearColor(0, 0, 0, 0)
    this._gl.blendEquation(this._gl.FUNC_ADD)
  }
  private async createProgram() {
    let vsSource = await this.getShaderFromFile('vs')
    let fsSource = await this.getShaderFromFile('fs')

    this._program = new Program(this._gl, vsSource, fsSource)
  }
  private async getShaderFromFile(fileName: string): Promise<string> {
    return await (
      await fetch(
        `shaders/shader-fluid/${fileName}.glsl?cache=${new Date().valueOf()}`
      )
    ).text()
  }
  private registerEvents() {
    this._viewport.onResize.add((s) => {
      this._gl.viewport(0, 0, s.x, s.y)
      this._rtPair.setSize(s.x, s.y)
      this._program.setSize(s)
    })
    this._viewport.onPoiterStart.add((p) => this.setStart(p))
    this._viewport.onPointerMove.add(({ position }) => this.move(position))
    this._loop.onExecute.add((elapsed) => this.draw(elapsed))

    this._keyboard.onKeyPress.add((k) => {
      let dir = this.getDirection(k)
      if (dir) {
        this._program.move(
          this._viewport.size.multiplyScalar(0.5),
          dir.normalize()
        )
        //console.log(this._viewport.size.multiplyScalar(0.5), this.getDirection(k).normalize());
      } else if (k === Key.p) {
        this._loop.switchPaused()
      } else if (k === Key.o) {
        this._loop.step()
      }
    })
  }
  private setStart(point: Vector2) {
    this._startPoint = point
  }
  private move(currentPoint: Vector2) {
    if (this._startPoint) {
      let dir = currentPoint.subtract(this._startPoint).normalize()
      this._program.move(currentPoint, new Vector2(dir.x, -dir.y))
      //console.log(currentPoint);
    }
    this._startPoint = currentPoint
  }
  private getDirection(key: Key): Vector2 | null {
    switch (key) {
      case Key.numpad1:
        return new Vector2(-1, -1)
      case Key.numpad2:
        return new Vector2(0, -1)
      case Key.numpad3:
        return new Vector2(1, -1)
      case Key.numpad4:
        return new Vector2(-1, 0)
      case Key.numpad6:
        return new Vector2(1, 0)
      case Key.numpad7:
        return new Vector2(-1, 1)
      case Key.numpad8:
        return new Vector2(0, 1)
      case Key.numpad9:
        return new Vector2(1, 1)
      default:
        return null
    }
  }
}
