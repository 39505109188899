import { Block } from "./Block";
import { Vector2 } from "../../core/math/Vector2";

export class Grid {
  matrix: Array<Block | null> = [];
  projected: { block: Block; position: Vector2 } | undefined;

  constructor(
    public width: number,
    public height: number,
    public position: Vector2,
    public squareSize: number
  ) {}

  blockAt(x: number, y: number): Block | null {
    return this.matrix[y * this.width + x];
  }

  setBlockAt(x: number, y: number, block: Block | null) {
    this.matrix[y * this.width + x] = block;
  }

  positionExists(position: Vector2): boolean {
    return (
      position.x >= 0 &&
      position.x < this.width &&
      position.y >= 0 &&
      position.y < this.height
    );
  }

  positionIsValid(position: Vector2): boolean {
    return (
      position.x >= 0 && position.x < this.width && position.y < this.height
    );
  }

  collides(block: Block): boolean {
    for (let i = 0; i < block.positions.length; i++) {
      const position = block.position.add(block.positions[i]);
      if (!this.positionIsValid(position)) return true;
      if (this.blockAt(position.x, position.y)) return true;
    }
    return false;
  }

  removeLine(line: number) {
    for (let i = line; i >= 1; i--) {
      for (let j = 0; j < this.width; j++) {
        this.setBlockAt(j, i, this.blockAt(j, i - 1));
      }
    }

    for (let i = 0; i < this.width; i++) {
      this.setBlockAt(0, i, null);
    }
  }

  clear() {
    for (let i = 0; i < this.width; i++) {
      for (let j = 0; j < this.height; j++) {
        this.setBlockAt(i, j, null);
      }
    }
  }

  removeCompletedLines(): number {
    let count = 0;
    for (let i = 0; i < this.height; i++) {
      for (let j = 0; j < this.width; j++) {
        if (!this.blockAt(j, i)) break;

        if (j === this.width - 1) {
          this.removeLine(i);
          count++;
        }
      }
    }

    return count;
  }

  setBlock(block: Block) {
    for (let i = 0; i < block.positions.length; i++) {
      const position = block.position.add(block.positions[i]);
      if (this.positionExists(position))
        this.setBlockAt(position.x, position.y, block);
    }
  }

  removeBlock(block: Block) {
    for (let i = 0; i < block.positions.length; i++) {
      const position = block.position.add(block.positions[i]);
      if (this.positionExists(position))
        this.setBlockAt(position.x, position.y, null);
    }
  }

  moveBlock(block: Block, change: Vector2): boolean {
    this.removeBlock(block);
    block.position = block.position.add(change);

    if (this.collides(block)) {
      block.position = block.position.subtract(change);
      this.setBlock(block);
      return false;
    }

    this.setBlock(block);
    return true;
  }

  rotate(block: Block, side: number) {
    this.removeBlock(block);
    block.rotate(side);

    if (this.collides(block)) {
      block.rotate(-side);
      this.setBlock(block);
      return false;
    }

    this.setBlock(block);
    return true;
  }

  hardDrop(block: Block) {
    const height = this.getDropHeight(block);
    this.moveBlock(block, new Vector2(0, height));
  }

  getDropHeight(block: Block): number {
    let dropHeight = this.height;

    for (let i = 0; i < block.positions.length; i++) {
      const pos = block.position.add(block.positions[i]);

      for (let y = pos.y + 1; y < this.height; y++) {
        const other = this.blockAt(pos.x, y);
        if (other === block) {
          break;
        } else if (other) {
          dropHeight = Math.min(dropHeight, y - pos.y - 1);
        } else if (y === this.height - 1) {
          dropHeight = Math.min(dropHeight, this.height - pos.y - 1);
        }
      }
    }

    return dropHeight;
  }

  createProjection(block: Block) {
    const height = this.getDropHeight(block);
    const position = block.position.add(new Vector2(0, height));
    this.projected = { block, position };
  }

  draw(context: CanvasRenderingContext2D, drawBlocks: boolean) {
    if (drawBlocks) {
      for (let i = 0; i < this.width; i++) {
        for (let j = 0; j < this.height; j++) {
          const block = this.blockAt(i, j);
          if (block) {
            context.beginPath();
            context.rect(
              this.position.x + i * this.squareSize,
              this.position.y + j * this.squareSize,
              this.squareSize,
              this.squareSize
            );
            context.fillStyle = block.color;
            context.fill();

            context.lineWidth = 1;
            context.strokeStyle = "white";
            context.stroke();
          }
        }
      }

      if (this.projected) {
        const { block, position } = this.projected;
        context.save();
        context.globalAlpha = 0.3;
        for (let i = 0; i < block.positions.length; i++) {
          const pos = position.add(block.positions[i]);
          context.fillStyle = block.color;
          context.fillRect(
            this.position.x + pos.x * this.squareSize,
            this.position.y + pos.y * this.squareSize,
            this.squareSize,
            this.squareSize
          );

          context.lineWidth = 1;
          context.strokeStyle = "white";
          context.stroke();
        }
        context.restore();
      }
    }

    context.beginPath();
    context.rect(
      this.position.x,
      this.position.y,
      this.width * this.squareSize,
      this.height * this.squareSize
    );

    context.lineWidth = 1;
    context.strokeStyle = "white";
    context.stroke();
  }
}
