import { Vector2 } from 'src/core/math/Vector2'
import { Color } from '../../Color'

export abstract class Geometry {
  id?: string
  coords?: Vector2[]
  indices?: number[]
  color?: Color
  texture?: string

  abstract getVertices(): Float32Array
  abstract get vertexSize()

  get transparent(): boolean {
    return (this.color?.a ?? 255) < 255
  }
}
