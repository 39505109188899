import { GUI } from 'dat.gui'

export interface GalaxyOptions {
  stars: number
  radius: number
  height: number
  starSizeMin: number
  starSizeMax: number
  speed: number
  color1: string
  color2: string
  autoClear: boolean
}

export class GalaxyConfigurator {
  private _gui: GUI
  onChange: () => void
  options: GalaxyOptions = {
    stars: 34000,
    radius: 1.3,
    height: 0.13,
    starSizeMin: 0.02,
    starSizeMax: 0.06,
    speed: 0.08,
    color1: '#ffd460',
    color2: '#ffffff',
    autoClear: false,
  }

  constructor() {
    this._gui = new GUI({ width: 300 })
    this._gui.useLocalStorage = true
    this._gui.remember(this.options)

    this._gui
      .add(this.options, 'stars')
      .min(10)
      .max(50000)
      .step(10)
      .onChange(this._onChangeHandler)

    this._gui
      .add(this.options, 'radius')
      .min(1)
      .max(20)
      .step(0.1)
      .onChange(this._onChangeHandler)

    this._gui
      .add(this.options, 'height')
      .min(0.01)
      .max(5)
      .step(0.01)
      .onChange(this._onChangeHandler)

    this._gui.add(this.options, 'starSizeMin').min(0.01).max(0.1).step(0.001)

    this._gui.add(this.options, 'starSizeMax').min(0.01).max(0.1).step(0.001)

    this._gui.add(this.options, 'speed').min(0).max(5).step(0.01)

    this._gui.addColor(this.options, 'color1')
    this._gui.addColor(this.options, 'color2')

    this._gui.add(this.options, 'autoClear')
  }
  dispose() {
    this._gui.destroy()
  }

  private _onChangeHandler = () => {
    this._gui.save()
    this.onChange()
  }
}
