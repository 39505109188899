import { RenderTarget } from "./RenderTarget";

export class RenderTargetPair {
    private _source: RenderTarget;
    private _target: RenderTarget;

    constructor(gl: WebGLRenderingContext, width: number, height: number) {
        this._source = new RenderTarget(gl, width, height);
        this._target = new RenderTarget(gl, width, height);
    }

    get source(): RenderTarget {
        return this._source;
    }
    get target(): RenderTarget {
        return this._target;
    }
    setSize(width: number, height: number) {
        this._source.setSize(width, height);
        this._target.setSize(width, height);
    }
    swap() {
        let newSource = this._target;
        this._target = this._source;
        this._source = newSource;
    }
    dispose() {
        this._source.dispose();
        this._target.dispose();
    }
}