import { Vector2 } from 'src/core/math/Vector2';

export class Collision {
    private _exists = false;
    private _distance: number;
    private _normal: Vector2;

    /** True if a collision really happened. */
    get exists(): boolean {
        return this._exists;
    }
    /** Distance the object moved before colliding. */
    get distance(): number {
        return this._distance;
    }
    /** The normal on the collision point. */
    get normal(): Vector2 {
        return this._normal;
    }

    /**
     * Merges two collision objects. If they both exist, keeps the first to happen.
     * @param other The other instance.
     */
    mergeWith(other: Collision) {
        if (!other._exists)
            return;

        if (!this.exists)
            this.setValues(other._distance, other._normal);
        else if (other._distance < this._distance)
            this.setValues(other._distance, other._normal);
    }

    /**
     * Sets the collision values.
     * @param distance Distance from the initial position to the collision.
     * @param normal The normal on the collision point.
     */
    setValues(distance: number, normal: Vector2) {
        this._exists = true;
        this._distance = distance;
        this._normal = normal;
    }

    /**
     * Returns the computed change in position considering the collision.
     * @param change The intended position change before the collision.
     */
    getChange(change: Vector2): Vector2 {
        if (!this._exists)
            return change;

        let changeLength = change.length();
        if (changeLength === 0)
            return change;

        let distanceFraction = this._distance / changeLength;
        let afterCollision = 1 - distanceFraction;
        let result = change.multiplyScalar(distanceFraction);

        // Bias
        result.selfAdd(this._normal.multiplyScalar(0.00001));

        let remainingChange = change.multiplyScalar(afterCollision);
        let remove = -this._normal.dot(remainingChange);
        remainingChange.selfAdd(this._normal.multiplyScalar(remove));
        result.selfAdd(remainingChange);

        return result;
    }
}