import { useEffect, useRef } from 'react'
import { Program } from './Program'
import { PageInformation } from 'src/components/PageInformation'

export const FluidSimulationPage = () => {
  const canvasRef = useRef<HTMLCanvasElement>()

  useEffect(() => {
    const program = new Program()
    const initPromise = program.initialize(canvasRef.current).then(() => {
      program.start()
    })
    return () => {
      initPromise.then(() => {
        program.dispose()
      })
    }
  }, [])

  const informations = [
    'P to pause',
    'L to execute a single frame',
    'R to reset',
    'Click and drag to add forces'
  ]

  return (
    <>
      <canvas width={800} height={600} ref={canvasRef} />
      <PageInformation lines={informations} />
    </>
  )
}
