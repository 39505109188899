import { Vector2 } from "src/core/math/Vector2";
import { Vector3 } from "src/core/math/Vector3";
import { Vector4 } from "src/core/math/Vector4";

export type UniformValue = number | Vector2 | Vector3 | Vector4;

export enum UniformTypes {
  float,
  float2,
  float3,
  float4,
  int,
}

export class Uniforms {
  private _values: { [key: string]: Uniform<UniformValue> };
  private _program: WebGLProgram;
  private _gl: WebGLRenderingContext;

  constructor(program: WebGLProgram, gl: WebGLRenderingContext) {
    this._program = program;
    this._gl = gl;
    this._values = {};
  }

  private setInstance(
    name: string,
    instance: Uniform<UniformValue>
  ): Uniform<UniformValue> {
    this._values[name] = instance;
    return instance;
  }
  private getInstance(name: string): Uniform<UniformValue> {
    return this._values[name];
  }
  private getUniform(name: string, type: UniformTypes): Uniform<UniformValue> {
    const existing = this.getInstance(name);
    if (existing) {
      return existing;
    }
    return this.setInstance(name, this.createUniform(name, type));
  }
  private createUniform(
    name: string,
    type: UniformTypes
  ): Uniform<UniformValue> {
    switch (type) {
      case UniformTypes.float:
        return new FloatUniform(name, this._program, this._gl);
      case UniformTypes.float2:
        return new Float2Uniform(name, this._program, this._gl);
      case UniformTypes.float3:
        return new Float3Uniform(name, this._program, this._gl);
      case UniformTypes.float4:
        return new Float4Uniform(name, this._program, this._gl);
      case UniformTypes.int:
        return new IntUniform(name, this._program, this._gl);
      default:
        throw new Error(`Unsupported uniform type: ${type}`);
    }
  }

  setValue(name: string, type: UniformTypes, value: UniformValue) {
    this.getUniform(name, type).setValue(value);
  }
  setFloat(name: string, value: number) {
    this.setValue(name, UniformTypes.float, value);
  }
  setFloat2(name: string, value: Vector2) {
    this.setValue(name, UniformTypes.float2, value);
  }
  setFloat3(name: string, value: Vector3) {
    this.setValue(name, UniformTypes.float3, value);
  }
  setInt(name: string, value: number) {
    this.setValue(name, UniformTypes.int, value);
  }
  setProgram(program: WebGLProgram) {
    this._program = program;
    for (let name in this._values) {
      this._values[name].setProgram(program);
    }
  }
  update() {
    for (let name in this._values) {
      this._values[name].update();
    }
  }
}

abstract class Uniform<T extends UniformValue> {
  protected _instance: WebGLUniformLocation;
  protected _gl: WebGLRenderingContext;
  protected _value: T;
  private _name: string;

  constructor(name: string, program: WebGLProgram, gl: WebGLRenderingContext) {
    this._gl = gl;
    this._name = name;
    this.setProgram(program);
  }
  abstract update();
  setValue(value: T) {
    this._value = value;
  }
  setProgram(program: WebGLProgram) {
    this._instance = this._gl.getUniformLocation(program, this._name);
  }
}
class IntUniform extends Uniform<number> {
  update() {
    this._gl.uniform1i(this._instance, this._value);
  }
}
class FloatUniform extends Uniform<number> {
  update() {
    this._gl.uniform1f(this._instance, this._value);
  }
}
class Float2Uniform extends Uniform<Vector2> {
  update() {
    this._gl.uniform2f(this._instance, this._value.x, this._value.y);
  }
}
class Float3Uniform extends Uniform<Vector3> {
  update() {
    this._gl.uniform3f(
      this._instance,
      this._value.x,
      this._value.y,
      this._value.z
    );
  }
}
class Float4Uniform extends Uniform<Vector4> {
  update() {
    this._gl.uniform4f(
      this._instance,
      this._value.x,
      this._value.y,
      this._value.z,
      this._value.w,
    );
  }
}
