import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { Vector2 } from '../math/Vector2'
import { IDisposable } from './IDisposable'

export enum CameraType {
  Perspective,
  Orthographic,
}

export class Camera implements IDisposable {
  private _currentCamera: THREE.Camera
  private _perspectiveCamera?: THREE.PerspectiveCamera
  private _orthographicCamera?: THREE.OrthographicCamera
  private _controls?: OrbitControls
  private _type: CameraType
  private _size: Vector2
  private _renderer: THREE.Renderer

  get camera() {
    return this._currentCamera
  }

  get position() {
    return this._currentCamera.position
  }

  constructor(type: CameraType, size: Vector2, renderer: THREE.Renderer) {
    this._type = type
    this._size = size
    this._renderer = renderer
    this.createFromType()

    if (type === CameraType.Perspective) {
      this._currentCamera.position.set(0, 1, 1)
    } else {
      this._currentCamera.position.set(0, 0, 1)
    }
    this._currentCamera.lookAt(new THREE.Vector3(0, 0, 0))
  }

  private createFromType() {
    if (this._type === CameraType.Perspective) {
      this.createPerspective()
    } else {
      this.createOrthographic()
    }
  }
  private createPerspective() {
    this._currentCamera = this._perspectiveCamera = new THREE.PerspectiveCamera(
      75,
      this._size.x / this._size.y,
      0.01,
      50
    )
  }
  private createOrthographic() {
    this._currentCamera = this._orthographicCamera =
      new THREE.OrthographicCamera()
  }

  setType(type: CameraType) {
    if (type !== this._type) {
      this._type = type
      this.createFromType()
    }
  }

  lookAt(position: THREE.Vector3) {
    this._currentCamera.lookAt(position)
    this._controls?.target.copy(position)
  }

  setPosition(position: THREE.Vector3) {
    this._currentCamera.position.copy(position)
  }

  addOrbitControls() {
    this._controls = new OrbitControls(
      this._currentCamera,
      this._renderer.domElement
    )
  }

  setSize(size: Vector2) {
    if (this._perspectiveCamera) {
      this._perspectiveCamera.aspect = size.x / size.y
      this._perspectiveCamera.updateProjectionMatrix()
    }else if (this._orthographicCamera) {
      //this._orthographicCamera.
    }
  }

  update(){
    this._controls?.update()
  }

  dispose() {
    this._controls?.dispose()
  }
}
