import { useEffect, useRef, useState } from "react";
import { Help } from "./Help";
import { Panorama } from "./Panorama";

export const PanoramaPage = () => {
  const [helpVisible, setHelpVisible] = useState(true);
  const canvasRef = useRef<HTMLCanvasElement>();

  const onHideHelpHandler = () => {
    setHelpVisible(false);
  };

  useEffect(() => {
    let p = new Panorama(canvasRef.current);
    return () => p.dispose();
  }, []);

  return (
    <div>
      <canvas ref={canvasRef}></canvas>
      {helpVisible && <Help onHide={onHideHelpHandler} />}
    </div>
  );
};
