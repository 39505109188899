import { ShaderLogInfo } from "./ShaderLogInfo";

export class ShaderCompilationResult {
  private _success: boolean;
  private _shader?: WebGLShader;
  private _logs?: ShaderLogInfo[];

  constructor(success: boolean, shader?: WebGLShader, log?: string) {
    this._success = success;
    this._shader = shader;
    if (log) {
      this._logs = ShaderLogInfo.parseArray(log);
    }
  }

  get success() {
    return this._success;
  }
  get shader(): WebGLShader {
    return this._shader;
  }
  get logs(): string[] {
    return this._logs?.map((e) => e.source) ?? [];
  }
}
