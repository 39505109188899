import { Vector3 } from "./Vector3";
import { Vector2 } from "./Vector2";

export class Matrix {
  constructor(public values = new Float32Array(16)) {}

  multiply(m: Matrix): Matrix {
    let tv = this.values;
    let mv = m.values;
    let values = new Float32Array([
      tv[0] * mv[0] + tv[1] * mv[4] + tv[2] * mv[8] + tv[3] * mv[12],
      tv[0] * mv[1] + tv[1] * mv[5] + tv[2] * mv[9] + tv[3] * mv[13],
      tv[0] * mv[2] + tv[1] * mv[6] + tv[2] * mv[10] + tv[3] * mv[14],
      tv[0] * mv[3] + tv[1] * mv[7] + tv[2] * mv[11] + tv[3] * mv[15],
      tv[4] * mv[0] + tv[5] * mv[4] + tv[6] * mv[8] + tv[7] * mv[12],
      tv[4] * mv[1] + tv[5] * mv[5] + tv[6] * mv[9] + tv[7] * mv[13],
      tv[4] * mv[2] + tv[5] * mv[6] + tv[6] * mv[10] + tv[7] * mv[14],
      tv[4] * mv[3] + tv[5] * mv[7] + tv[6] * mv[11] + tv[7] * mv[15],
      tv[8] * mv[0] + tv[9] * mv[4] + tv[10] * mv[8] + tv[11] * mv[12],
      tv[8] * mv[1] + tv[9] * mv[5] + tv[10] * mv[9] + tv[11] * mv[13],
      tv[8] * mv[2] + tv[9] * mv[6] + tv[10] * mv[10] + tv[11] * mv[14],
      tv[8] * mv[3] + tv[9] * mv[7] + tv[10] * mv[11] + tv[11] * mv[15],
      tv[12] * mv[0] + tv[13] * mv[4] + tv[14] * mv[8] + tv[15] * mv[12],
      tv[12] * mv[1] + tv[13] * mv[5] + tv[14] * mv[9] + tv[15] * mv[13],
      tv[12] * mv[2] + tv[13] * mv[6] + tv[14] * mv[10] + tv[15] * mv[14],
      tv[12] * mv[3] + tv[13] * mv[7] + tv[14] * mv[11] + tv[15] * mv[15],
    ]);
    return new Matrix(values);
  }
  invert(): Matrix {
    let tv = this.values;
    let values = new Float32Array(16);
    let num23 = tv[10] * tv[15] - tv[11] * tv[14];
    let num22 = tv[9] * tv[15] - tv[11] * tv[13];
    let num21 = tv[9] * tv[14] - tv[10] * tv[13];
    let num20 = tv[8] * tv[15] - tv[11] * tv[12];
    let num19 = tv[8] * tv[14] - tv[10] * tv[12];
    let num18 = tv[8] * tv[13] - tv[9] * tv[12];
    let num39 = tv[5] * num23 - tv[6] * num22 + tv[7] * num21;
    let num38 = -(tv[4] * num23 - tv[6] * num20 + tv[7] * num19);
    let num37 = tv[4] * num22 - tv[5] * num20 + tv[7] * num18;
    let num36 = -(tv[4] * num21 - tv[5] * num19 + tv[6] * num18);
    let num =
      1.0 / (tv[0] * num39 + tv[1] * num38 + tv[2] * num37 + tv[3] * num36);
    values[0] = num39 * num;
    values[4] = num38 * num;
    values[8] = num37 * num;
    values[12] = num36 * num;
    values[1] = -(tv[1] * num23 - tv[2] * num22 + tv[3] * num21) * num;
    values[5] = (tv[0] * num23 - tv[2] * num20 + tv[3] * num19) * num;
    values[9] = -(tv[0] * num22 - tv[1] * num20 + tv[3] * num18) * num;
    values[13] = (tv[0] * num21 - tv[1] * num19 + tv[2] * num18) * num;
    let num35 = tv[6] * tv[15] - tv[7] * tv[14];
    let num34 = tv[5] * tv[15] - tv[7] * tv[13];
    let num33 = tv[5] * tv[14] - tv[6] * tv[13];
    let num32 = tv[4] * tv[15] - tv[7] * tv[12];
    let num31 = tv[4] * tv[14] - tv[6] * tv[12];
    let num30 = tv[4] * tv[13] - tv[5] * tv[12];
    values[2] = (tv[1] * num35 - tv[2] * num34 + tv[3] * num33) * num;
    values[6] = -(tv[0] * num35 - tv[2] * num32 + tv[3] * num31) * num;
    values[10] = (tv[0] * num34 - tv[1] * num32 + tv[3] * num30) * num;
    values[14] = -(tv[0] * num33 - tv[1] * num31 + tv[2] * num30) * num;
    let num29 = tv[6] * tv[11] - tv[7] * tv[10];
    let num28 = tv[5] * tv[11] - tv[7] * tv[9];
    let num27 = tv[5] * tv[10] - tv[6] * tv[9];
    let num26 = tv[4] * tv[11] - tv[7] * tv[8];
    let num25 = tv[4] * tv[10] - tv[6] * tv[8];
    let num24 = tv[4] * tv[9] - tv[5] * tv[8];
    values[3] = -(tv[1] * num29 - tv[2] * num28 + tv[3] * num27) * num;
    values[7] = (tv[0] * num29 - tv[2] * num26 + tv[3] * num25) * num;
    values[11] = -(tv[0] * num28 - tv[1] * num26 + tv[3] * num24) * num;
    values[15] = (tv[0] * num27 - tv[1] * num25 + tv[2] * num24) * num;
    return new Matrix(values);
  }
  transpose(): Matrix {
    let values = new Float32Array([
      this.values[0],
      this.values[4],
      this.values[8],
      this.values[12],
      this.values[1],
      this.values[5],
      this.values[9],
      this.values[13],
      this.values[2],
      this.values[6],
      this.values[10],
      this.values[14],
      this.values[3],
      this.values[7],
      this.values[11],
      this.values[15],
    ]);
    return new Matrix(values);
  }
  project(
    source: Vector3,
    width: number,
    height: number,
    near: number,
    far: number
  ): Vector3 {
    let vector = source.transform(this);
    let a =
      source.x * this.values[3] +
      source.y * this.values[7] +
      source.z * this.values[11] +
      this.values[15];
    vector.x = (vector.x / a + 1) * 0.5 * width;
    vector.y = (-vector.y / a + 1) * 0.5 * height;
    vector.z = vector.z / a;
    return vector;
  }
  unproject(
    source: Vector3,
    width: number,
    height: number,
    near: number,
    far: number
  ): Vector3 {
    let v = new Vector3(
      (source.x / width) * 2.0 - 1.0,
      -(source.y / height) * 2.0 - 1.0,
      source.z
    );

    let inverse = this.invert();
    let vector = v.transform(inverse);
    let a =
      v.x * inverse.values[3] +
      v.y * inverse.values[7] +
      v.z * inverse.values[11] +
      inverse.values[15];
    return vector.selfDivideScalar(a);
  }
  values3x3(): Float32Array {
    return new Float32Array([
      this.values[0],
      this.values[1],
      this.values[2],
      this.values[4],
      this.values[5],
      this.values[6],
      this.values[8],
      this.values[9],
      this.values[10],
    ]);
  }
  clone(): Matrix {
    return new Matrix(this.values);
  }

  static identity(): Matrix {
    return new Matrix(
      new Float32Array([1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1])
    );
  }
  static orthographic(
    width: number,
    height: number,
    near: number,
    far: number
  ): Matrix {
    let values = new Float32Array(16);
    values[0] = 2.0 / width;
    values[1] = values[2] = values[3] = 0.0;
    values[5] = 2.0 / height;
    values[4] = values[6] = values[7] = 0.0;
    values[10] = 1.0 / (near - far);
    values[8] = values[9] = values[11] = 0.0;
    values[12] = values[13] = 0.0;
    values[14] = near / (near - far);
    values[15] = 1.0;
    return new Matrix(values);
  }
  static perspective(
    fov: number,
    aspect: number,
    near: number,
    far: number
  ): Matrix {
    let n1 = 1.0 / Math.tan(fov * 0.5);
    let n2 = n1 / aspect;
    let values = new Float32Array(16);
    values[0] = n2;
    values[1] = values[2] = values[3] = 0.0;
    values[5] = n1;
    values[4] = values[6] = values[7] = 0.0;
    values[8] = values[9] = 0.0;
    values[10] = far / (near - far);
    values[11] = -1.0;
    values[12] = values[13] = values[15] = 0.0;
    values[14] = (near * far) / (near - far);
    return new Matrix(values);
  }
  static lookAt(position: Vector3, target: Vector3, up: Vector3): Matrix {
    let v1 = position.subtract(target).selfNormalize();
    let v2 = up.cross(v1).selfNormalize();
    let v3 = v1.cross(v2);
    let values = new Float32Array(16);
    values[0] = v2.x;
    values[1] = v3.x;
    values[2] = v1.x;
    values[3] = 0.0;
    values[4] = v2.y;
    values[5] = v3.y;
    values[6] = v1.y;
    values[7] = 0.0;
    values[8] = v2.z;
    values[9] = v3.z;
    values[10] = v1.z;
    values[11] = 0.0;
    values[12] = -v2.dot(position);
    values[13] = -v3.dot(position);
    values[14] = -v1.dot(position);
    values[15] = 1.0;
    return new Matrix(values);
  }
  static rotationX(rotation: number): Matrix {
    let cos = Math.cos(rotation);
    let sin = Math.sin(rotation);
    let values = new Float32Array([
      1,
      0,
      0,
      0,
      0,
      cos,
      sin,
      0,
      0,
      -sin,
      cos,
      0,
      0,
      0,
      0,
      1,
    ]);
    return new Matrix(values);
  }
  static rotationZ(rotation: number): Matrix {
    let cos = Math.cos(rotation);
    let sin = Math.sin(rotation);
    let values = new Float32Array([
      cos,
      sin,
      0,
      0,
      -sin,
      cos,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
    ]);
    return new Matrix(values);
  }
  static rotateAxisAngle(axis: Vector3, angle: number): Matrix {
    let x = axis.x;
    let y = axis.y;
    let z = axis.z;
    let sin = Math.sin(angle);
    let cos = Math.cos(angle);
    let x2 = x * x;
    let y2 = y * y;
    let z2 = z * z;
    let xy = x * y;
    let xz = x * z;
    let yz = y * z;
    let values = [
      x2 + cos * (1 - x2),
      xy - cos * xy + sin * z,
      xz - cos * xz - sin * y,
      0,
      xy - cos * xy - sin * z,
      y2 + cos * (1 - y2),
      yz - cos * yz + sin * x,
      0,
      xz - cos * xz + sin * y,
      yz - cos * yz - sin * x,
      z2 + cos * (1 - z2),
      0,
      0,
      0,
      0,
      1,
    ];
    return new Matrix(new Float32Array(values));
  }
  static translation2D(position: Vector2): Matrix {
    return new Matrix(
      new Float32Array([
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        position.x,
        position.y,
        0,
        1,
      ])
    );
  }
  static translation(position: Vector3): Matrix {
    return new Matrix(
      new Float32Array([
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        position.x,
        position.y,
        position.z,
        1,
      ])
    );
  }
  static scale(scale: number): Matrix {
    return new Matrix(
      new Float32Array([
        scale,
        0,
        0,
        0,
        0,
        scale,
        0,
        0,
        0,
        0,
        scale,
        0,
        0,
        0,
        0,
        1,
      ])
    );
  }
  static scaleVector2(scale: Vector2): Matrix {
    return new Matrix(
      new Float32Array([
        scale.x,
        0,
        0,
        0,
        0,
        scale.y,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        1,
      ])
    );
  }
  static scaleVector(scale: Vector3): Matrix {
    return new Matrix(
      new Float32Array([
        scale.x,
        0,
        0,
        0,
        0,
        scale.y,
        0,
        0,
        0,
        0,
        scale.z,
        0,
        0,
        0,
        0,
        1,
      ])
    );
  }
}
