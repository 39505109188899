import { IVector3 } from './interfaces'

export class Quaternion {
  x: number
  y: number
  z: number
  w: number

  constructor(x?: number, y?: number, z?: number, w?: number) {
    this.x = x || 0
    this.y = y || 0
    this.z = z || 0
    this.w = w || 0
  }

  clone(): Quaternion {
    return new Quaternion(this.x, this.y, this.z, this.w)
  }
  multiply(q: Quaternion): Quaternion {
    const result = new Quaternion(0, 0, 0, 1)
    const num4 = q.x
    const num3 = q.y
    const num2 = q.z
    const num = q.w
    const num12 = this.y * num2 - this.z * num3
    const num11 = this.z * num4 - this.x * num2
    const num10 = this.x * num3 - this.y * num4
    const num9 = this.x * num4 + this.y * num3 + this.z * num2
    result.x = this.x * num + num4 * this.w + num12
    result.y = this.y * num + num3 * this.w + num11
    result.z = this.z * num + num2 * this.w + num10
    result.w = this.w * num - num9
    return result
  }

  static identity(): Quaternion {
    return new Quaternion(0, 0, 0, 1)
  }
  static fromAxisAngle(axis: IVector3, angle: number): Quaternion {
    let halfAngle = angle * 0.5
    let sin = Math.sin(halfAngle)
    let x = axis.x * sin
    let y = axis.y * sin
    let z = axis.z * sin
    let w = Math.cos(halfAngle)
    return new Quaternion(x, y, z, w)
  }
}
