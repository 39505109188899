import { Vector2 } from "./Vector2";

export class Rectangle {
  min: Vector2;
  max: Vector2;

  constructor(min: Vector2, max: Vector2) {
    this.min = min.clone();
    this.max = max.clone();
  }

  clone(): Rectangle {
    return new Rectangle(this.min, this.max);
  }
  size(): Vector2 {
    return this.max.subtract(this.min);
  }
  intersects(r: Rectangle): boolean {
    let outside =
      this.min.x > r.max.x ||
      this.max.x < r.min.x ||
      this.min.y > r.max.y ||
      this.max.y < r.min.y;
    return !outside;
  }
  /**
   * Increase the rectangle top, bottom, left and right by the given ammount.
   * A negative value will make the rectangle smaller.
   * @param ammount Ammont to increse the rectangle sides.
   */
  increaseAllSides(ammount: number) {
    if (ammount > 0) {
      this.min.x -= ammount;
      this.min.y -= ammount;
      this.max.x += ammount;
      this.max.y += ammount;
    } else {
      let size = this.size().multiplyScalar(0.5).minScalar(-ammount);
      this.min.x += size.x;
      this.min.y += size.y;
      this.max.x -= size.x;
      this.max.y -= size.y;
    }
  }
  merge(other: Rectangle) {
    this.min.selfMin(other.min);
    this.max.selfMax(other.max);
  }
  /**
   * Increases the rectangle size by the specified vector.
   * Positive values will incrcrease the max position
   * and negative values will decrease the min position.
   * @param change The change vector.
   */
  increaseByVector(change: Vector2) {
    if (change.x > 0) this.max.x += change.x;
    else this.min.x += change.x;

    if (change.y > 0) this.max.y += change.y;
    else this.min.y += change.y;
  }
  toString(): string {
    return `{ min: ${this.min}, max: ${this.max} }`;
  }

  static fromPoints(points: Vector2[]): Rectangle {
    let min = new Vector2(Number.MAX_VALUE, Number.MAX_VALUE);
    let max = new Vector2(-Number.MAX_VALUE, -Number.MAX_VALUE);
    for (let p of points) {
      min.x = Math.min(min.x, p.x);
      min.y = Math.min(min.y, p.y);
      max.x = Math.max(max.x, p.x);
      max.y = Math.max(max.y, p.y);
    }
    return new Rectangle(min, max);
  }
  static fromRectangles(rectangles: Rectangle[] | null): Rectangle {
    if (!rectangles || rectangles.length === 0)
      return new Rectangle(new Vector2(0, 0), new Vector2(0, 0));

    let result = new Rectangle(rectangles[0].min, rectangles[0].max);
    for (let i = 1; i < rectangles.length; i++) result.merge(rectangles[i]);

    return result;
  }
}
