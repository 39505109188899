import { GUI } from 'dat.gui'

const defaultOptions = {
  stars: 100000,
  radius: 0.7,
  height: 0.5,
  gravity: 0.35,
  starSizeMin: 0.02,
  starSizeMax: 0.06,
  speed: 1,
  color1: '#ffd460',
  color2: '#ffffff',
  autoClear: false,
}

export type GalaxyOptions = typeof defaultOptions

export class GalaxyConfigurator {
  private _gui: GUI
  onChange: () => void
  options: GalaxyOptions = defaultOptions

  constructor() {
    this._gui = new GUI({ width: 300 })
    this._gui.useLocalStorage = true
    this._gui.remember(this.options)

    const start = this._gui.addFolder('initial state')
    start.open()

    start
      .add(this.options, 'stars')
      .min(10)
      .max(1000000)
      .step(10)
      .onChange(this._onChangeHandler)

    start
      .add(this.options, 'radius')
      .min(0.01)
      .max(1)
      .step(0.01)
      .onChange(this._onChangeHandler)

    start
      .add(this.options, 'height')
      .min(0)
      .max(1)
      .step(0.01)
      .onChange(this._onChangeHandler)

    this._gui.add(this.options, 'gravity').min(0.01).max(5).step(0.01)

    this._gui.add(this.options, 'starSizeMin').min(0.01).max(0.1).step(0.001)

    this._gui.add(this.options, 'starSizeMax').min(0.01).max(0.1).step(0.001)

    this._gui.add(this.options, 'speed').min(0).max(5).step(0.01)

    this._gui.addColor(this.options, 'color1')
    this._gui.addColor(this.options, 'color2')

    this._gui.add(this.options, 'autoClear')
  }
  dispose() {
    this._gui.destroy()
  }

  private _onChangeHandler = () => {
    this._gui.save()
    this.onChange()
  }
}
