import { ShaderCompilationResult } from "./ShaderCompilationResult";

const createShader = (
  gl: WebGLRenderingContext,
  type: number,
  source: string
): ShaderCompilationResult => {
  let shader = gl.createShader(type);
  gl.shaderSource(shader, source);
  gl.compileShader(shader);

  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    const log = gl.getShaderInfoLog(shader);
    console.warn(log);
    gl.deleteShader(shader);
    return new ShaderCompilationResult(false, null, log);
  }

  return new ShaderCompilationResult(true, shader);
};

export const createVertexShader = (
  gl: WebGLRenderingContext,
  source: string
): ShaderCompilationResult => {
  return createShader(gl, gl.VERTEX_SHADER, source);
};

export const createFragmentShader = (
  gl: WebGLRenderingContext,
  source: string
): ShaderCompilationResult => {
  return createShader(gl, gl.FRAGMENT_SHADER, source);
};
