import { ThreeApp } from 'src/core/three/ThreeApp'
import * as THREE from 'three'

import vertexShader from './vertex.glsl'
import fragmentShader from './fragment.glsl'
import { Configurator } from './Configurator'

export class ShaderTerrain extends ThreeApp {
  private _material: THREE.ShaderMaterial
  private _configurator: Configurator

  initialize(canvas: HTMLCanvasElement): void {
    super.initialize(canvas)
    this._camera.position.set(0, 0.3, 0.3)
    this._renderer.setClearColor(new THREE.Color(0.4, 0.4, 0.4))
    this._configurator = new Configurator()
    this._configurator.onChange = () => this.setConfigs()
    this.addDisposable(this._configurator)

    this.createScene()
    this._camera.addOrbitControls()
  }

  dispose() {
    super.dispose()
  }

  protected beforeDraw(elapsedSeconds: number, totalSeconds: number): void {
    this._material.uniforms['uTime'].value = totalSeconds
  }

  private createScene() {
    const configs = this._configurator.values
    const plane = new THREE.PlaneGeometry(4, 4, 1000, 1000)
    this._material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        uTime: { value: 0 },
        uBottomColor: { value: new THREE.Color(configs.bottomColor) },
        uTopColor: { value: new THREE.Color(configs.topColor) },
        uFogDistance: { value: configs.fogDistance }
      },
    })
    this.setConfigs()
    plane.rotateX(-Math.PI / 2)
    this._scene.add(new THREE.Mesh(plane, this._material))
    this.addDisposable(plane, this._material)
  }

  private setConfigs() {
    const configs = this._configurator.values
    this._material.uniforms['uBottomColor'].value.set(configs.bottomColor)
    this._material.uniforms['uTopColor'].value.set(configs.topColor)
    this._material.uniforms['uFogDistance'].value = configs.fogDistance
  }
}
