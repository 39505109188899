import { IEffect } from './IEffect';
import { Vector2 } from 'src/core/math/Vector2';
import { Color } from 'src/core/Color';

export class FibonacciEffect implements IEffect {
    fibonacciNumbers: Array<number>;
    center: Vector2;
    startAngle: number;
    color: Color;
    nextColor: Color;
    maxDist: number;

    constructor(public context: CanvasRenderingContext2D) {
        this.fibonacciNumbers = this.getNumbers();
        this.center = new Vector2(context.canvas.width / 2, context.canvas.height / 2);
        this.startAngle = 0;
        this.color = Color.random;
        this.nextColor = Color.random;
        this.maxDist = Math.min(context.canvas.width / 2, context.canvas.height / 2);

        this.context.fillStyle = 'black';
        this.context.fillRect(0, 0, this.context.canvas.width, this.context.canvas.height);
    }

    getNumbers(): Array<number> {
        let numbers = new Array<number>(14);

        let n1 = 0;
        let n2 = 1;
        for (let i = 0; i < numbers.length; i++) {
            numbers[i] = n2;
            let next = n2 + n1;
            n1 = n2;
            n2 = next;
        }

        return numbers;
    }

    getName(): string {
        return "Fibonnaci";
    }

    update() {
        this.startAngle += 0.1;
        if (this.startAngle > Math.PI * 2)
            this.startAngle -= Math.PI * 2;

        this.color.r += this.color.r > this.nextColor.r ? -1 : 1;
        this.color.g += this.color.g > this.nextColor.g ? -1 : 1;
        this.color.b += this.color.b > this.nextColor.b ? -1 : 1;

        if (this.color.r === this.nextColor.r)
            this.nextColor.r = Math.round(Math.random() * 255);
        if (this.color.g === this.nextColor.g)
            this.nextColor.g = Math.round(Math.random() * 255);
        if (this.color.b === this.nextColor.b)
            this.nextColor.b = Math.round(Math.random() * 255);
    }

    draw() {
        this.context.beginPath();
        this.context.moveTo(this.center.x, this.center.y);
        for (let n = 1; n < this.fibonacciNumbers.length; n++) {
            let angle = 0;
            for (let a = 2; a <= 360; a += 1) {
                let prop = a / 360.0;
                angle = this.startAngle + 2 * Math.PI * prop;

                let dist = (prop * this.fibonacciNumbers[n] + (1 - prop) * this.fibonacciNumbers[n - 1]);
                if (dist > this.maxDist)
                    break;

                let cos = Math.cos(angle);
                let sin = Math.sin(angle);

                let x = this.center.x + cos * dist;
                let y = this.center.y + sin * dist;

                this.context.lineTo(x, y);
            }
        }

        this.context.lineWidth = 2.5;
        this.context.strokeStyle = this.color.toString();
        this.context.stroke();
        this.context.closePath();


        //Blur
        let width = this.context.canvas.width;
        let height = this.context.canvas.height;
        let factor = 0.6;
        let invFactor = 1 - factor;
        let imageData = this.context.getImageData(0, 0, width, height);
        let data = imageData.data;
        for (let i = 0; i < width; i++) {
            for (let j = 0; j < height - 1; j++) {

                let index1 = (i + (j - 1) * width) * 4;
                let index2 = (i + j * width) * 4;
                if (index1 < 0) {
                    data[index2] = data[index2] * factor;
                    data[index2 + 1] = data[index2 + 1] * factor;
                    data[index2 + 2] = data[index2 + 2] * factor;
                }
                else {
                    data[index2] = data[index1] * invFactor + data[index2] * factor;
                    data[index2 + 1] = data[index1 + 1] * invFactor + data[index2 + 1] * factor;
                    data[index2 + 2] = data[index1 + 2] * invFactor + data[index2 + 2] * factor;
                }
            }
        }

        this.context.putImageData(imageData, 0, 0);
    }
}