import { Vector2 } from "src/core/math/Vector2";


export class Settings {
  gridWidth = 10;
  gridHeight = 20;
  gridStartPosition = new Vector2(20, 20);
  blockStartPosition = new Vector2(4, -1);
  gridAuxWidth = 4;
  gridAuxHeight = 4;
  gridAuxStartPosition = new Vector2(340, 20);
  gridAuxBlockStartPosition = new Vector2(1, 3);
  squareSize = 30;
  updateInterval = 1000;
  timeToChangeSpeed = 30000;
}
