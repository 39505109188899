/**
 * Generates a random guid using numbers, lowercase and uppercase letters.
 * @param chars Number of characters in the guid. Default is 10.
 */
export function sguid(chars = 10): string {
    let result = "";
    for (let i = 0; i < chars; i++) {
        let n = 48 + Math.floor(Math.random() * 62);

        if (n > 57)
            n += 7;
        if (n > 90)
            n += 6;

        result += String.fromCharCode(n);
    }

    return result;
}