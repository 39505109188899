import { WebGLTools } from "../../WebGLTools";
import { Sprite } from "./Sprite";

import defaultVS from "./shaders/full-screen-sprite-vs.glsl"
import defaultFS from "./shaders/full-screen-sprite-fs.glsl"

export class FullScreenSprite extends Sprite {
  private _vertexBuffer: WebGLBuffer;
  private _texCoordBuffer: WebGLBuffer;

  constructor(gl: WebGLRenderingContext, vertexShader?: string, fragmentShader?: string) {
    super(gl, vertexShader ?? defaultVS, fragmentShader ?? defaultFS);

    let vertices = [1, 1, -1, 1, 1, -1, -1, -1];
    let texCoords = [1, 1, 0, 1, 1, 0, 0, 0];
    this._vertexBuffer = WebGLTools.createStaticVertexBuffer(
      gl,
      new Float32Array(vertices)
    );
    this._texCoordBuffer = WebGLTools.createStaticVertexBuffer(
      gl,
      new Float32Array(texCoords)
    );
  }

  render(texture: WebGLBuffer) {
    super.render(this._vertexBuffer, this._texCoordBuffer, false, texture);
  }
  dispose() {
    super.dispose();
    this._gl.deleteBuffer(this._vertexBuffer);
    this._gl.deleteBuffer(this._texCoordBuffer);
  }
}
