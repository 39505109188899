import { KeyboardEvent, useRef } from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";

type Props = {
  className?: string;
  shader: string;
  onChange: (shader: string) => void;
};

document.documentElement.setAttribute("data-color-mode", "dark");

export const ShaderEditor = ({ className, shader, onChange }: Props) => {
  const codeEditorRef = useRef<HTMLTextAreaElement>()
  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && event.ctrlKey) {
      onChange(codeEditorRef.current.value);
      event.preventDefault();
      return false;
    }
  };

  return (
    <div className={className}>
      <span>ctrl+enter to apply</span>
      <CodeEditor
        value={shader}
        ref={codeEditorRef}
        language="glsl"
        onKeyDown={handleKeyDown}
        padding={15}
        style={{
          fontSize: 12,
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
      />
    </div>
  );
};
