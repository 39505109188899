import { Vector2 } from 'src/core/math/Vector2';
import { IRenderable } from 'src/core/rendering/webgl/IRenderable';
import { IBaseRenderer } from 'src/core/rendering/IBaseRenderer';
import { IBody } from 'src/core/physics2D/IBody';
import { IShape } from 'src/core/physics2D/collision/IShape';

/** Represents players and NPCs. */
export abstract class Character implements IRenderable, IBody {
    protected _life: number;
    protected _totalLife: number;
    protected _position: Vector2;
    shape: IShape;

    get life(): number {
        return this._life;
    }
    get totalLife(): number {
        return this._totalLife;
    }
    get position(): Vector2 {
        return this._position;
    }
    set position(v: Vector2) {
        this._position = v;
    }

    move(change: Vector2) {
        this._position.selfAdd(change);
    }

    abstract rendererUpdade(renderer: IBaseRenderer);
}