import { Vector2 } from 'src/core/math/Vector2';
import { Rectangle } from 'src/core/math/Rectangle';
import { IIdentifiable } from 'src/core/IIdentifiable';
import { IWithRectangle } from './IWithRectangle';

export interface IShape extends IIdentifiable, IWithRectangle {
    readonly type: ShapeType;
    intersectsRectangle(rectangle: Rectangle): boolean;
    move(change: Vector2): void;
}

export enum ShapeType {
    Circle,
    Line
}