import { useCallback, useEffect, useState } from "react";
import { ConfigurationsSection } from "src/components/configurations/ConfigurationsSection";
import { SelectOption } from "src/components/configurations/SelectOption";
import {
  builtInShaders,
  defaultShaderIndex,
  getShaderFromFile,
} from "../shaders";
import { ShaderEditor } from "./ShaderEditor";
import { ShaderLogs } from "./ShaderLogs";

import styles from "./Configuration.module.css";

type Props = {
  logs: string[];
  onShaderChanged: (value: string) => void;
};

export const Configuration = ({ logs, onShaderChanged }: Props) => {
  const [shader, setShader] = useState("");
  const [builtInShaderName, setBuiltInShaderName] = useState(
    builtInShaders[defaultShaderIndex].id
  );

  const handleShaderChange = useCallback(
    (shader: string) => {
      setShader(shader);
      onShaderChanged(shader);
    },
    [onShaderChanged]
  );
  const handleBuiltInShaderChange = (fileName: string) => {
    setBuiltInShaderName(fileName);
  };

  useEffect(() => {
    getShaderFromFile(builtInShaderName).then(handleShaderChange);
  }, [builtInShaderName, handleShaderChange]);

  return (
    <ConfigurationsSection className={styles["configurations-section"]}>
      <div className={styles.configurations}>
        <SelectOption
          values={builtInShaders}
          value={builtInShaderName}
          onChange={handleBuiltInShaderChange}
        />
        <ShaderEditor
          className={styles.editor}
          shader={shader}
          onChange={handleShaderChange}
        />
        <ShaderLogs logs={logs} />
      </div>
    </ConfigurationsSection>
  );
};
