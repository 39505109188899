import { Vector2 } from 'src/core/math/Vector2'
import { Texture } from 'src/core/webGPU/textures/Texture'

export class TexturePair {
  private _sourceIndex = 0
  private _textures: [Texture, Texture]

  constructor(textures: [Texture, Texture]) {
    this._textures = textures
  }

  get sourceIndex(): number {
    return this._sourceIndex  
  }

  get destinationIndex(): number {
    return this._sourceIndex === 0 ? 1 : 0  
  }

  get source(): Texture {
    return this._textures[this._sourceIndex]
  }

  get destination(): Texture {
    return this._textures[this.destinationIndex]
  }

  swap(): void {
    this._sourceIndex = this._sourceIndex === 0 ? 1 : 0
  }

  resize(size: Vector2): void {
    this._textures.forEach(t => t.resize(size))
  }

  dispose(): void {
    this._textures.forEach(t => t.dispose())
  }
}
