import { useEffect, useRef } from 'react'
import { GalaxyGenerator3 } from './GalaxyGenerator3'

export const GalaxyGenerator3Page = () => {
  const canvasRef = useRef<HTMLCanvasElement>()

  useEffect(() => {
    const program = new GalaxyGenerator3()
    const initPromise = program.initialize(canvasRef.current).then(() => {
      program.start()
    })
    return () => {
      initPromise.then(() => {
        program.dispose()
      })
    }
  }, [])

  return <canvas ref={canvasRef} />
}
