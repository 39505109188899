import { IMatrix, IQuaternion } from "./interfaces";
import { Vector2 } from "./Vector2";

export class Vector3 {
  constructor(public x = 0, public y = 0, public z = 0) {}

  add(v: Vector3): Vector3 {
    return new Vector3(this.x + v.x, this.y + v.y, this.z + v.z);
  }
  selfAdd(v: Vector3): Vector3 {
    this.x += v.x;
    this.y += v.y;
    this.z += v.z;
    return this;
  }
  subtract(v: Vector3): Vector3 {
    return new Vector3(this.x - v.x, this.y - v.y, this.z - v.z);
  }
  selfSubtract(v: Vector3): Vector3 {
    this.x -= v.x;
    this.y -= v.y;
    this.z -= v.z;
    return this;
  }
  multiplyScalar(scalar: number): Vector3 {
    return new Vector3(this.x * scalar, this.y * scalar, this.z * scalar);
  }
  selfMultiplyScalar(scalar: number): Vector3 {
    this.x *= scalar;
    this.y *= scalar;
    this.z *= scalar;
    return this;
  }
  divideScalar(scalar: number): Vector3 {
    return new Vector3(this.x / scalar, this.y / scalar, this.z / scalar);
  }
  selfDivideScalar(scalar: number): Vector3 {
    this.x /= scalar;
    this.y /= scalar;
    this.z /= scalar;
    return this;
  }
  length(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
  }
  size(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
  }
  setLength(value: number): Vector3 {
    return this.selfMultiplyScalar(value / this.length());
  }
  distance(v: Vector3): number {
    let x = this.x - v.x;
    let y = this.y - v.y;
    let z = this.z - v.z;
    return Math.sqrt(x * x + y * y + z * z);
  }
  lengthSquared(): number {
    return this.x * this.x + this.y * this.y + this.z * this.z;
  }
  normalize(): Vector3 {
    let l = 1.0 / this.length();
    return this.multiplyScalar(l);
  }
  selfNormalize(): Vector3 {
    let l = 1.0 / this.length();
    return this.selfMultiplyScalar(l);
  }
  cross(v: Vector3): Vector3 {
    return new Vector3(
      this.y * v.z - this.z * v.y,
      this.z * v.x - this.x * v.z,
      this.x * v.y - this.y * v.x
    );
  }
  dot(other: Vector3): number {
    return this.x * other.x + this.y * other.y + this.z * other.z;
  }
  negate(): Vector3 {
    return new Vector3(-this.x, -this.y, -this.z);
  }
  selfNegate(): Vector3 {
    this.x = -this.x;
    this.y = -this.y;
    this.z = -this.z;
    return this;
  }
  lerp(v: Vector3, t: number): Vector3 {
    return this.add(v.subtract(this).selfMultiplyScalar(t));
  }
  directionTo(v: Vector3): Vector3 {
    return v.subtract(this).selfNormalize();
  }
  rotateZ(angle: number): Vector3 {
    let cos = Math.cos(angle);
    let sin = Math.sin(angle);
    return new Vector3(
      this.x * cos - this.y * sin,
      this.x * sin + this.y * cos,
      this.z
    );
  }
  transform(m: IMatrix): Vector3 {
    return new Vector3(
      this.x * m.values[0] +
        this.y * m.values[4] +
        this.z * m.values[8] +
        m.values[12],
      this.x * m.values[1] +
        this.y * m.values[5] +
        this.z * m.values[9] +
        m.values[13],
      this.x * m.values[2] +
        this.y * m.values[6] +
        this.z * m.values[10] +
        m.values[14]
    );
  }
  transformNormal(m: IMatrix): Vector3 {
    return new Vector3(
      this.x * m.values[0] + this.y * m.values[4] + this.z * m.values[8],
      this.x * m.values[1] + this.y * m.values[5] + this.z * m.values[9],
      this.x * m.values[2] + this.y * m.values[6] + this.z * m.values[10]
    );
  }
  transformQuaternion(q: IQuaternion): Vector3 {
    let qx2 = q.x + q.x;
    let qy2 = q.y + q.y;
    let qz2 = q.z + q.z;
    let num11 = q.w * qx2;
    let num10 = q.w * qy2;
    let num9 = q.w * qz2;
    let num8 = q.x * qx2;
    let num7 = q.x * qy2;
    let num6 = q.x * qz2;
    let num5 = q.y * qy2;
    let num4 = q.y * qz2;
    let num3 = q.z * qz2;
    let x =
      this.x * (1 - num5 - num3) +
      this.y * (num7 - num9) +
      this.z * (num6 + num10);
    let y =
      this.x * (num7 + num9) +
      this.y * (1 - num8 - num3) +
      this.z * (num4 - num11);
    let z =
      this.x * (num6 - num10) +
      this.y * (num4 + num11) +
      this.z * (1 - num8 - num5);
    return new Vector3(x, y, z);
  }
  rotateAroundPoint(point: Vector3, quaternion: IQuaternion): Vector3 {
    return this.subtract(point).transformQuaternion(quaternion).selfAdd(point)
  }
  /**
   * Returns the smallest angle from this vector to another vector.
   */
  angleTo(other: Vector3): number {
    const dot = this.dot(other)
    const cosine = dot / (this.length() * other.length())
    return Math.acos(cosine)
  }
  clone(): Vector3 {
    return new Vector3(this.x, this.y, this.z);
  }
  equals(v: Vector3, tolerance = 0.01): boolean {
    return (
      Math.abs(this.x - v.x) < tolerance &&
      Math.abs(this.y - v.y) < tolerance &&
      Math.abs(this.z - v.z) < tolerance
    );
  }
  toVector2(): Vector2 {
    return new Vector2(this.x, this.y);
  }
  toArray(): Float32Array {
    return new Float32Array([this.x, this.y, this.z]);
  }
  toString(): string {
    return `{ x: ${this.x}, y: ${this.y}, z: ${this.z} }`;
  }

  static fromVector2(vector: Vector2, z = 0): Vector3 {
    return new Vector3(vector.x, vector.y, z);
  }
  static fromArray(values: number[]) {
    return new Vector3(values[0], values[1], values[2]);
  }
  static revive(v: Vector3): Vector3 | null {
    if (!v) return null;
    return new Vector3(v.x, v.y, v.z);
  }
  static float32Array(source: Vector3[]): Float32Array {
    let result = new Float32Array(source.length * 3);
    for (let i = 0; i < source.length; i++) {
      let i3 = i * 3;
      result[i3] = source[i].x;
      result[i3 + 1] = source[i].y;
      result[i3 + 2] = source[i].z;
    }
    return result;
  }
}
