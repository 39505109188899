import { assertIsDefined } from '../utils/asserts'

export class RenderTarget {
  private _gl: WebGLRenderingContext
  private _frameBuffer: WebGLFramebuffer
  private _texture: WebGLTexture
  private _depthBuffer: WebGLRenderbuffer
  private _width: number
  private _height: number
  private _clearColor: number[]

  constructor(gl: WebGLRenderingContext, width: number, height: number) {
    this._gl = gl
    this._width = width
    this._height = height

    const frameBuffer = this._gl.createFramebuffer()
    assertIsDefined(frameBuffer, 'frame buffer')
    this._frameBuffer = frameBuffer
    this._gl.bindFramebuffer(this._gl.FRAMEBUFFER, this._frameBuffer)
    
    const texture = this._gl.createTexture()
    assertIsDefined(texture, 'texture')
    this._texture = texture
    this._gl.bindTexture(this._gl.TEXTURE_2D, this._texture)
    this._gl.texParameteri(
      this._gl.TEXTURE_2D,
      this._gl.TEXTURE_MIN_FILTER,
      this._gl.LINEAR
    )
    this._gl.texParameteri(
      this._gl.TEXTURE_2D,
      this._gl.TEXTURE_WRAP_S,
      this._gl.CLAMP_TO_EDGE
    )
    this._gl.texParameteri(
      this._gl.TEXTURE_2D,
      this._gl.TEXTURE_WRAP_T,
      this._gl.CLAMP_TO_EDGE
    )

    this._gl.texImage2D(
      this._gl.TEXTURE_2D,
      0,
      this._gl.RGBA,
      width,
      height,
      0,
      this._gl.RGBA,
      this._gl.UNSIGNED_BYTE,
      null
    )

    const depthBuffer = this._gl.createRenderbuffer()
    assertIsDefined(depthBuffer)
    this._depthBuffer = depthBuffer
    this._gl.bindRenderbuffer(this._gl.RENDERBUFFER, this._depthBuffer)
    this._gl.renderbufferStorage(
      this._gl.RENDERBUFFER,
      this._gl.DEPTH_COMPONENT16,
      width,
      height
    )

    this._gl.framebufferTexture2D(
      this._gl.FRAMEBUFFER,
      this._gl.COLOR_ATTACHMENT0,
      this._gl.TEXTURE_2D,
      this._texture,
      0
    )
    this._gl.framebufferRenderbuffer(
      this._gl.FRAMEBUFFER,
      this._gl.DEPTH_ATTACHMENT,
      this._gl.RENDERBUFFER,
      this._depthBuffer
    )

    this._gl.bindTexture(this._gl.TEXTURE_2D, null)
    this._gl.bindRenderbuffer(this._gl.RENDERBUFFER, null)
    this._gl.bindFramebuffer(this._gl.FRAMEBUFFER, null)
  }
  get width(): number {
    return this._width
  }
  get height(): number {
    return this._height
  }
  get texture(): WebGLBuffer {
    return this._texture
  }
  set clearColor(c: number[]) {
    this._clearColor = c
  }

  setSize(width: number, height: number) {
    this._width = width
    this._height = height

    this._gl.bindTexture(this._gl.TEXTURE_2D, this._texture)
    this._gl.texImage2D(
      this._gl.TEXTURE_2D,
      0,
      this._gl.RGBA,
      width,
      height,
      0,
      this._gl.RGBA,
      this._gl.UNSIGNED_BYTE,
      null
    )

    this._gl.bindRenderbuffer(this._gl.RENDERBUFFER, this._depthBuffer)
    this._gl.renderbufferStorage(
      this._gl.RENDERBUFFER,
      this._gl.DEPTH_COMPONENT16,
      width,
      height
    )

    this._gl.bindRenderbuffer(this._gl.RENDERBUFFER, null)
  }
  clear() {
    if (this._clearColor)
      this._gl.clearColor(
        this._clearColor[0],
        this._clearColor[1],
        this._clearColor[2],
        this._clearColor[3]
      )

    this.enable()
    this._gl.clear(this._gl.COLOR_BUFFER_BIT | this._gl.DEPTH_BUFFER_BIT)
    this.disable()
  }
  enable() {
    this._gl.bindFramebuffer(this._gl.FRAMEBUFFER, this._frameBuffer)
  }
  disable() {
    this._gl.bindFramebuffer(this._gl.FRAMEBUFFER, null)
  }
  dispose() {
    this._gl.deleteFramebuffer(this._frameBuffer)
    this._gl.deleteTexture(this._texture)
    this._gl.deleteRenderbuffer(this._depthBuffer)
  }
}
