export function assertIsDefined<T>(
  value: T,
  name?: string
): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(`Value should be defined: ${name}`)
  }
}

export function assertDefined<T>(
  value: T | null | undefined,
  name?: string
): T {
  if (value === undefined || value === null) {
    throw new Error(`Value should be defined: ${name}`)
  }
  return value
}