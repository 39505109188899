import { WebGPUProgram } from 'src/core/webGPU/WebGPUProgram'
import { Vector2 } from 'src/core/math/Vector2'
import { Keyboard } from 'src/core/Keyboard'
import { Key } from 'src/core/Key'
import { ViewportDragEvent } from 'src/core/Viewport'
import { AlignmentTest } from './AlignmentTest/AlignmentTest'

export class Program extends WebGPUProgram {
  private _uniformArrayTest = new AlignmentTest(this._gpu)
  private _keyboard = new Keyboard()
  private _movement?: ViewportDragEvent

  constructor() {
    super({})
  }

  async initialize(canvas: HTMLCanvasElement): Promise<void> {
    await super.initialize(canvas)

    this._uniformArrayTest.createPipeline()

    this._keyboard.onKeyPress.add((key) => {
      if (key === Key.s) {
        this.toggleStats()
      }
    })
    this._viewport.onAnyDrag.add((e) => (this._movement = e))
    this._viewport.onResize.add((size) => this.resize(size))

    this._loop.speed = 20
    this._uniformArrayTest.run()
  }

  protected disposeProgram(): void {
    this._uniformArrayTest.dispose()
  }

  protected update(): void {
  }

  private resize(size: Vector2) {}
}
