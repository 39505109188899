import { Grid } from './Grid'
import { Block } from './Block'
import { Vector2 } from 'src/core/math/Vector2'
import { Viewport } from 'src/core/Viewport'
import { CanvasButton } from 'src/core/CanvasButton'
import { Color } from 'src/core/Color'
import { GameState } from './GameState'
import { Settings } from './Settings'

export class Tetris {
  private _viewport: Viewport
  private _state: GameState
  private _grid!: Grid
  private _gridAux!: Grid
  private _currentBlock!: Block
  private _nextBlock: Block | undefined
  private _settings!: Settings
  private _currentSpeedTime!: number
  private _lastTime!: number
  private _currentSpeed!: number
  private _currentUpdateInterval!: number
  private _points!: number
  private _canvas: HTMLCanvasElement
  private _context: CanvasRenderingContext2D
  private _timer!: number
  private _helpTexts = [
    'KEYBOARD',
    'Arrows to move',
    'A and S to rotate',
    'Enter to drop',
    'R to restart',
    'Space to pause',
    '',
    'TOUCH',
    'Tap on the sides to move',
    'Tap on the top to rotate',
    'Tap on the bottom to drop',
  ]
  private _textHeight = 15
  private _scale = 1
  private _pauseButton: CanvasButton
  private _resetButton: CanvasButton

  /*
        Shadow out of the play field (bottom and top)
        Pause button (show help)
        Resume / Restart buttons
        Start paused

        Evolution game: https://www.youtube.com/watch?v=0ZGbIKd0XrM
    */

  constructor(canvas: HTMLCanvasElement) {
    this._canvas = canvas
    this._context = canvas.getContext('2d')!

    document.addEventListener('keydown', this.keyDown)

    // Left side goes from 340 to 460

    this._pauseButton = new CanvasButton()
    this._pauseButton.color = Color.black
    this._pauseButton.highLightedColor = new Color(35, 35, 35)
    this._pauseButton.position = new Vector2(350, 340)
    this._pauseButton.size = new Vector2(100, 45)
    this._pauseButton.text = 'Pause'
    this._pauseButton.textColor = Color.white
    this._pauseButton.textFont = 'bold 24px sans-serif'

    this._resetButton = new CanvasButton()
    this._resetButton.color = Color.black
    this._resetButton.highLightedColor = new Color(35, 35, 35)
    this._resetButton.position = new Vector2(350, 400)
    this._resetButton.size = new Vector2(100, 45)
    this._resetButton.text = 'Reset'
    this._resetButton.textColor = Color.white
    this._resetButton.textFont = 'bold 24px sans-serif'

    this.reset()
    this._state = GameState.Paused

    this._viewport = new Viewport(canvas)
    this._viewport.onResize.add((s) => this.resize(s))
    this._viewport.onPoiterStart.add((p) => this.tap(p))
    this._viewport.onPointerMove.add(({ position }) =>
      this.pointerMove(position)
    )
    this._viewport.fillWindow()
  }

  dispose() {
    this._viewport.dispose()
    document.removeEventListener('keydown', this.keyDown)
    clearInterval(this._timer)
  }
  private resize(size: Vector2) {
    let height =
      this._settings.gridStartPosition.y * 2 +
      this._settings.gridHeight * this._settings.squareSize
    let width =
      this._settings.gridAuxStartPosition.x +
      this._settings.gridAuxWidth * this._settings.squareSize +
      this._settings.gridStartPosition.x

    this._context.resetTransform()
    this._scale = Math.min(size.y / height, size.x / width)
    this._context.scale(this._scale, this._scale)
    this.draw()
  }
  private tap(p: Vector2) {
    p = p.multiplyScalar(1 / this._scale)
    if (this._pauseButton.click(p)) {
      this.switchPause()
      return
    }
    if (this._resetButton.click(p)) {
      this.reset()
      return
    }

    if (this._state === GameState.Game) {
      let halfGrid = new Vector2(
        (this._settings.gridWidth * this._settings.squareSize) / 2,
        (this._settings.gridHeight * this._settings.squareSize) / 2
      )

      let relativeP = p
        .subtract(this._settings.gridStartPosition)
        .selfDivide(halfGrid)
        .selfAdd(new Vector2(-1, -1))

      // Move block sideways
      if (Math.abs(relativeP.x) > Math.abs(relativeP.y)) {
        let sign = Math.sign(relativeP.x)
        this._grid.moveBlock(this._currentBlock, new Vector2(sign, 0))
      } else if (relativeP.y > 0) {
        this._grid.hardDrop(this._currentBlock)
        this.update()
      } else {
        this._grid.rotate(this._currentBlock, 1)
      }

      this.draw()
    }
  }
  private pointerMove(p: Vector2) {
    this._pauseButton.pointerOver(p.multiplyScalar(1 / this._scale))
    this._resetButton.pointerOver(p.multiplyScalar(1 / this._scale))
    this.draw()
  }
  private reset() {
    this._settings = new Settings()

    this._grid = new Grid(
      this._settings.gridWidth,
      this._settings.gridHeight,
      this._settings.gridStartPosition,
      this._settings.squareSize
    )
    this._gridAux = new Grid(
      this._settings.gridAuxWidth,
      this._settings.gridAuxHeight,
      this._settings.gridAuxStartPosition,
      this._settings.squareSize
    )
    this.changeBlock()

    this._currentSpeedTime = 0
    this._lastTime = new Date().getTime()
    this._currentSpeed = 1
    this._currentUpdateInterval = this._settings.updateInterval
    clearInterval(this._timer)

    this._points = 0

    this._state = GameState.Game
    this.draw()

    this._timer = window.setInterval(
      () => this.update(),
      this._currentUpdateInterval
    )
  }
  private gameOver() {
    window.clearInterval(this._timer)
    this._state = GameState.GameOver
    this.draw()
  }
  private changeBlock() {
    var count = this._grid.removeCompletedLines()
    if (count) this._points += count * (this._currentSpeed * this._currentSpeed)

    if (this._currentBlock && !this._currentBlock.isCompletelyInside())
      this.gameOver()

    if (this._nextBlock) {
      this._currentBlock = this._nextBlock
    } else
      this._currentBlock = new Block(
        Math.floor(Math.random() * 7),
        this._settings.blockStartPosition
      )

    var rand = Math.floor(Math.random() * 7)
    this._nextBlock = new Block(rand, this._settings.blockStartPosition)

    this._gridAux.clear()
    this._gridAux.setBlock(
      new Block(rand, this._settings.gridAuxBlockStartPosition)
    )
  }
  private update() {
    var time = new Date().getTime()
    if (this._state === GameState.Game) {
      this._currentSpeedTime += time - this._lastTime

      if (this._currentSpeedTime > this._settings.timeToChangeSpeed) {
        this._currentSpeed++
        window.clearInterval(this._timer)
        //Aumenta 25% a velocidade
        this._currentUpdateInterval = 1000 * (1 / (1 + this._currentSpeed / 4))
        this._timer = window.setInterval(
          () => this.update(),
          this._currentUpdateInterval
        )
        this._currentSpeedTime = 0
      }

      if (!this._grid.moveBlock(this._currentBlock, new Vector2(0, 1)))
        this.changeBlock()
    }

    this._lastTime = time
    this.draw()
  }
  private keyDown = (e: KeyboardEvent) => {
    if (e.keyCode >= 30 && e.keyCode <= 40) e.preventDefault()

    if (this._state === GameState.Game) {
      if (e.keyCode === 37)
        this._grid.moveBlock(this._currentBlock, new Vector2(-1, 0))
      else if (e.keyCode === 39)
        this._grid.moveBlock(this._currentBlock, new Vector2(1, 0))
      else if (e.keyCode === 40) {
        if (!this._grid.moveBlock(this._currentBlock, new Vector2(0, 1)))
          this.changeBlock()
      } else if (e.keyCode === 65) this._grid.rotate(this._currentBlock, 1)
      else if (e.keyCode === 83) this._grid.rotate(this._currentBlock, -1)
      else if (e.keyCode === 13) {
        this._grid.hardDrop(this._currentBlock)
        this.update()
      }
    }

    if (e.keyCode === 82) this.reset()
    else if (e.keyCode === 32) this.switchPause()

    this.draw()
  }
  private switchPause() {
    if (this._state !== GameState.GameOver)
      this._state =
        this._state === GameState.Paused ? GameState.Game : GameState.Paused
    this.draw()
  }
  private draw() {
    //Background
    this._context.fillStyle = 'black'
    this._context.fillRect(
      0,
      0,
      this._canvas.width / this._scale,
      this._canvas.height / this._scale
    )

    var drawBlocks: boolean = this._state !== GameState.Paused
    this._grid.createProjection(this._currentBlock)
    this._grid.draw(this._context, drawBlocks)
    this._gridAux.draw(this._context, drawBlocks)

    //Configuracao do texto
    this._context.fillStyle = 'white'
    this._context.font = 'bold 24px sans-serif'
    this._context.textBaseline = 'top'

    //Speed
    this._context.fillText('Speed: ' + this._currentSpeed, 340, 160)

    //Pontos
    this._context.fillText('Points:', 340, 220)
    this._context.fillText(this._points.toString(), 340, 250)

    if (this._state === GameState.GameOver) {
      this._context.fillStyle = 'white'
      this._context.font = 'bold 48px sans-serif'
      this._context.textBaseline = 'middle'
      this._context.fillText(
        'Game over',
        -125 +
          this._settings.gridStartPosition.x +
          (this._settings.gridWidth / 2) * this._settings.squareSize,
        this._settings.gridStartPosition.y +
          (this._settings.gridHeight / 2) * this._settings.squareSize
      )
      this._context.strokeStyle = 'black'
      this._context.strokeText(
        'Game over',
        -125 +
          this._settings.gridStartPosition.x +
          (this._settings.gridWidth / 2) * this._settings.squareSize,
        this._settings.gridStartPosition.y +
          (this._settings.gridHeight / 2) * this._settings.squareSize
      )
    } else if (this._state === GameState.Paused) {
      this._context.save()
      this._context.fillStyle = 'white'
      this._context.font = 'bold 48px sans-serif'
      this._context.textBaseline = 'middle'
      this._context.textAlign = 'center'
      let x =
        this._settings.gridStartPosition.x +
        (this._settings.gridWidth / 2) * this._settings.squareSize
      let y =
        this._settings.gridStartPosition.y +
        (this._settings.gridHeight / 2) * this._settings.squareSize -
        100
      this._context.fillText('Paused', x, y)

      this._context.font = 'bold 12px sans-serif'
      let height = (y += 40)
      for (let t of this._helpTexts) {
        this._context.fillText(t, x, height)
        height += this._textHeight
      }

      this._context.restore()
    }

    this._pauseButton.draw(this._context)
    this._resetButton.draw(this._context)
  }
}
