import { Color } from 'src/core/Color'
import { BindGroup } from 'src/core/webGPU/BindGroup'
import { Bindable } from 'src/core/webGPU/Bindable'
import { Textures } from '../Textures'
import { Sampler } from 'src/core/webGPU/textures/Sampler'
import shader from './shaders/updateVelocitiesIntegrate.wgsl'
import { GPU } from 'src/core/webGPU/GPU'
import { RenderPipeline } from 'src/core/webGPU/RenderPipeline'
import { TexturePairBindGroup } from 'src/core/webGPU/TexturePairBindGroup'

export class UpdateVelocitiesIntegrate {
  private _gpu: GPU
  private _pipeline: RenderPipeline
  private _textures: Textures
  private _configBindGroup: BindGroup
  private _velocityBindGroup: TexturePairBindGroup
  private _pressureBindGroup: TexturePairBindGroup
  private _sampler = new Sampler({
    magFilter: 'linear',
    minFilter: 'linear',
    addressModeU: 'repeat',
    addressModeV: 'repeat',
  })

  constructor(gpu: GPU, configStruct: Bindable, textures: Textures) {
    this._gpu = gpu
    this._textures = textures
    this._configBindGroup = new BindGroup([configStruct])
    this._velocityBindGroup = new TexturePairBindGroup(
      this._sampler,
      this._textures.velocity
    )
    this._pressureBindGroup = new TexturePairBindGroup(
      this._sampler,
      this._textures.pressure
    )
    this._pipeline = new RenderPipeline(
      {
        label: 'UpdateVelocitiesIntegratePass pipeline',
        vertex: {
          module: { code: shader },
          entryPoint: 'vertex',
        },
        fragment: {
          module: { code: shader },
          entryPoint: 'fragment',
          targets: [
            {
              format: 'rg16float',
            } as GPUColorTargetState,
          ],
        },
        primitive: {
          topology: 'triangle-strip',
        },
      },
      [this._configBindGroup, this._velocityBindGroup, this._pressureBindGroup]
    )
  }

  createPipeline() {
    this._pipeline.initialize(this._gpu.device)
  }

  run() {
    const device = this._gpu.device
    const commandEncoder = device.createCommandEncoder({
      label: 'UpdateVelocitiesIntegratePass',
    })

    const textureView =
      this._textures.velocity.destination.getOrCreateResource(device)
    const renderPassDescriptor: GPURenderPassDescriptor = {
      colorAttachments: [
        {
          view: textureView,
          clearValue: Color.black.toGPUColor(),
          loadOp: 'clear',
          storeOp: 'store',
        } as GPURenderPassColorAttachment,
      ],
    }

    const passEncoder = commandEncoder.beginRenderPass(renderPassDescriptor)
    this._pipeline.bindTo(passEncoder)
    passEncoder.draw(4, 1, 0, 0)
    passEncoder.end()

    device.queue.submit([commandEncoder.finish()])

    this._textures.velocity.swap()
  }
}
