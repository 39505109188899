import { Vector3 } from '../math/Vector3'
import { Vector2 } from '../math/Vector2'
import { Matrix } from '../math/Matrix'
import { Quaternion } from '../math/Quaternion'
import { Ray } from '../math/Ray'

export class Camera {
  private _position = new Vector3(0, 0, 1)
  private _target = new Vector3(0, 0, 0)
  private _up = new Vector3(0, 1, 0)
  private _direction = new Vector3(0, 0, -1)
  private _orbitAxis = new Vector3(0, 0, 1)

  private _near = 10
  private _far = 50000
  private _size = new Vector2(1, 1)
  private _fov = Math.PI / 2
  private _isOrthographic = false
  private _orthographicScale = 1

  private _view = Matrix.identity()
  private _projection = Matrix.identity()
  private _viewProjection = Matrix.identity()

  private _viewDirty = true
  private _projectionDirty = true
  private _viewProjectionDirty = true

  private static _change = new Array<(sender: Camera) => void>()

  private getSpeedFactor(): number {
    if (this._isOrthographic) {
      //Quanto maior a escala, maior a velocidade
      return this._orthographicScale
    } else {
      let distance = this._target.subtract(this._position).length()

      //Mantém a velocidade padrão até 5 metros de distância
      //Depois disso aumenta em 1 a cada 10 metros de distância
      distance += 5000
      return 5 * Math.max(1, distance / 10000)
    }
  }
  private viewChanged() {
    this._viewDirty = true
    this._viewProjectionDirty = true
    for (let c of Camera._change) c(this)
  }
  private projectionChanged() {
    this._projectionDirty = true
    this._viewProjectionDirty = true
    for (let c of Camera._change) c(this)
  }
  private viewProjectionChanged() {
    this._viewDirty = true
    this._projectionDirty = true
    this._viewProjectionDirty = true
    for (let c of Camera._change) c(this)
  }

  getPosition(): Vector3 {
    return this._position.clone()
  }
  setPosition(value: Vector3) {
    this._position = value.clone()
    this.viewChanged()
    this._direction = this._target.subtract(this._position).normalize()
  }
  getTarget(): Vector3 {
    return this._target.clone()
  }
  setTarget(value: Vector3) {
    this._target = value.clone()
    this.viewChanged()
    this._direction = this._target.subtract(this._position).normalize()
  }
  getUp(): Vector3 {
    return this._up.clone()
  }
  setUp(value: Vector3) {
    this._up = value.clone()
    this.viewChanged()
  }
  getNear(): number {
    return this._near
  }
  setNear(value: number) {
    this._near = value
    this.projectionChanged()
  }
  getFar(): number {
    return this._far
  }
  setFar(value: number) {
    this._far = value
    this.projectionChanged()
  }
  getSize(): Vector2 {
    return this._size.clone()
  }
  setSize(value: Vector2) {
    if (this._size.x === value.x && this._size.y === value.y) return

    this._size = value.clone()
    this.projectionChanged()
  }
  getFov(): number {
    return this._fov
  }
  setFov(value: number) {
    this._fov = value
    this.projectionChanged()
  }
  set ortographic(value: boolean) {
    this._isOrthographic = value
    this.projectionChanged()
  }
  get ortographic(): boolean {
    return this._isOrthographic
  }
  setOrthographicScale(value: number) {
    this._orthographicScale = isNaN(value) ? 1 : value
    this.projectionChanged()
  }
  getOrthographicScale(): number {
    return this._orthographicScale
  }
  getDirection(): Vector3 {
    return this._direction.clone()
  }
  getRight(): Vector3 {
    return this._direction.cross(this._up)
  }
  setValues(newPos: Vector3, newTarget: Vector3, newUp: Vector3 | null = null) {
    this._position = newPos
    this._target = newTarget

    if (!newUp) {
      let dir = newPos.directionTo(newTarget)
      let up =
        Math.abs(dir.z) > 0.7 ? new Vector3(0, 1, 0) : new Vector3(0, 0, 1)
      let side = dir.cross(up).normalize()
      newUp = side.cross(dir)
    }

    this._up = newUp
    this.viewChanged()
    this._direction = this._target.subtract(this._position).normalize()
  }

  rotate(change: Vector2) {
    let qu = Quaternion.fromAxisAngle(this._up, change.x)
    let qr = Quaternion.fromAxisAngle(this._direction.cross(this._up), change.y)
    let q = qu.multiply(qr)
    let newD = this._direction.transformQuaternion(q)

    this.setTarget(this._position.add(newD))
  }
  moveForward(change: number) {
    this.move(this._direction.multiplyScalar(change))
  }
  moveUp(change: number) {
    this.move(this._up.multiplyScalar(change))
  }
  moveRight(change: number) {
    let right = this._direction.cross(this._up)
    this.move(right.multiplyScalar(change))
  }
  move(change: Vector3) {
    this.setValues(
      this._position.add(change),
      this._target.add(change),
      this._up
    )
  }
  fitSphere(center: Vector3, radius: number, direction: Vector3) {
    let target = center
    let position = target.subtract(direction)

    let side = direction
      .cross(direction.z > 0.999 ? new Vector3(0, 1, 0) : new Vector3(0, 0, 1))
      .normalize()
    let up = side.cross(direction)

    let vFov = this._fov * 0.5
    let hFov = Math.atan(Math.tan(vFov) * (this._size.x / this._size.y))

    let dist = radius / Math.cos(Math.PI / 2 - Math.min(vFov, hFov))
    position = target.add(direction.multiplyScalar(-dist))

    this.setTarget(target)
    this.setPosition(position)
    this.setUp(up)
  }

  getRay(pos: Vector2): Ray {
    let p1 = this.unproject(new Vector3(pos.x, pos.y - this._size.y, 0))
    let p2 = this.unproject(new Vector3(pos.x, pos.y - this._size.y, 1))
    let dir = p2.selfSubtract(p1).selfNormalize()
    return new Ray(p1, dir)
  }
  getView(): Matrix {
    if (this._viewDirty) {
      this._view = Matrix.lookAt(this._position, this._target, this._up)
      this._viewDirty = false
    }

    return this._view
  }
  getProjection(): Matrix {
    if (this._projectionDirty) {
      if (this._isOrthographic) {
        this._projection = Matrix.orthographic(
          this._orthographicScale * this._size.x,
          this._orthographicScale * this._size.y,
          this._near,
          this._far
        )
      } else {
        this._projection = Matrix.perspective(
          this._fov,
          this._size.x / this._size.y,
          this._near,
          this._far
        )
      }
      this._projectionDirty = false
    }

    return this._projection
  }
  getViewProjection(): Matrix {
    if (this._viewProjectionDirty) {
      this._viewProjection = this.getView().multiply(this.getProjection())
      this._viewProjectionDirty = false
    }
    return this._viewProjection
  }
  project(point: Vector3): Vector3 {
    return this.getViewProjection().project(
      point,
      this._size.x,
      this._size.y,
      this._near,
      this._far
    )
  }
  unproject(point: Vector3): Vector3 {
    return this.getViewProjection().unproject(
      point,
      this._size.x,
      this._size.y,
      this._near,
      this._far
    )
  }

  static onChange(callback: (sender: Camera) => void) {
    Camera._change.push(callback)
  }
}
